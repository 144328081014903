<!-- Desktop View  -->
<div *ngIf="!isLoading">
    <div *ngIf="showProductSelector" class="main-container d-none d-md-flex hide-scrollbar" [ngStyle]="{ 'background-image': 'url(' + backgroundImage + ')'}">
        <div class="product-selector">
            <div class="product-selector-child">
                <div class="product-selector-header">
                    <span class="product-selector-heading">
                        Choose the product you want to explore
                    </span>
                    <span class="product-selector-subheading">
                        Don't worry. You can switch to the other product anytime.
                    </span>
                </div>
                <div class="product-selector-components">
                    <div class="product-selector-main-component" [ngClass]="{'on-hover-product':!isProductTypeQr, 'default-cursor-border': isProductTypeQr,'pointer-cursor-border': !isProductTypeQr}" (click)="handleQRCodesSelectorComponent()" (mouseover)="handleMouseOverQrCodes()" (mouseout)="handleMouseOutQrCodes()">
                        <div class="selector-class">
                            <span [ngClass]="{'selector-button-on':isProductTypeQr, 'selector-button-off':!isProductTypeQr}">
                            </span>
                        </div>
                        <div class="qr-codes-main-component">
                            <div class="qr-codes-header">
                                <span class="product-heading">
                                    QR Codes
                                </span>
                                <span class="qr-codes-subheading">
                                    Elevate your offline campaigns with secure QR Codes
                                </span>
                                <span class="free-trial-banner">
                                    14-DAY FREE TRIAL
                                </span>
                            </div>
                            <div [ngClass]="{ 'dull': !isProductTypeQr }" class="qr-codes-svg">
                                <img class="qr-svg-image" [src]="qrCodesSvgPath" alt="qr-codes"/>
                            </div>
                        </div>
                    </div>
                    <div class="product-selector-main-component" [ngClass]="{'on-hover-product':isProductTypeQr,'default-cursor-border': !isProductTypeQr,'pointer-cursor-border': isProductTypeQr}" (click)="handleCardsSelectorComponent()" (mouseover)="handleMouseOverCards()" (mouseout)="handleMouseOutCards()">
                        <div class="selector-class">
                            <span [ngClass]="{'selector-button-on':!isProductTypeQr, 'selector-button-off':isProductTypeQr}">
                            </span>
                        </div>
                        <div class="qr-codes-main-component">
                            <div class="cards-header">
                                <span class="product-heading">
                                    Cards
                                </span>
                                <span class="cards-subheading">
                                    Turn conversations into instant leads with digital business cards
                                </span>
                                <span class="dbc-free-banner d-flex justify-content-center">
                                    FREE FOREVER
                                </span>
                            </div>
                            <div [ngClass]="{ 'dull': isProductTypeQr }" class="cards-svg">
                                <img class="cards-svg-image" [src]="cardsSvgPath" alt="cards" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="product-selector-next-cta">
                <button class="next-button" (click)="handleNextCta()">
                    Next
                </button>
            </div>
        </div>
    </div>


    <!-- Mobile view  -->


    <div *ngIf="showProductSelector" class="main-container-mobile d-flex d-md-none" [ngStyle]="{'background-image':'url'+'('+backgroundImage+')'}">
        <div class="product-selector-mobile">
            <div class="product-selector-header-mobile">
                <div class="product-selector-heading-parent-mobile">
                    <span class="product-selector-heading-mobile">
                        Choose the product you want to explore
                    </span>
                </div>
                <div class="product-selector-subheading-parent-mobile">
                    <span class="product-selector-subheading-mobile">
                        Don't worry. You can switch to the other product anytime.
                    </span>
                </div>
            </div>
            <div class="product-selector-main-component-mobile" [ngClass]="{'on-hover-product':!isProductTypeQr, 'default-cursor-border': isProductTypeQr,'pointer-cursor-border': !isProductTypeQr}" (click)="handleQRCodesSelectorComponent()" (mouseover)="handleMouseOverQrCodes()" (mouseout)="handleMouseOutQrCodes()">
                <div class="selector-class">
                    <span [ngClass]="{'selector-button-on':isProductTypeQr, 'selector-button-off':!isProductTypeQr}">
                    </span>
                </div>
                <div class="qr-codes-main-component-mobile">
                    <div class="qr-codes-header">
                        <span class="product-heading">
                            QR Codes
                        </span>
                        <span class="qr-codes-subheading">
                            Elevate your offline campaigns with secure QR Codes
                        </span>
                        <span class="free-trial-banner">
                            14-DAY FREE TRIAL
                        </span>
                    </div>
                    <div [ngClass]="{ 'dull': !isProductTypeQr }" class="qr-codes-svg-mobile">
                        <img class="qr-codes-svg-image-mobile" [src]="qrCodesSvgPath" alt="qr-codes"/>
                    </div>
                </div>
            </div>
            <div class="product-selector-main-component-mobile" [ngClass]="{'on-hover-product':isProductTypeQr,'default-cursor-border': !isProductTypeQr, 'pointer-cursor-border': isProductTypeQr}" (click)="handleCardsSelectorComponent()" (mouseover)="handleMouseOverCards()" (mouseout)="handleMouseOutCards()">
                <div class="selector-class">
                    <span [ngClass]="{'selector-button-on':!isProductTypeQr, 'selector-button-off':isProductTypeQr}">
                    </span>
                </div>
                <div class="qr-codes-main-component-mobile">
                    <div class="cards-header">
                        <span class="product-heading">
                            Cards
                        </span>
                        <span class="cards-subheading">
                            Turn conversations into instant leads with digital business cards
                        </span>
                        <span class="dbc-free-banner d-flex justify-content-center">
                            FREE FOREVER
                        </span>
                    </div>
                    <div [ngClass]="{ 'dull': isProductTypeQr }" class="cards-svg-mobile">
                        <img class="cards-svg-image-mobile" [src]="cardsSvgPath" alt="cards"/>
                    </div>
                </div>
            </div>
            <div class="next-cta-mobile">
                <button class="next-button" (click)="handleNextCta()">
                    Next
                </button>
            </div>
        </div>
    </div>
</div>

<beaconstac-wait-loader [ngClass]="isLoading ? '' : 'd-none'" class="h-100 beaconstac-overlay full-layout"></beaconstac-wait-loader>
