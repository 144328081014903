import {Facebook} from './facebook.model';
import {SocialMedia} from './social-media.model';
import {VCardPlus} from './vcard-plus.model';
import {CouponCode} from './coupon-code.model';
import {BusinessCard} from './business-card.model';
import {Attendance} from './attendance.model';
import {PDF} from '../campaign/models/pdf.model';
import {RestaurantMenu} from '../campaign/models/restaurant-menu.model';
import {PhoneSms} from '../campaign/models/phone-sms.model';
import {PhoneCall} from '../campaign/models/phone-call.model';
import {Email} from '../campaign/models/email.model';
import * as moment from 'moment-timezone';
import {GeoLocationModel} from '../campaign/models/geo-location.model';
import {SmartRulesModel} from '../campaign/models/smart-rules.model';
import { GS1Code } from 'app/gs1/gs1-models/gs1-campaign.model';


export enum  EddyCampaignTypes {
    NoCampaign = 0,
    CustomURL = 1,
    Card = 2,
    Form = 3,
    Schedule = 4,
    AppLinks = 5,
    MultiLanguage = 6,
    VCardPlus = 7,
    SocialMedia = 8,
    FacebookPage = 9,
    CouponCode = 10,
    BusinessCard = 11,
    Attendance = 12,
    PDF = 13,
    RestaurantMenu = 14,
    PhoneCall = 15,
    PhoneSMS = 16,
    Email = 17,
    Linkpage = 18,
    IMAGE = 19,
    MP3 = 20,
    LOCATION = 21,
    GeoLocation = 22,
    SmartRules = 23,
    GS1 = 28
}

export enum CAMPAIGN_TYPE {
    Website = 'website',
    LandingPage = 'landing_page',
    FeedbackForm = 'feedback_form',
    PDF = 'pdf',
    Location = 'location',
    Image = 'image',
    MP3 = 'mp3',
    AppDownloadLinks = 'app_download_links',
    VCardPlus = 'vcard_plus',
    SocialMedia = 'social_media',
    FacebookPage = 'facebook_page',
    CouponCode = 'coupon',
    BusinessCard = 'business_card',
    Attendance = 'attendance',
    RestaurantMenu = 'restaurant_menu',
    PhoneSMS = 'phone_sms',
    PhoneCall = 'phone_call',
    Email = 'dynamic_email',
    StaticWebsite = 'static_website',
    StaticSMS = 'sms',
    StaticPhone = 'phone',
    StaticEmail = 'email',
    StaticvCard = 'vcard',
    StaticText = 'text',
    StaticWifi = 'staticwifi',
    Linkpage = 'linkpage',
    StaticLinkpage = 'static_linkpage',
    GeoLocation = 'geo_location',
    SmartRules = 'smart_rules',
    GS1 = 'GS1'
}


export class MultiLanguageContent {
    content_type: EddyCampaignTypes;
    markdown_cards = {
        en: {id: -1, language: 'English'}
    };
    custom_urls = {
        en: {url: '', language: 'English'}
    };
    forms = {
        en: {id: -1, language: 'English'}
    };
    pdfs = {
        en: {data: new PDF(), language: 'English'}
    };
    restaurant_menus = {
        en: {data: null, language: 'English'}
    };

    default: string = 'en';

    constructor(json?) {
        if (!json) {
            return;
        }

        this.content_type = json['content_type'];
        this.markdown_cards = json['markdown_cards'] ? json['markdown_cards'] : this.markdown_cards;
        this.custom_urls = json['custom_urls'] ? json['custom_urls'] : this.custom_urls;
        this.forms = json['forms'] ? json['forms'] : this.forms;
        this.default = json['default'];
        if (json['pdfs']) {
            for (const language of Object.keys(json['pdfs'])) {
                json['pdfs'][language]['data'] = new PDF(json['pdfs'][language]['data']);
            }
        }
        this.pdfs = json['pdfs'] ? json['pdfs'] : this.pdfs;
        if (json['restaurant_menus']) {
            for (const language of Object.keys(json['restaurant_menus'])) {
                json['restaurant_menus'][language]['data'] = new RestaurantMenu(json['restaurant_menus'][language]['data']);
            }
        }
        this.restaurant_menus = json['restaurant_menus'] ? json['restaurant_menus'] : this.restaurant_menus;

    }
}

export class Campaign {
    id: number;
    name: string;
    custom_url: string = '';
    content_type: EddyCampaignTypes = EddyCampaignTypes.NoCampaign;
    campaign_active: boolean = true;
    created: string;
    updated: string;
    fb_pixel_event: string;
    fb_pixel_id: null;
    ga_code: string;
    google_conversion_id: null;
    timezone: string = moment.tz.guess();
    start_date: string;
    end_date: string;
    organization: number;
    markdown_card: number;
    form: number;
    schedule: number;
    app_links = {
        android_url: '',
        android_deeplink: '',
        iphone_url: '',
        iphone_deeplink: '',
        ipad_url: '',
        ipad_deeplink: '',
        fallback_url: ''
    };
    multi_language_content: MultiLanguageContent = new MultiLanguageContent();
    vcard_plus: VCardPlus = new VCardPlus();
    social_media: SocialMedia = new SocialMedia();
    facebook_page: Facebook = new Facebook();
    coupon: CouponCode = new CouponCode();
    business_card: BusinessCard = new BusinessCard();
    attendance: Attendance = new Attendance();
    pdf: PDF = new PDF();
    restaurant_menu: RestaurantMenu = new RestaurantMenu();
    phone_sms: PhoneSms = new PhoneSms();
    phone_call: PhoneCall = new PhoneCall();
    email: Email = new Email();
    age_gate: number;
    link_page: number;
    geo_location_content = new GeoLocationModel()
    smart_rules = new SmartRulesModel();
    gs1_code = new GS1Code();

    constructor(json?) {
        if (!json) {
            return;
        }
        this.id = json['id'];
        this.name = json['name'];
        this.custom_url = json['custom_url'];
        this.content_type = json['content_type'];
        this.campaign_active = json['campaign_active'];
        this.fb_pixel_event = json['fb_pixel_event'];
        this.fb_pixel_id = json['fb_pixel_id'];
        this.ga_code = json['ga_code'];
        this.google_conversion_id = json['google_conversion_id'];
        this.start_date = json['start_date'];
        this.end_date = json['end_date'];
        this.timezone = json['timezone'];
        this.organization = json['organization'];
        this.markdown_card = this.content_type === EddyCampaignTypes.Card ? json['markdown_card'] : null;
        this.form = this.content_type === EddyCampaignTypes.Form ? json['form'] : null;
        this.schedule = json['schedule'];
        if (json['app_links'] && json['app_links'].hasOwnProperty('fallback_url')) {
            this.app_links = json['app_links'];
        }
        this.multi_language_content = new MultiLanguageContent(json['multi_language_content']);
        this.vcard_plus = new VCardPlus(json['vcard_plus']);
        this.facebook_page = new Facebook(json['facebook_page']);
        this.social_media = new SocialMedia(json['social_media']);
        this.coupon = new CouponCode(json['coupon']);
        this.business_card = new BusinessCard(json['business_card']);
        this.attendance = new Attendance(json['attendance']);
        this.pdf = new PDF(json['pdf']);
        this.restaurant_menu = new RestaurantMenu(json['restaurant_menu']);
        this.phone_call = json['phone_call'];
        this.phone_sms = json['phone_sms'];
        this.email = json['email'];
        this.age_gate = json['age_gate'] ? json['age_gate'] : 0;
        this.link_page = this.content_type === EddyCampaignTypes.Linkpage ? json['link_page'] : null
        this.geo_location_content = new GeoLocationModel(json['geo_location_content'])
        this.smart_rules = new SmartRulesModel(json['smart_rules']);
        this.gs1_code = json['gs1_code'] ? new GS1Code(json['gs1_code']) : new GS1Code();
    }

    isActive(): boolean {
        return this.content_type !== EddyCampaignTypes.NoCampaign;
    }

    needsNotificationData(): boolean {
        return this.isActive() && this.content_type !== EddyCampaignTypes.Schedule;
    }


    getReadableCampaignType(): string {
        return [
            'No campaign',
            'Website',
            'Landing page',
            'Form',
            'Schedule',
            'App Download',
            'MultiLanguage',
            'Digital Business Card (vCard)',
            'Social Media',
            'Facebook Page',
            'Coupon Code',
            'Business Page',
            'Attendance',
            'PDF',
            'Restaurant Menu',
            'Call',
            'SMS',
            'Email',
            'Linkpage',
            'Image',
            'MP3',
            'Location',
            'Geolocation',
            'Smart Rules'
        ][this.content_type];
    }
}
