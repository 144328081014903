import {Observable, throwError as observableThrowError} from 'rxjs';

import {catchError, map} from 'rxjs/operators';


import {environment} from '../../environments/environment';
import {AuthService} from './auth.service';
import {BaseModel, BaseModelConstructor} from './base.model';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MessageModalService} from '../shared/message-modal/message-modal.service';

type BaseModelConstructorHackyAlias = BaseModelConstructor;

export interface ListResult<E> {
    objects: E[];
    pageCount: number;
    totalCount: number;
    next: any;
    previous: any;
    hiddenResponseCount?: number;
}

export interface SearchParams {
    [param: string]: string | number;
}

export abstract class BaseBackendService<E extends BaseModel> {

    protected apiURL: string;

    protected constructor(protected http: HttpClient,
        protected authService: AuthService,
        protected model: BaseModelConstructorHackyAlias,
        protected apiSuffix: string,
        protected messageModal: MessageModalService,
        protected apiVersion = environment.apiVersion) {
        this.apiURL = environment.baseURL + environment.apiEndpoint + this.apiVersion + '/' + apiSuffix + '/';
    }

    protected buildHeaders(argsObject: object): object {
        if (!argsObject) {
            // throw new Error('RequestOptionsArgs not passed to buildHeaders');
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `${this.authService.getTokenType()} ${this.authService.getToken()}`
        });
        // if(this.authService)
        argsObject['headers'] = headers;
        return argsObject;
    }

    protected buildURL(url: String = this.apiURL, pageNumber: number = 1, pageSize: number = 10, searchParams: SearchParams = {},
        passOrg: boolean = true, ordering?: string, custom?: string, extraParams: string = '', passAllOrg = true): string {
        let paramString = '';
        // Build param string
        for (const param in searchParams) {
            const value = searchParams[param];
            paramString = paramString + '&' + param + '=' + value;
        }
        let orderingString = '';
        if (ordering) {
            orderingString = '&ordering=' + ordering;
        }
        if (custom) {
            return url + custom;
        } else if (passOrg) {
            return url + '?organization=' + this.authService.getCurrentOrgId() + '&page_size=' + pageSize + '&page=' + pageNumber
        + paramString + orderingString + extraParams;
        } else {
            if (passAllOrg) {
                return url + '?organization=ALL' + '&page_size=' + pageSize + '&page=' + pageNumber + paramString + orderingString + extraParams;
            } else {
                return url + '?page_size=' + pageSize + '&page=' + pageNumber + paramString + orderingString + extraParams;

            }
        }
    }

    public getListFromURL(url: string, pageSize: number = 10): Observable<ListResult<E>> {
    // Check if re-authentication is required.
        if (this.authService.isReAuthenticationRequired()) {
            return observableThrowError(null);
        }
        return this.http
            .get(url, this.buildHeaders({})).pipe(
                map((response): ListResult<E> => {
                    const jsonResponse = response;
                    const pageCount = Math.ceil(jsonResponse['count'] / pageSize);

                    return {
                        objects: jsonResponse['results'].map(result => {
                            return new this.model(result);
                        }),
                        pageCount: pageCount,
                        totalCount: jsonResponse['count'],
                        next: jsonResponse['next'],
                        previous: jsonResponse['previous'],
                        hiddenResponseCount: jsonResponse['hidden_response_count']
                    };
                }),
                catchError(error => {
                    return this.handleError(error);
                }));
    }

    public getList(pageNumber: number = 1, pageSize: number = 10, params: SearchParams = {},
        passOrg: boolean = true, ordering?: string, custom?: any, extraParams?: string, passAllOrg = true): Observable<ListResult<E>> {
    // Check if re-authentication is required.
        if (this.authService.isReAuthenticationRequired()) {
            return observableThrowError(null);
        }
        return this.http
            .get(this.buildURL(this.apiURL, pageNumber, pageSize, params, passOrg, ordering, custom, extraParams, passAllOrg), this.buildHeaders({})).pipe(
                map((response): ListResult<E> => {
                    const jsonResponse = response;
                    const pageCount = Math.ceil(jsonResponse['count'] / pageSize);

                    return {
                        objects: jsonResponse['results'].map(result => {
                            return new this.model(result);
                        }),
                        pageCount: pageCount,
                        totalCount: jsonResponse['count'],
                        next: jsonResponse['next'],
                        previous: jsonResponse['previous'],
                        hiddenResponseCount: jsonResponse['hidden_response_count']
                    };
                }),
                catchError(error => {
                    return this.handleError(error);
                }));
    }

    public getDetail(id: number | string, custom?: any): Observable<E> {
        if (this.authService.isReAuthenticationRequired()) {
            return observableThrowError(null);
        }
        const beaconDetailUrl = this.apiURL + id as string + '/';
        const url = !custom ? this.buildURL(beaconDetailUrl) : this.buildURL(beaconDetailUrl, null, null, null, true, null, custom);
        return this.http
            .get(url, this.buildHeaders({})).pipe(
                map(response => {
                    return new this.model(response);
                }),
                catchError(error => {
                    return this.handleError(error)
                }));
    }
    public verify(id: number | string): Observable<any> {
        const malwareDetectURL = this.buildURL(this.apiURL + id as string + '/');
        return this.http.post(malwareDetectURL, {}, this.buildHeaders({})).pipe(
            map(response => {
                return response;
            }),
            catchError(error => {
                return this.handleError(error);
            }));
    }
    public getData(id: number | string, custom?: any): Observable<E> {
        if (this.authService.isReAuthenticationRequired()) {
            return observableThrowError(null);
        }
        const beaconDetailUrl = this.apiURL + id as string + '/';
        const url = !custom ? this.buildURL(beaconDetailUrl) : this.buildURL(beaconDetailUrl, null, null, null, true, null, custom);
        return this.http
            .get(url, this.buildHeaders({})).pipe(
                map(response => {
                    return response;
                }),
                catchError(error => {
                    return this.handleError(error);
                }));
    }

    protected handleError(error: any): Observable<any> {
        switch (error.status) {
            case 401:
                this.authService.logout();
                break;
            case 403:
                this.messageModal.show('You are not allowed to access this resource', 'danger');
                break;
        }
        // Manage error into something
        return observableThrowError(error);
    }

    public put(body: object, id: number | string, custom?: string): Observable<any> {
        if (this.authService.isReAuthenticationRequired()) {
            return observableThrowError(null);
        }
        let url;
        url = this.apiURL + id as string + '/';
        if (custom) {
            url += custom;
        }
        return this.http.put(url, JSON.stringify(body), this.buildHeaders({})).pipe(
            map(response => {
                return response;
            }),
            catchError(error => {
                return this.handleError(error);
            }));
    }

    public patch(body: object, id: number | string, custom?: string): Observable<any> {
        if (this.authService.isReAuthenticationRequired()) {
            return observableThrowError(null);
        }
        let url;
        url = `${this.apiURL}${id}/`;
        if (custom) {
            url += custom;
        }
        return this.http.patch(url, JSON.stringify(body), this.buildHeaders({})).pipe(
            map(response => {
                return response;
            }),
            catchError(error => {
                return this.handleError(error);
            }));
    }

    public post(body: object, custom?: string, additionalConfig?: {observeResponse: boolean}): Observable<any> {
        if (this.authService.isReAuthenticationRequired()) {
            return observableThrowError(null);
        }
        let url = this.apiURL;
        if (custom) {
            url += custom;
        } else {
            url = url + '?organization=' + this.authService.getCurrentOrgId()
        }

        const options = additionalConfig && additionalConfig.observeResponse ? {...this.buildHeaders({}), observe: 'response'} : this.buildHeaders({});

        return this.http.post(url, JSON.stringify(body), options).pipe(
            map(response => {
                this.authService.setDataUpdate();
                return response;
            }),
            catchError(error => {
                return this.handleError(error);
            }));
    }

    public postToId(body: object, id: number | string, custom?: string): Observable<any> {
        if (this.authService.isReAuthenticationRequired()) {
            return observableThrowError(null);
        }
        let url = this.apiURL + id as string;
        if (custom) {
            url += custom;
        }
        return this.http.post(url, JSON.stringify(body), this.buildHeaders({})).pipe(
            map(response => {
                this.authService.setDataUpdate();
                return response;
            }),
            catchError(error => {
                return this.handleError(error);
            }));
    }

    public delete(id: number | string, custom?: string): Observable<any> {
        if (this.authService.isReAuthenticationRequired()) {
            return observableThrowError(null);
        }
        let url;
        url = this.apiURL + id as string + '/';
        if (custom) {
            url += custom;
        }

        return this.http.delete(url, this.buildHeaders({})).pipe(
            map(response => {
                this.authService.setDataUpdate();
                return response;
            }),
            catchError(error => {
                return this.handleError(error);
            }));
    }

    public getCompleteListFromURL(url: string): Observable<ListResult<E>> {
        // Check if re-authentication is required.
        if (this.authService.isReAuthenticationRequired()) {
            return observableThrowError(null);
        }
        return this.http
            .get(url, this.buildHeaders({})).pipe(
                catchError(error => {
                    return this.handleError(error);
                }));
    }

    get URL() {
        return this.apiURL;
    }

}
