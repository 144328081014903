import {distinctUntilChanged, filter} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {Title} from '@angular/platform-browser';
import {BEACONSTAC_HOSTS} from './app.constants';
import {AuthService} from './global-services/auth.service';
import {Utils} from './shared/utils';

declare global {
    interface Window {
        lintrk: any;
        customFit: any;
        CustomFit: any;
        pintrk: any;
    }
}
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'body',
    template: `
        <beaconstac-wait-loader
            *ngIf="isLoadingForDirectPurchase || !(loggedIn$ | async) && !(authError$ | async) && !isWhiteLabeledDashboard && !outsideLogin"
            class="beaconstac-overlay full-layout" [id]="'overlay-loader'"></beaconstac-wait-loader>
        <router-outlet></router-outlet>
    `
})
export class AppComponent implements OnInit, OnDestroy {

    private routerSubscription: Subscription;

    loggedIn$: any;
    authError$: any;
    isWhiteLabeledDashboard: boolean = false;
    outsideLogin: boolean = false;
    isLoadingForDirectPurchase: boolean = false;

    constructor(private router: Router, private titleService: Title, private authService: AuthService, private route: ActivatedRoute) {
        // global services are included here so that they get initialized at the application level
        this.isLoadingForDirectPurchase = window.location.search.indexOf('source=direct-purchase') !== -1;
        this.loggedIn$ = this.authService.isLoggedIn$;
        this.authError$ = this.authService.authError$.pipe(distinctUntilChanged(), filter(val => val !== null), );
        this.setupTitle();
        this.isWhiteLabeledDashboard = Utils.isWhiteLabeledDashboard();
        this.setOutsideLoginPage();

    }

    ngOnInit() {
        this.hideSidebarForNewUserExp();
        // https://github.com/angular/angular/issues/7791#issuecomment-268227536
        this.routerSubscription = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd))
            .subscribe(event => {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            });
    }

    ngOnDestroy() {
        this.routerSubscription.unsubscribe();
    }

    setOutsideLoginPage() {
        const outsideLoginPaths = [
            '/invite/',
            '/vcard_update/',
            '/personalize/update/',
            '/signup',
            '/digital-business-cards/onboarding'
        ];

        this.outsideLogin = outsideLoginPaths.some(path => {
            if (path === '/digital-business-cards/onboarding') {
                this.isLoadingForDirectPurchase = false; // As this page is out of the login flow
            }
            return window.location.href.includes(path); // Explicit return
        });
    }

    hideSidebarForNewUserExp() {
        this.route.queryParams.subscribe(param => {
            if (param.trial_first === 'true') {
                document.getElementsByTagName('body')[0].classList.add('sidebar-hidden');
            }
        })
    }

    setupTitle() {
        const host = window.location.hostname.toLowerCase();
        if (BEACONSTAC_HOSTS.indexOf(host) !== -1) {
            this.titleService.setTitle('Uniqode');
        } else {
            this.titleService.setTitle('Dashboard');
        }
    }

}
