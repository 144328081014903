import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {DBC_PRICE_PAGE_TYPE} from '../dbc-pricing-page.component';
import {AuthService} from '../../../global-services/auth.service';
import {PRODUCT_TYPES} from '../../../shared/utils';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-page-description',
    templateUrl: './page-description.component.html',
    styleUrls: ['./page-description.component.scss']
})
export class PageDescriptionComponent implements OnChanges, OnDestroy {
    @Input() pageType: DBC_PRICE_PAGE_TYPE = DBC_PRICE_PAGE_TYPE.EXPIRED;
    @Input() totalDBCInOrg: number = undefined;

    title: string;
    description: string;
    DBC_PRICE_PAGE_TYPE = DBC_PRICE_PAGE_TYPE;
    isOnDbcTrialPlan: boolean = false;
    currentPlanName: string;
    hasChildOrgs: boolean = false;
    showGenericDescription: boolean = false;
    ngUnsubscribe: Subject<any> = new Subject();

    constructor(private readonly authService: AuthService) {
        this.isOnDbcTrialPlan = this.authService.getUser().isOnTrialPlan(PRODUCT_TYPES.DBC)
        this.currentPlanName = this.authService.getUser().getReadablePlan(PRODUCT_TYPES.DBC).split(' ')[0].toUpperCase();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.totalDBCInOrg) {
            this.totalDBCInOrg = changes.totalDBCInOrg.currentValue;
            this.authService.organizationsList$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((orgs) => {
                this.hasChildOrgs = orgs.length > 1;
                this.showGenericDescription = this.hasChildOrgs;
                this.generatePageDescription();
            });
        }
    }

    generatePageDescription() {
        if (this.pageType === DBC_PRICE_PAGE_TYPE.EXPIRED) {
            if (this.isOnDbcTrialPlan) {
                this.title = 'Your 14-day free trial has expired';
            } else {
                this.title = `Your ${this.currentPlanName} plan has expired`;
            }

            if (!this.showGenericDescription && this.totalDBCInOrg) {
                this.description = `You have <b>${this.totalDBCInOrg}</b> ${this.totalDBCInOrg === 1 ? 'card ' : 'cards '} in your account. Upgrade to a paid plan to keep using them.`
            } else {
                this.description = 'Upgrade to a paid plan to continue using your Uniqode Cards account.';
            }
        } else {
            this.title = 'Pricing & Plans';
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
