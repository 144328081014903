import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Pipe,
    PipeTransform,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime, finalize, takeUntil} from 'rxjs/operators';
import {
    DBC_CARRY_FORWARD_FIELDS,
    DBC_LANGUAGE_SPECIFIC_FIELDS,
    DBCCardTypes,
    deepCopy,
    getCustomColoredSocialIconSvg,
    Utils
} from 'app/shared/utils';
import {UntypedFormControl} from '@angular/forms';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {SOCIAL_MEDIA, socialIconsFontClasses, socialMediaMetaData} from 'app/shared/social-media';
import {TabsetComponent} from 'ngx-bootstrap/tabs';
import {
    BUTTON_STYLES,
    BUTTON_TYPES,
    CARET_POSITION,
    ICON_POSITION,
    ICON_SIZE,
    TEXT_FIELD_TYPES,
    TEXT_FIELD_VALIDATIONS,
    TOOLTIP_POSITION,
} from 'kaizen-design-system';
import {Animations} from '../../../shared/beaconstac-animations';
import {
    BeaconstacFileDropComponent
} from '../../../global-components/beaconstac-file-drop/beaconstac-file-drop.component';
import {MediaGalleryComponent} from '../../../global-components/media-gallery/media-gallery.component';
import {ImageCropperComponent} from '../../../global-components/image-cropper/image-cropper.component';
import {Media} from '../../../places/media.model';
import {environment} from 'environments/environment';
import {MessageModalService} from '../../../shared/message-modal/message-modal.service';
import {MediaService} from '../../../places/media-service';
import {AuthService} from '../../../global-services/auth.service';
import {MediaFolderService} from '../../../media-folders/media-folder.service';
import {
    buildContactInfoList,
    DigitalBusinessCard,
    DigitalBusinessCardTemplateModel,
    MultiLanguageContent
} from '../../digital-business-card.model';
import {
    CARD_PROPERTY_TYPES,
    DigitalBusinessCardSetting
} from '../../digital-business-card-setting/digital-business-card-setting.model';
import {User} from '../../../user-account/user.model';
import {UpsellService} from '../../../global-services/upsell.service';
import {LanguageService} from '../../../global-components/notification-language/language.service';
import {CARD_USER_INPUT_OPERATIONS} from '../digital-business-card-detail.component';
import {
    CardsOrganizationRestrictedFields,
    DBC_ORG_PERMISSION_OPTIONS
} from '../../../user-account/user-organization.model';
import {DomSanitizer} from '@angular/platform-browser';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {CONTACT_DETAIL} from '../../digital-business-card-utils';
import {DBC_LAYOUTS} from '../../templates-utils';
import {
    DbcFormUpsellTouchpointsPermission,
    FeatureVisibilityPermisions
} from '../../../global-services/dbc-rabc/dbc.rbac.model';
import {DbcRbacService} from '../../../global-services/dbc-rabc/dbc.rbac.service';
import {
    AMPLITUDE_EVENT_CATEGORIES,
    AMPLITUDE_EVENTS,
    AmplitudeService
} from '../../../global-services/amplitude.service';
import {cloneDeep} from 'lodash';
import {FEATURE_FLAGS} from '../../../shared/feature-flags';
import { enlargeLogoSize } from 'app/dbc-onboarding/dbc-onboard.utils';

export enum IMAGE_TYPE {
    Logo,
    Background,
    DBCLogo,
    DBCUserImage
}

enum COLLAPSE {
    PERSONAL_INFO = 'personalInfo',
    COMPANY_DETAILS = 'companyDetails',
    CONTACT_DETAILS = 'contactDetails',
    SOCIAL_LINKS = 'socialLinks',
}

@Pipe({name: 'getSocialIconSvg', pure: true})
export class GetSocialIconSvgPipe implements PipeTransform {
    constructor(private domSanitizer: DomSanitizer) {
    }
    transform(item: any): any {
        return this.domSanitizer.bypassSecurityTrustHtml(getCustomColoredSocialIconSvg(item.title));
    }
}

@Component({
    selector: 'app-digital-business-card-setup',
    templateUrl: './digital-business-card-setup.component.html',
    styleUrls: ['./digital-business-card-setup.component.scss'],
    animations: [Animations.collapse]
})
export class DigitalBusinessCardSetupComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    @Input() product: DigitalBusinessCard = null
    @Input() hasWriteAccess = null
    @Input() newVcard: boolean;
    @Input() cardSetting: DigitalBusinessCardSetting;
    @Input() orgCardSetting: CardsOrganizationRestrictedFields;
    @Input() customizeStepVisited: boolean;
    @Input() validations: any;
    @Input() validationsMultiLang: {[key: string]: {first_name_ml: boolean,
        email_v2_ml: boolean,
        website_v2_ml: boolean}} = {};
    @Output() productChange: EventEmitter<DigitalBusinessCard> = new EventEmitter();
    @Input()  isMultiLanguageContent: boolean = false;
    @Output() isMultiLanguageContentChange: EventEmitter<boolean> = new EventEmitter();
    @Input()  currentLanguage: string = 'en';
    @Output() currentLanguageChange: EventEmitter<string> = new EventEmitter();
    @Input() multiLangList: Array<string>;
    @Input() priortizeOrgSettings: boolean = false;
    @Output() multiLangListChange: EventEmitter<Array<string>> = new EventEmitter();
    @Output() stepOneClonedProduct: EventEmitter<DigitalBusinessCard> = new EventEmitter();
    @Input() locationModel = {
        latitude: 0,
        longitude: 0,
        placeId: '',
        url: ''
    };
    @Input() locationModelMultiLang: {[key: string]: {
        latitude: number,
        longitude: number,
        placeId: string,
        url: string
    }} = {};
    @Input() cardType: DBCCardTypes;
    @Output() locationModelChangeMultiLang: EventEmitter<any> = new EventEmitter();
    @Output() locationModelChange: EventEmitter<any> = new EventEmitter();
    @Output() onNext: EventEmitter<null> = new EventEmitter();
    @Output() validationsChange: EventEmitter<any> = new EventEmitter();
    @Output() validationsMultiLangChange: EventEmitter<any> = new EventEmitter();
    @Output() cardSettingChange: EventEmitter<any> = new EventEmitter();
    @Output() cardUserInputChange: EventEmitter<any> = new EventEmitter();
    @Input() isMobileScreen: boolean = false;
    @Input() dbcFormUpsellTouhPointsPermission: DbcFormUpsellTouchpointsPermission;

    @ViewChild('vCardPlusPreview', {static: false}) vCardPlusPreview: ElementRef;

    @ViewChild('vCardPlusPreviewMobile', {static: false}) vCardPlusPreviewMobile: ElementRef;
    @ViewChild('search', {static: false}) searchElementRef: ElementRef;
    @ViewChild('searchMultiLang', {static: false}) searchMultiLangElementRef: ElementRef;
    @ViewChild('vCardUserImageDrop', {static: true}) vCardUserImageDrop: BeaconstacFileDropComponent;
    @ViewChild('imageSelectModal', {static: false}) imageSelectModal: ModalDirective;
    @ViewChild('previewModal', {static: false}) previewModal: ModalDirective;
    @ViewChild('imageSelectGallery', {static: false}) imageSelectGallery: MediaGalleryComponent;
    @ViewChild('imageCropper', {static: false}) private imageCropper: ImageCropperComponent;
    @ViewChild('logoDropVCardFeatureDisabled', {static: true}) logoDropVCardFeatureDisabled: BeaconstacFileDropComponent;
    @ViewChild('logoDropVCardFeatureEnabled', {static: true}) logoDropVCardFeatureEnabled: BeaconstacFileDropComponent;
    @ViewChild('imageUpdateModal', {static: false}) imageUpdateModal: ModalDirective;
    @ViewChild('vcardTabs', {static: false}) vcardTabs: TabsetComponent;
    @ViewChild('switchMultiLanguage', {static: false}) switchMultiLanguage: ModalDirective;
    Utils: any = Utils;
    IMAGE_TYPE: any = IMAGE_TYPE;
    mapZoomValue: number = 16;
    disableNextButtonVcard: boolean;
    contentChangeSubject = new Subject<null>();
    isIconUploading: boolean = false;
    isBackgroundUploading: boolean = false;
    imageSelectModel = {
        media: <Media>null,
        link: '',
        alt: '',
        isUploading: false,
        type: this.IMAGE_TYPE.Logo
    };
    imageCropperFile: File;
    updatedMedia: Media = null;
    isLogoUploading: boolean = false;
    isProcessingImage: boolean = false;

    // iFrame load status
    isFrameLoadingSubject = new Subject<boolean>();
    isFrameLoading: boolean = false;
    searchControl: UntypedFormControl = new UntypedFormControl();
    searchControlMultiLang: {[key: string]: UntypedFormControl} = {};
    ngUnsubscribe: Subject<any> = new Subject();
    product_type: string = 'qr';
    uploadToFolder = null;
    isOnEnterprisePlan: boolean;
    socialFieldDetails: Array<any> = [];
    socialFieldDetailsMultiLang: {[key: string]: Array<any>} = {};
    socialMediaMetaData: Array<any> = socialMediaMetaData
    socialModels: any[] = [];
    isFacebook: boolean;
    isInstagram: boolean;
    isTwitter: boolean;
    isLinkedIn: boolean;
    isTikTok: boolean;
    isSnapChat: boolean;
    isWhatsapp: boolean;
    isTelegram: boolean;
    isVimeo: boolean;
    isYoutube: boolean;
    isWistia: boolean;
    isTwitch: boolean;
    isDiscord: boolean;
    isPinterest: boolean;
    isYelp: boolean;
    isPaypal: boolean;
    isVenmo: boolean;
    isCashApp: boolean;
    isCalendly: boolean;
    isShopify: boolean;
    isGithub: boolean;
    isDribbble: boolean;
    isBehance: boolean;
    isCustomUrl: boolean;
    socialLinkStates: object = {
        isFacebook: false,
        isInstagram: false,
        isTwitter: false,
        isLinkedIn: false,
        isTikTok: false,
        isSnapChat: false,
        isWhatsapp: false,
        isTelegram: false,
        isVimeo: false,
        isYoutube: false,
        isWistia: false,
        isTwitch: false,
        isDiscord: false,
        isPinterest: false,
        isYelp: false,
        isPaypal: false,
        isVenmo: false,
        isCashApp: false,
        isCalendly: false,
        isShopify: false,
        isGithub: false,
        isDribbble: false,
        isBehance: false,
        isCustomUrl: false
    };
    MultiLangLinks: {[key: string]: typeof this.socialLinkStates} = {};
    isPreviewButtonClicked: boolean = false;
    geocoder = null;
    environment: any = environment;
    TEXT_FIELD_TYPES = TEXT_FIELD_TYPES;
    TEXT_FIELD_VALIDATIONS = TEXT_FIELD_VALIDATIONS;
    BUTTON_TYPES = BUTTON_TYPES;
    TOOLTIP_POSITION = TOOLTIP_POSITION;
    showLayoutSelectStep: boolean;
    BUTTON_STYLES = BUTTON_STYLES;
    CARET_POSITION = CARET_POSITION;
    showProfileImage = true;


    // Layout Data
    layoutData: Array<Object> = DBC_LAYOUTS;
    fontList: Array<Object> = Utils.available_fonts;
    vCardFontStyle: String = '';
    isMobile: boolean = false;

    custom_labels = []
    labelsForPhone = Utils.PHONE_LABELS
    labelsForEmail = Utils.EMAIL_LABELS
    labelsForWebsite = Utils.WEBSITE_LABELS

    markerOptions: google.maps.MarkerOptions = {draggable: true};
    center: google.maps.LatLngLiteral = {lat: 0, lng: 0};
    centerMultiLang: {[key: string]: google.maps.LatLngLiteral} = {en : {lat: 0, lng: 0}};
    addMarkerCluster: boolean = false;
    locationData: object = {lat: 0, lng: 0};
    locationDataMultiLang: {[key: string]: typeof this.locationData} = {};
    user: User;
    languageNames: {[key: string]: string} = {};

    DBCCardTypes = DBCCardTypes;
    CARD_PROPERTY_TYPES = CARD_PROPERTY_TYPES
    ICON_SIZE = ICON_SIZE;
    ICON_POSITION = ICON_POSITION;
    isOnSoloPlan: boolean = false;

    DBCCopies = {
        cardCreation: {
            Heading: 'Build Your Card',
            subHeading: 'Enter the details for your digital business card',
        },
        templateCreation: {
            Heading: 'Create Template',
            subHeading: 'Add details you want to include on your digital businesss card',
            profileSubheading: 'Add details you want to include on your digital businesss card',
        }
    }
    COLLAPSE = COLLAPSE;
    collapseSection = {
        personalInfo: false,
        companyDetails: false,
        contactDetails: false,
        socialLinks: false
    }
    collapseMultilanguage = {
        personalInfo: false,
        companyDetails: false,
        contactDetails: false,
        socialLinks: false
    }

    selectedTemplate: DigitalBusinessCardTemplateModel = null;
    hasCardsTemplateAccess: boolean = false;
    searchedIconInput: string = '';
    iconSearchInputSubject: Subject<string> = new Subject<string>();
    socialMediaMetaMobileList = socialMediaMetaData;
    showCustomIcon: boolean = false;
    showSvgSocialIcons: boolean = false;
    hasMultiLangAccess = false;
    photoUploadEventProperties = {}
    clonedProduct: any = null
    logoResizeMLangEnabled: boolean = false

    dragdropState: boolean = false;
    protected readonly CONTACT_DETAIL = CONTACT_DETAIL;
    constructor(private ngZone: NgZone,
        private messageModal: MessageModalService,
        private mediaService: MediaService, private mediaFolderService: MediaFolderService,
        private authService: AuthService, private upsellService: UpsellService, private languageService: LanguageService,
        private readonly dbcRbacService: DbcRbacService, private readonly amplitude: AmplitudeService) {
        this.isOnEnterprisePlan = this.authService.getUser().isOnEnterprisePlan();
        this.isOnSoloPlan = this.authService.getUser().isOnSoloPlan();
        this.user = this.authService.getUser();
        this.searchControlMultiLang[this.currentLanguage] = new UntypedFormControl();
        this.locationModelMultiLang[this.currentLanguage] = {
            latitude: 0,
            longitude: 0,
            placeId: '',
            url: ''
        };
        this.languageNames = this.reverseMapObject(this.languageService.allLanguagesNameCode);

        this.showSvgSocialIcons = true;
        this.hasMultiLangAccess = this.user.organization.dbc_feature_permissions.multi_language;

        this.getDbcRbacPermissions();
    }


    featuresVisibilityPermission: FeatureVisibilityPermisions;

    ngOnInit() {
        this.checkShowProfileImage();
        this.multiLangList = this.product.default_language ? [] : ['en'];
        Object.keys(this.product.language_data).forEach(lang => {
            this.multiLangList.push(lang);
            // initializing auto complete of app-map component
            this.locationModelMultiLang[lang] = deepCopy({
                latitude: 0,
                longitude: 0,
                placeId: '',
                url: this.product.language_data[lang].address_url,
            })
            this.locationDataMultiLang[lang] = deepCopy({lat: 0, lng: 0});
            this.searchControlMultiLang[lang] = new UntypedFormControl();
            this.centerMultiLang[lang] = deepCopy({lat: 0, lng: 0});

            this.socialFieldDetailsMultiLang[lang] = [];
            this.MultiLangLinks[lang] = {};
            for (const key in this.socialLinkStates) {
                if (this.socialLinkStates.hasOwnProperty(key)) {
                    this.MultiLangLinks[lang][key] = false;
                }
            }

            this.initializeContactFields(lang);

            // initialize and set validation object
            this.setValidationObjectForMultiLang(lang);
        });

        this.appendCustomLabelsToExistingLabels()

        this.isMobile = window.innerWidth < 671;
        this.contentChangeSubject.pipe(debounceTime(1000)).subscribe(event => {
            this.onVCardPlusUpdated();
        });
        this.isFrameLoadingSubject.pipe(debounceTime(100)).subscribe(event => {
            this.isFrameLoading = event;
        });
        this.disableNextButtonVcard = false;
        this.initializeAutoComplete();
        this.handleOldData();
        this.onVCardPlusUpdated(true);
        if (this.product.social_links) {
            const social_links = this.product.social_links;
            this.isFacebook = social_links.facebook ? true : false;
            if (this.isFacebook) {
                this.updateSocialFieldDetails(SOCIAL_MEDIA.Facebook);
            }
            this.isInstagram = social_links.instagram ? true : false;
            if (this.isInstagram) {
                this.updateSocialFieldDetails(SOCIAL_MEDIA.Instagram);
            }
            this.isTwitter = social_links.twitter ? true : false;
            if (this.isTwitter) {
                this.updateSocialFieldDetails(SOCIAL_MEDIA.Twitter);
            }
            this.isLinkedIn = social_links.linkedin ? true : false;
            if (this.isLinkedIn) {
                this.updateSocialFieldDetails(SOCIAL_MEDIA.LinkedIn);
            }
            this.isTikTok = social_links.tiktok ? true : false;
            if (this.isTikTok) {
                this.updateSocialFieldDetails(SOCIAL_MEDIA.TikTok);
            }
            this.isSnapChat = social_links.snapchat ? true : false;
            if (this.isSnapChat) {
                this.updateSocialFieldDetails(SOCIAL_MEDIA.SnapChat);
            }
            this.isWhatsapp = social_links.whatsapp ? true : false;
            if (this.isWhatsapp) {
                this.updateSocialFieldDetails(SOCIAL_MEDIA.Whatsapp);
            }
            this.isTelegram = social_links.telegram ? true : false;
            if (this.isTelegram) {
                this.updateSocialFieldDetails(SOCIAL_MEDIA.Telegram);
            }
            this.isVimeo = social_links.vimeo ? true : false;
            if (this.isVimeo) {
                this.updateSocialFieldDetails(SOCIAL_MEDIA.Vimeo);
            }
            this.isYoutube = social_links.youtube ? true : false;
            if (this.isYoutube) {
                this.updateSocialFieldDetails(SOCIAL_MEDIA.Youtube);
            }
            this.isWistia = social_links.wistia ? true : false;
            if (this.isWistia) {
                this.updateSocialFieldDetails(SOCIAL_MEDIA.Wistia);
            }
            this.isTwitch = social_links.twitch ? true : false;
            if (this.isTwitch) {
                this.updateSocialFieldDetails(SOCIAL_MEDIA.Twitch);
            }
            this.isDiscord = social_links.discord ? true : false;
            if (this.isDiscord) {
                this.updateSocialFieldDetails(SOCIAL_MEDIA.Discord);
            }
            this.isPinterest = social_links.pinterest ? true : false;
            if (this.isPinterest) {
                this.updateSocialFieldDetails(SOCIAL_MEDIA.Pinterest);
            }
            this.isYelp = social_links.yelp ? true : false;
            if (this.isYelp) {
                this.updateSocialFieldDetails(SOCIAL_MEDIA.Yelp);
            }
            this.isPaypal = social_links.paypal ? true : false;
            if (this.isPaypal) {
                this.updateSocialFieldDetails(SOCIAL_MEDIA.Paypal);
            }
            this.isVenmo = social_links.venmo ? true : false;
            if (this.isVenmo) {
                this.updateSocialFieldDetails(SOCIAL_MEDIA.Venmo);
            }
            this.isCashApp = social_links.cashapp ? true : false;
            if (this.isCashApp) {
                this.updateSocialFieldDetails(SOCIAL_MEDIA.CashApp);
            }
            this.isCalendly = social_links.calendly ? true : false;
            if (this.isCalendly) {
                this.updateSocialFieldDetails(SOCIAL_MEDIA.Calendly);
            }
            this.isShopify = social_links.shopify ? true : false;
            if (this.isShopify) {
                this.updateSocialFieldDetails(SOCIAL_MEDIA.Shopify);
            }
            this.isGithub = social_links.github ? true : false;
            if (this.isGithub) {
                this.updateSocialFieldDetails(SOCIAL_MEDIA.Github);
            }
            this.isDribbble = social_links.dribbble ? true : false;
            if (this.isDribbble) {
                this.updateSocialFieldDetails(SOCIAL_MEDIA.Dribbble);
            }
            this.isBehance = social_links.behance ? true : false;
            if (this.isBehance) {
                this.updateSocialFieldDetails(SOCIAL_MEDIA.Behance);
            }
            this.isCustomUrl = social_links.custom_url ? true : false;
            if (this.isCustomUrl) {
                this.updateSocialFieldDetails(SOCIAL_MEDIA.CustomUrl);
            }
        }

        if (this.product.language_data) {
            for (const language in this.product.language_data) {
                if (!this.product.language_data[language].social_links) {
                    continue;
                }
                const social_links = this.product.language_data[language].social_links;
                this.MultiLangLinks[language].isFacebook = social_links.facebook ? true : false;
                if (this.MultiLangLinks[language].isFacebook) {
                    this.updateSocialFieldDetailsMultiLang(SOCIAL_MEDIA.Facebook, language);
                }
                this.MultiLangLinks[language].isInstagram = social_links.instagram ? true : false;
                if (this.MultiLangLinks[language].isInstagram) {
                    this.updateSocialFieldDetailsMultiLang(SOCIAL_MEDIA.Instagram, language);
                }
                this.MultiLangLinks[language].isTwitter = social_links.twitter ? true : false;
                if (this.MultiLangLinks[language].isTwitter) {
                    this.updateSocialFieldDetailsMultiLang(SOCIAL_MEDIA.Twitter, language);
                }
                this.MultiLangLinks[language].isLinkedIn = social_links.linkedin ? true : false;
                if (this.MultiLangLinks[language].isLinkedIn) {
                    this.updateSocialFieldDetailsMultiLang(SOCIAL_MEDIA.LinkedIn, language);
                }
                this.MultiLangLinks[language].isTikTok = social_links.tiktok ? true : false;
                if (this.MultiLangLinks[language].isTikTok) {
                    this.updateSocialFieldDetailsMultiLang(SOCIAL_MEDIA.TikTok, language);
                }
                this.MultiLangLinks[language].isSnapChat = social_links.snapchat ? true : false;
                if (this.MultiLangLinks[language].isSnapChat) {
                    this.updateSocialFieldDetailsMultiLang(SOCIAL_MEDIA.SnapChat, language);
                }
                this.MultiLangLinks[language].isWhatsapp = social_links.whatsapp ? true : false;
                if (this.MultiLangLinks[language].isWhatsapp) {
                    this.updateSocialFieldDetailsMultiLang(SOCIAL_MEDIA.Whatsapp, language);
                }
                this.MultiLangLinks[language].isTelegram = social_links.telegram ? true : false;
                if (this.MultiLangLinks[language].isTelegram) {
                    this.updateSocialFieldDetailsMultiLang(SOCIAL_MEDIA.Telegram, language);
                }
                this.MultiLangLinks[language].isVimeo = social_links.vimeo ? true : false;
                if (this.MultiLangLinks[language].isVimeo) {
                    this.updateSocialFieldDetailsMultiLang(SOCIAL_MEDIA.Vimeo, language);
                }
                this.MultiLangLinks[language].isYoutube = social_links.youtube ? true : false;
                if (this.MultiLangLinks[language].isYoutube) {
                    this.updateSocialFieldDetailsMultiLang(SOCIAL_MEDIA.Youtube, language);
                }
                this.MultiLangLinks[language].isWistia = social_links.wistia ? true : false;
                if (this.MultiLangLinks[language].isWistia) {
                    this.updateSocialFieldDetailsMultiLang(SOCIAL_MEDIA.Wistia, language);
                }
                this.MultiLangLinks[language].isTwitch = social_links.twitch ? true : false;
                if (this.MultiLangLinks[language].isTwitch) {
                    this.updateSocialFieldDetailsMultiLang(SOCIAL_MEDIA.Twitch, language);
                }
                this.MultiLangLinks[language].isDiscord = social_links.discord ? true : false;
                if (this.MultiLangLinks[language].isDiscord) {
                    this.updateSocialFieldDetailsMultiLang(SOCIAL_MEDIA.Discord, language);
                }
                this.MultiLangLinks[language].isPinterest = social_links.pinterest ? true : false;
                if (this.MultiLangLinks[language].isPinterest) {
                    this.updateSocialFieldDetailsMultiLang(SOCIAL_MEDIA.Pinterest, language);
                }
                this.MultiLangLinks[language].isYelp = social_links.yelp ? true : false;
                if (this.MultiLangLinks[language].isYelp) {
                    this.updateSocialFieldDetailsMultiLang(SOCIAL_MEDIA.Yelp, language);
                }
                this.MultiLangLinks[language].isPaypal = social_links.paypal ? true : false;
                if (this.MultiLangLinks[language].isPaypal) {
                    this.updateSocialFieldDetailsMultiLang(SOCIAL_MEDIA.Paypal, language);
                }
                this.MultiLangLinks[language].isVenmo = social_links.venmo ? true : false;
                if (this.MultiLangLinks[language].isVenmo) {
                    this.updateSocialFieldDetailsMultiLang(SOCIAL_MEDIA.Venmo, language);
                }
                this.MultiLangLinks[language].isCashApp = social_links.cashapp ? true : false;
                if (this.MultiLangLinks[language].isCashApp) {
                    this.updateSocialFieldDetailsMultiLang(SOCIAL_MEDIA.CashApp, language);
                }
                this.MultiLangLinks[language].isCalendly = social_links.calendly ? true : false;
                if (this.MultiLangLinks[language].isCalendly) {
                    this.updateSocialFieldDetailsMultiLang(SOCIAL_MEDIA.Calendly, language);
                }
                this.MultiLangLinks[language].isShopify = social_links.shopify ? true : false;
                if (this.MultiLangLinks[language].isShopify) {
                    this.updateSocialFieldDetailsMultiLang(SOCIAL_MEDIA.Shopify, language);
                }
                this.MultiLangLinks[language].isGithub = social_links.github ? true : false;
                if (this.MultiLangLinks[language].isGithub) {
                    this.updateSocialFieldDetailsMultiLang(SOCIAL_MEDIA.Github, language);
                }
                this.MultiLangLinks[language].isDribbble = social_links.dribbble ? true : false;
                if (this.MultiLangLinks[language].isDribbble) {
                    this.updateSocialFieldDetailsMultiLang(SOCIAL_MEDIA.Dribbble, language);
                }
                this.MultiLangLinks[language].isBehance = social_links.behance ? true : false;
                if (this.MultiLangLinks[language].isBehance) {
                    this.updateSocialFieldDetailsMultiLang(SOCIAL_MEDIA.Behance, language);
                }
                this.MultiLangLinks[language].isCustomUrl = social_links.custom_url ? true : false;
                if (this.MultiLangLinks[language].isCustomUrl) {
                    this.updateSocialFieldDetailsMultiLang(SOCIAL_MEDIA.CustomUrl, language);
                }
            }

        }

        const {secondary_color, font_style} = this.product.customizations;
        if (!this.newVcard) {
            this.vCardFontStyle = font_style;
        }
        const {first_name} = this.product;
        this.product.first_name = first_name || 'John Doe';
        this.product.customizations.secondary_color = secondary_color || '#000000';
        this.showLayoutSelectStep = this.newVcard;
        this.hasCardsTemplateAccess = this.authService.getUser().organization.dbc_feature_permissions.card_template;
        this.onVCardContentChange(true);
        this.markerOptions.draggable = this.hasWriteAccess && !this.cardSetting.address;

        this.iconSearchInputSubject.pipe(takeUntil(this.ngUnsubscribe), debounceTime(200)).subscribe((searchedInput) => {
            this.searchedIconInput = searchedInput;
            this.filterIconListOnSearch();
        });

        this.logEventOnPageView()
        this.photoUploadEventProperties = {
            organization_id: this.authService.getCurrentOrgId(),
            new_card: this.newVcard,
            card_id: this.product?.id
        }
        this.clonedProduct = cloneDeep(this.product);
        this.stepOneClonedProduct.emit(this.clonedProduct);

        this.authService.getFeatureFlag(FEATURE_FLAGS.MULTI_LANG_LOGO_RESIZE, true).then((res) => {
            this.logoResizeMLangEnabled = res
        })
        this.product.logo_size = enlargeLogoSize(this.product.logo_size, this.product.layout);
    }

    getDbcRbacPermissions() {
        this.dbcRbacService.featuresVisibility$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
            this.featuresVisibilityPermission = res;
        })
    }

    checkShowProfileImage() {
        if (this.product.layout === '9' || this.product.layout === '11' || this.product.layout === '8') {
            this.showProfileImage = false;
        }
    }
    ngOnChanges(changes: SimpleChanges): void {
        this.checkForMobileView();
        if (changes['orgCardSetting'] && !changes['orgCardSetting'].firstChange) {
            this.appendCustomLabelsToExistingLabels()
        }
        if (changes['dbcFormUpsellTouhPointsPermission'] && !changes['dbcFormUpsellTouhPointsPermission'].firstChange) {
            this.dbcFormUpsellTouhPointsPermission = changes['dbcFormUpsellTouhPointsPermission'].currentValue;
        }

    }

    ngAfterViewInit(): void {
        if (!this.hasWriteAccess) {
            this.Utils.disableUserInteraction()
        }
        this.populateMapWithExistingValue();
        this.multiLangList.forEach(lang => {
            this.populateMapWithExistingValueMultiLang(lang);
        })
        setTimeout(() => {
            this.isMultiLanguageContentChange.emit(this.isMultiLanguageContent);
            this.multiLangListChange.emit(this.multiLangList);
        });
    }

    onVCardContentChange(duringInitialize = false) {
        if (!duringInitialize) {
            this.showSvgSocialIcons = this.product.layout === '7' || this.product.layout === '8';
            this.productChange.emit(this.product)
        }
        this.validationsChange.emit(this.validations);
    }

    onIFrameLoadingStateChange(value: boolean) {
        this.isFrameLoadingSubject.next(value);
    }

    onVCardPlusUpdated(duringInitialize = false) {
        if (!duringInitialize) {
            this.productChange.emit(this.product)
        }
    }

    setVCardLocationURL(event?: any) {
        if (event) {
            this.locationModel.latitude = event.lat;
            this.locationModel.longitude = event.lng;
            this.locationModel.placeId = '';
            const coordinates = {lat: this.locationModel.latitude, lng: this.locationModel.longitude};
            this.locationData = coordinates;
            this.center = coordinates;

            this.geocoder = new google.maps.Geocoder;
            const latlng = new google.maps.LatLng(this.locationModel.latitude, this.locationModel.longitude);

            this.geocoder.geocode({'latLng': latlng}, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    this.searchControl.setValue(results[0].formatted_address);
                } else {
                    this.messageModal.show('Error fetching Location', 'danger');
                }
            });
        }

        this.locationModel.url =
            `https://www.google.com/maps/search/?api=1&query=${this.locationModel.latitude},${this.locationModel.longitude}`;
        if (this.locationModel.placeId) {
            this.locationModel.url += `&query_place_id=${this.locationModel.placeId}`;
        }
        this.product.address_url = this.locationModel.url;
        this.setAddressField({'coords': {'lat': this.locationModel.latitude, 'lng': this.locationModel.longitude}})
        this.onVCardContentChange();
    }

    setVCardLocationURLMultiLang(event?: any) {
        if (event) {
            this.locationModelMultiLang[this.currentLanguage].latitude = event.lat;
            this.locationModelMultiLang[this.currentLanguage].longitude = event.lng;
            this.locationModelMultiLang[this.currentLanguage].placeId = '';
            const coordinates = {lat: this.locationModelMultiLang[this.currentLanguage].latitude, lng: this.locationModelMultiLang[this.currentLanguage].longitude};
            this.locationDataMultiLang[this.currentLanguage] = coordinates;
            this.centerMultiLang[this.currentLanguage] = coordinates;

            this.geocoder = new google.maps.Geocoder;
            const latlng = new google.maps.LatLng(this.locationModelMultiLang[this.currentLanguage].latitude, this.locationModelMultiLang[this.currentLanguage].longitude);

            this.geocoder.geocode({'latLng': latlng}, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    const searchControlObject = this.searchControlMultiLang[this.currentLanguage] = new UntypedFormControl();
                    searchControlObject.setValue(results[0].formatted_address);
                } else {
                    this.messageModal.show('Error fetching Location', 'danger');
                }
            });
        }

        this.locationModelMultiLang[this.currentLanguage].url =
            `https://www.google.com/maps/search/?api=1&query=${this.locationModelMultiLang[this.currentLanguage].latitude},${this.locationModelMultiLang[this.currentLanguage].longitude}`;
        if (this.locationModelMultiLang[this.currentLanguage].placeId) {
            this.locationModelMultiLang[this.currentLanguage].url += `&query_place_id=${this.locationModelMultiLang[this.currentLanguage].placeId}`;
        }
        this.product.language_data[this.currentLanguage].address_url = this.locationModelMultiLang[this.currentLanguage].url;
        this.setAddressFieldMultiLang({'coords': {'lat': this.locationModelMultiLang[this.currentLanguage].latitude, 'lng': this.locationModelMultiLang[this.currentLanguage].longitude}})
        this.onVCardContentChange();
    }

    initializeAutoComplete() {
        if (!this.searchElementRef) {
            setTimeout(() => {
                this.initializeAutoComplete();
            }, 100);
            return;
        }

        const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {});
        autocomplete.addListener('place_changed', () => {
            this.ngZone.run(() => {
                const place: google.maps.places.PlaceResult = autocomplete.getPlace();
                if (place.geometry === undefined || place.geometry === null) {
                    return;
                }
                this.locationModel.latitude = place.geometry.location.lat();
                this.locationModel.longitude = place.geometry.location.lng();
                const coordinates = {lat: this.locationModel.latitude, lng: this.locationModel.longitude};
                this.locationData = coordinates;
                this.center = coordinates;
                this.locationModel.placeId = place.place_id;
                this.setAddressField({'coords': {'lat': this.locationModel.latitude, 'lng': this.locationModel.longitude}})
                this.setVCardLocationURL();
            });
        });
    }

    initializeAutoCompleteMultiLang(language = this.currentLanguage) {
        if (!this.searchMultiLangElementRef) {
            setTimeout(() => {
                this.initializeAutoCompleteMultiLang();
            }, 100);
            return;
        }

        const autocomplete = new google.maps.places.Autocomplete(this.searchMultiLangElementRef.nativeElement, {});
        autocomplete.addListener('place_changed', () => {
            this.ngZone.run(() => {
                const place: google.maps.places.PlaceResult = autocomplete.getPlace();
                if (place.geometry === undefined || place.geometry === null) {
                    return;
                }
                this.locationModelMultiLang[language].latitude = place.geometry.location.lat();
                this.locationModelMultiLang[language].longitude = place.geometry.location.lng();
                const coordinates = {lat: this.locationModelMultiLang[language].latitude, lng: this.locationModelMultiLang[this.currentLanguage].longitude};
                this.locationDataMultiLang[language] = coordinates;
                this.centerMultiLang[language] = coordinates;
                this.locationModelMultiLang[language].placeId = place.place_id;
                this.setAddressFieldMultiLang({'coords': {'lat': this.locationModelMultiLang[language].latitude, 'lng': this.locationModelMultiLang[this.currentLanguage].longitude}})
                this.setVCardLocationURLMultiLang();
            });
        });
    }

    onVcardPlusUserImageAdded(file: File) {
        this.isIconUploading = true;
        this.mediaService.uploadFile(file, 'image').pipe(
            takeUntil(this.ngUnsubscribe),
            finalize(() => {
                this.isIconUploading = false;
            })).subscribe(media => {
            this.product.user_image_url = media.url;
            this.onVCardContentChange();
        }, error => {
            console.error(error);
            this.messageModal.show('Image upload failed', 'danger');
            this.vCardUserImageDrop.clear();
        });

    }

    onVcardPlusUserImageAddedMultiLang(file: File) {
        this.isIconUploading = true;
        this.mediaService.uploadFile(file, 'image').pipe(
            takeUntil(this.ngUnsubscribe),
            finalize(() => {
                this.isIconUploading = false;
            })).subscribe(media => {
            this.product.language_data[this.currentLanguage].user_image_url = media.url;
            this.onVCardContentChange();
        }, error => {
            console.error(error);
            this.messageModal.show('Image upload failed', 'danger');
            this.vCardUserImageDrop.clear();
        });

    }

    updateLogo(type: IMAGE_TYPE) {
        if ( (type === IMAGE_TYPE.DBCUserImage) && (!this.hasWriteAccess || (this.priortizeOrgSettings && this.orgCardSetting.user_image_url) || this.cardSetting.user_image_url)) {
            return;
        }
        this.imageSelectModel.type = type;
        this.imageSelectModal.show();
        if (type === IMAGE_TYPE.DBCUserImage) {
            this.logEventForChangeProfilePicAction()
        }
    }

    logEventForChangeProfilePicAction() {
        this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Engagement, AMPLITUDE_EVENTS.CARD_PHOTO_CHANGE, this.photoUploadEventProperties)
    }

    onVcardPlusUserImageRemoved() {
        if (this.isMultiLanguageContent) {
            this.product.language_data[this.currentLanguage].user_image_url = '';
        } else {
            this.product.user_image_url = '';
        }
        this.onVCardContentChange();
        this.logEventOnRemoveProfilePicture()
    }

    logEventOnRemoveProfilePicture() {
        this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Engagement, AMPLITUDE_EVENTS.CARD_PHOTO_REMOVE, this.photoUploadEventProperties)
    }

    setupImageSelectModal() {
        if (!this.imageCropperFile) {
            this.imageSelectModel = {
                media: null,
                link: '',
                alt: '',
                isUploading: false,
                type: this.imageSelectModel.type
            };
            this.updatedMedia = null;
            this.imageSelectGallery.fetchFolders();
        }
    }

    loadCropper(file) {
        this.imageCropperFile = file;
        if (this.imageCropperFile.type !== 'image/gif') {
            this.imageSelectModal.hide();
            this.showImageCropper();
        }
    }

    showImageCropper() {
        if (!this.imageCropper) {
            setTimeout(() => {
                this.showImageCropper();
            }, 100);
            return;
        }
        this.imageCropper.show();
    }

    closeImageCropperModal() {
        this.setUpImageCropperModal();
    }

    imageSelectedInGallery({media, clicked}) {
        if (media) {
            this.imageSelectModel.media = media;
            if (!clicked) {
                this.imageSelectModal.hide();
                this.selectImage();
            }
        } else {
            this.imageSelectModel.media = null;
        }
    }

    setUpImageCropperModal() {
        this.imageCropperFile = null;
    }

    selectImage() {
        this.imageSelectModal.hide();
        if (this.imageSelectModel.type === this.IMAGE_TYPE.DBCLogo) {
            if (this.isMultiLanguageContent) {
                this.product.language_data[this.currentLanguage].logo_url = this.imageSelectModel.media.url;
            } else {
                this.product.logo_url = this.imageSelectModel.media.url;
            }
            this.onVCardContentChange();
        } else if (this.imageSelectModel.type === this.IMAGE_TYPE.DBCUserImage) {
            if (this.isMultiLanguageContent) {
                this.product.language_data[this.currentLanguage].user_image_url = this.imageSelectModel.media.url;
            } else {
                this.product.user_image_url = this.imageSelectModel.media.url;
            }
            this.onVCardContentChange();
        }
        this.messageModal.show('Image updated', 'success');
        if (this.imageSelectModel.type === IMAGE_TYPE.DBCUserImage) {
            this.logEventOnSavingProfilePicture();
        }
    }

    onVcardPlusLogoAdded(file: File) {
        this.isIconUploading = true;
        this.mediaFolderService.getList(1, 1, {'logos': 'true'}).pipe(
            takeUntil(this.ngUnsubscribe)
        ).subscribe((folder) => {
            this.mediaService.uploadFile(file, 'image', null, null, folder.objects[0].id).pipe(
                takeUntil(this.ngUnsubscribe),
                finalize(() => {
                    this.isIconUploading = false;
                })).subscribe(media => {
                this.product.logo_url = media.url;
                this.onVCardContentChange();
            }, error => {
                console.error(error);
                this.messageModal.show('Image upload failed', 'danger');
                if (this.logoResizeMLangEnabled) {
                    this.logoDropVCardFeatureEnabled.clear()
                } else {
                    this.logoDropVCardFeatureDisabled.clear();
                }
            });
        });
    }

    onMultiLangDBCLogoAdded(file: File) {
        this.isIconUploading = true;
        this.mediaFolderService.getList(1, 1, {'logos': 'true'}).pipe(
            takeUntil(this.ngUnsubscribe)
        ).subscribe((folder) => {
            this.mediaService.uploadFile(file, 'image', null, null, folder.objects[0].id).pipe(
                takeUntil(this.ngUnsubscribe),
                finalize(() => {
                    this.isIconUploading = false;
                })).subscribe(media => {
                this.product.language_data[this.currentLanguage].logo_url = media.url;
                this.onVCardContentChange();
            }, error => {
                console.error(error);
                this.messageModal.show('Image upload failed', 'danger');
                if (this.logoResizeMLangEnabled) {
                    this.logoDropVCardFeatureEnabled.clear()
                } else {
                    this.logoDropVCardFeatureDisabled.clear();
                }
            });
        });
    }

    onDBCLogoAdded(file: File) {
        if (this.isMultiLanguageContent) {
            this.onMultiLangDBCLogoAdded(file)
        } else {
            this.onVcardPlusLogoAdded(file)
        }
    }

    onDBCLogoRemoved() {
        if (this.isMultiLanguageContent) {
            this.product.language_data[this.currentLanguage].logo_url = '';
        } else {
            this.product.logo_url = '';
        }
        this.onVCardContentChange();
    }

    // TODO : Make scrolling dynamic
    @HostListener('window:scroll', ['$event'])
    onWindowScroll(e) {
        const title = document.getElementById('customize-title');
        const tabs = document.getElementById('tabs');
        if (title && tabs) {
            if (window.pageYOffset >= 90 && window.innerWidth <= 414) {
                title.classList.add('sticky');
                tabs.classList.add('tabs-sticky');
            } else {
                title.classList.remove('sticky');
                tabs.classList.remove('tabs-sticky');
            }
        }
    }

    updateMedia(media) {
        if (this.imageCropper) {
            this.imageCropper.hide();
            this.setUpImageCropperModal()
        }
        this.updatedMedia = media;
        if (this.imageSelectModel.type === IMAGE_TYPE.DBCUserImage) {
            this.logEventOnSavingProfilePicture();
        }
    }

    isMediaSelected(id: number) {
        switch (id) {
            case SOCIAL_MEDIA.Facebook:
                return this.isFacebook;
            case SOCIAL_MEDIA.Instagram:
                return this.isInstagram;
            case SOCIAL_MEDIA.Twitter:
                return this.isTwitter;
            case SOCIAL_MEDIA.LinkedIn:
                return this.isLinkedIn;
            case SOCIAL_MEDIA.TikTok:
                return this.isTikTok;
            case SOCIAL_MEDIA.SnapChat:
                return this.isSnapChat;
            case SOCIAL_MEDIA.Whatsapp:
                return this.isWhatsapp;
            case SOCIAL_MEDIA.Telegram:
                return this.isTelegram;
            case SOCIAL_MEDIA.Vimeo:
                return this.isVimeo;
            case SOCIAL_MEDIA.Youtube:
                return this.isYoutube;
            case SOCIAL_MEDIA.Wistia:
                return this.isWistia;
            case SOCIAL_MEDIA.Twitch:
                return this.isTwitch;
            case SOCIAL_MEDIA.Discord:
                return this.isDiscord;
            case SOCIAL_MEDIA.Pinterest:
                return this.isPinterest;
            case SOCIAL_MEDIA.Yelp:
                return this.isYelp;
            case SOCIAL_MEDIA.Paypal:
                return this.isPaypal;
            case SOCIAL_MEDIA.Venmo:
                return this.isVenmo;
            case SOCIAL_MEDIA.CashApp:
                return this.isCashApp;
            case SOCIAL_MEDIA.Calendly:
                return this.isCalendly;
            case SOCIAL_MEDIA.Shopify:
                return this.isShopify;
            case SOCIAL_MEDIA.Github:
                return this.isGithub;
            case SOCIAL_MEDIA.Dribbble:
                return this.isDribbble;
            case SOCIAL_MEDIA.Behance:
                return this.isBehance;
            case SOCIAL_MEDIA.CustomUrl:
                return this.isCustomUrl;
        }
    }

    isMediaSelectedMultiLang(id: number) {
        if (this.MultiLangLinks[this.currentLanguage]) {
            switch (id) {
                case SOCIAL_MEDIA.Facebook:
                    return this.MultiLangLinks[this.currentLanguage].isFacebook;
                case SOCIAL_MEDIA.Instagram:
                    return this.MultiLangLinks[this.currentLanguage].isInstagram;
                case SOCIAL_MEDIA.Twitter:
                    return this.MultiLangLinks[this.currentLanguage].isTwitter;
                case SOCIAL_MEDIA.LinkedIn:
                    return this.MultiLangLinks[this.currentLanguage].isLinkedIn;
                case SOCIAL_MEDIA.TikTok:
                    return this.MultiLangLinks[this.currentLanguage].isTikTok;
                case SOCIAL_MEDIA.SnapChat:
                    return this.MultiLangLinks[this.currentLanguage].isSnapChat;
                case SOCIAL_MEDIA.Whatsapp:
                    return this.MultiLangLinks[this.currentLanguage].isWhatsapp;
                case SOCIAL_MEDIA.Telegram:
                    return this.MultiLangLinks[this.currentLanguage].isTelegram;
                case SOCIAL_MEDIA.Vimeo:
                    return this.MultiLangLinks[this.currentLanguage].isVimeo;
                case SOCIAL_MEDIA.Youtube:
                    return this.MultiLangLinks[this.currentLanguage].isYoutube;
                case SOCIAL_MEDIA.Wistia:
                    return this.MultiLangLinks[this.currentLanguage].isWistia;
                case SOCIAL_MEDIA.Twitch:
                    return this.MultiLangLinks[this.currentLanguage].isTwitch;
                case SOCIAL_MEDIA.Discord:
                    return this.MultiLangLinks[this.currentLanguage].isDiscord;
                case SOCIAL_MEDIA.Pinterest:
                    return this.MultiLangLinks[this.currentLanguage].isPinterest;
                case SOCIAL_MEDIA.Yelp:
                    return this.MultiLangLinks[this.currentLanguage].isYelp;
                case SOCIAL_MEDIA.Paypal:
                    return this.MultiLangLinks[this.currentLanguage].isPaypal;
                case SOCIAL_MEDIA.Venmo:
                    return this.MultiLangLinks[this.currentLanguage].isVenmo;
                case SOCIAL_MEDIA.CashApp:
                    return this.MultiLangLinks[this.currentLanguage].isCashApp;
                case SOCIAL_MEDIA.Calendly:
                    return this.MultiLangLinks[this.currentLanguage].isCalendly;
                case SOCIAL_MEDIA.Shopify:
                    return this.MultiLangLinks[this.currentLanguage].isShopify;
                case SOCIAL_MEDIA.Github:
                    return this.MultiLangLinks[this.currentLanguage].isGithub;
                case SOCIAL_MEDIA.Dribbble:
                    return this.MultiLangLinks[this.currentLanguage].isDribbble;
                case SOCIAL_MEDIA.Behance:
                    return this.MultiLangLinks[this.currentLanguage].isBehance;
                case SOCIAL_MEDIA.CustomUrl:
                    return this.MultiLangLinks[this.currentLanguage].isCustomUrl;
            }
        }
    }

    updateSocialFieldDetails(id: number) {
        let socialFieldDetail = {};
        switch (id) {
            case SOCIAL_MEDIA.Facebook:
                socialFieldDetail = {
                    title: 'Facebook',
                    image: '../../../assets/img/vcard-plus-social-icons/facebook_icon.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.social_links.facebook,
                    font_aweomse_class: socialIconsFontClasses['facebook']
                }
                this.socialFieldDetails.push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.Instagram:
                socialFieldDetail = {
                    title: 'Instagram',
                    image: '../../../assets/img/vcard-plus-social-icons/instagram_icon.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.social_links.instagram,
                    font_aweomse_class: socialIconsFontClasses['instagram']
                }
                this.socialFieldDetails.push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.Twitter:
                socialFieldDetail = {
                    title: 'Twitter',
                    image: '../../../assets/img/vcard-plus-social-icons/twitter_icon.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.social_links.twitter
                }
                this.socialFieldDetails.push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.LinkedIn:
                socialFieldDetail = {
                    title: 'LinkedIn',
                    image: '../../../assets/img/vcard-plus-social-icons/linkedin_icon.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.social_links.linkedin,
                    font_aweomse_class: socialIconsFontClasses['linkedin']
                }
                this.socialFieldDetails.push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.TikTok:
                socialFieldDetail = {
                    title: 'TikTok',
                    image: '../../../assets/img/vcard-plus-social-icons/tiktok_icon.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.social_links.tiktok,
                    font_aweomse_class: socialIconsFontClasses['tiktok']
                }
                this.socialFieldDetails.push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.SnapChat:
                socialFieldDetail = {
                    title: 'Snapchat',
                    image: '../../../assets/img/vcard-plus-social-icons/snapchat_icon.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.social_links.snapchat,
                    font_aweomse_class: socialIconsFontClasses['snapchat']
                }
                this.socialFieldDetails.push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.Whatsapp:
                socialFieldDetail = {
                    title: 'Whatsapp',
                    image: '../../../assets/img/vcard-plus-social-icons/whatsapp_icon.png',
                    pos: id,
                    placeholder: 'Enter phone no with country code',
                    model: this.product.social_links.whatsapp,
                    font_aweomse_class: socialIconsFontClasses['whatsapp']
                }
                this.socialFieldDetails.push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.Telegram:
                socialFieldDetail = {
                    title: 'Telegram',
                    image: '../../../assets/img/vcard-plus-social-icons/telegram.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.social_links.telegram,
                    font_aweomse_class: socialIconsFontClasses['telegram']
                }
                this.socialFieldDetails.push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.Vimeo:
                socialFieldDetail = {
                    title: 'Vimeo',
                    image: '../../../assets/img/vcard-plus-social-icons/Vimeo.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.social_links.vimeo,
                    font_aweomse_class: socialIconsFontClasses['vimeo']
                }
                this.socialFieldDetails.push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.Youtube:
                socialFieldDetail = {
                    title: 'Youtube',
                    image: '../../../assets/img/vcard-plus-social-icons/youtube_icon.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.social_links.youtube,
                    font_aweomse_class: socialIconsFontClasses['youtube']
                }
                this.socialFieldDetails.push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.Wistia:
                socialFieldDetail = {
                    title: 'Wistia',
                    image: '../../../assets/img/vcard-plus-social-icons/Vistea.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.social_links.wistia
                }
                this.socialFieldDetails.push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.Twitch:
                socialFieldDetail = {
                    title: 'Twitch',
                    image: '../../../assets/img/vcard-plus-social-icons/Twitch.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.social_links.twitch,
                    font_aweomse_class: socialIconsFontClasses['twitch']
                }
                this.socialFieldDetails.push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.Discord:
                socialFieldDetail = {
                    title: 'Discord',
                    image: '../../../assets/img/vcard-plus-social-icons/Discord.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.social_links.discord,
                    font_aweomse_class: socialIconsFontClasses['discord']
                }
                this.socialFieldDetails.push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.Pinterest:
                socialFieldDetail = {
                    title: 'Pinterest',
                    image: '../../../assets/img/vcard-plus-social-icons/pinterest_icon.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.social_links.pinterest,
                    font_aweomse_class: socialIconsFontClasses['pinterest']
                }
                this.socialFieldDetails.push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.Yelp:
                socialFieldDetail = {
                    title: 'Yelp',
                    image: '../../../assets/img/vcard-plus-social-icons/Yelp.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.social_links.yelp,
                    font_aweomse_class: socialIconsFontClasses['yelp']
                }
                this.socialFieldDetails.push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.Paypal:
                socialFieldDetail = {
                    title: 'Paypal',
                    image: '../../../assets/img/vcard-plus-social-icons/Paypal.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.social_links.paypal,
                    font_aweomse_class: socialIconsFontClasses['paypal']
                }
                this.socialFieldDetails.push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.Venmo:
                socialFieldDetail = {
                    title: 'Venmo',
                    image: '../../../assets/img/vcard-plus-social-icons/Venmo.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.social_links.venmo
                }
                this.socialFieldDetails.push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.CashApp:
                socialFieldDetail = {
                    title: 'CashApp',
                    image: '../../../assets/img/vcard-plus-social-icons/Cashapp.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.social_links.cashapp
                }
                this.socialFieldDetails.push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.Calendly:
                socialFieldDetail = {
                    title: 'Calendly',
                    image: '../../../assets/img/vcard-plus-social-icons/Calendly.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.social_links.calendly
                }
                this.socialFieldDetails.push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.Shopify:
                socialFieldDetail = {
                    title: 'Shopify',
                    image: '../../../assets/img/vcard-plus-social-icons/shopify.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.social_links.shopify,
                    font_aweomse_class: socialIconsFontClasses['shopify']
                }
                this.socialFieldDetails.push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.Github:
                socialFieldDetail = {
                    title: 'Github',
                    image: '../../../assets/img/vcard-plus-social-icons/Github.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.social_links.github,
                    font_aweomse_class: socialIconsFontClasses['github']
                }
                this.socialFieldDetails.push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.Dribbble:
                socialFieldDetail = {
                    title: 'Dribbble',
                    image: '../../../assets/img/vcard-plus-social-icons/Dribbble.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.social_links.dribbble,
                    font_aweomse_class: socialIconsFontClasses['dribbble']
                }
                this.socialFieldDetails.push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.Behance:
                socialFieldDetail = {
                    title: 'Behance',
                    image: '../../../assets/img/vcard-plus-social-icons/Behance.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.social_links.behance,
                    font_aweomse_class: socialIconsFontClasses['behance']
                }
                this.socialFieldDetails.push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.CustomUrl:
                socialFieldDetail = {
                    title: 'Custom URL',
                    image: '../../../assets/img/vcard-plus-social-icons/URL.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.social_links.custom_url
                }
                this.socialFieldDetails.push(socialFieldDetail);
                break;
        }
        this.resetScialIconsSearch()

    }

    updateSocialFieldDetailsMultiLang(id: number, lang = this.currentLanguage) {
        let socialFieldDetail = {};
        switch (id) {
            case SOCIAL_MEDIA.Facebook:
                socialFieldDetail = {
                    title: 'Facebook',
                    image: '../../../assets/img/vcard-plus-social-icons/facebook_icon.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.language_data[lang].social_links.facebook,
                    font_aweomse_class: socialIconsFontClasses['facebook']
                }
                this.socialFieldDetailsMultiLang[lang].push(socialFieldDetail);
                this.onVCardContentChange();
                break;
            case SOCIAL_MEDIA.Instagram:
                socialFieldDetail = {
                    title: 'Instagram',
                    image: '../../../assets/img/vcard-plus-social-icons/instagram_icon.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.language_data[lang].social_links.instagram,
                    font_aweomse_class: socialIconsFontClasses['instagram']
                }
                this.socialFieldDetailsMultiLang[lang].push(socialFieldDetail);
                this.onVCardContentChange();
                break;
            case SOCIAL_MEDIA.Twitter:
                socialFieldDetail = {
                    title: 'Twitter',
                    image: '../../../assets/img/vcard-plus-social-icons/twitter_icon.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.language_data[lang].social_links.twitter
                }
                this.socialFieldDetailsMultiLang[lang].push(socialFieldDetail);
                this.onVCardContentChange();
                break;
            case SOCIAL_MEDIA.LinkedIn:
                socialFieldDetail = {
                    title: 'LinkedIn',
                    image: '../../../assets/img/vcard-plus-social-icons/linkedin_icon.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.language_data[lang].social_links.linkedin,
                    font_aweomse_class: socialIconsFontClasses['linkedin']
                }
                this.socialFieldDetailsMultiLang[lang].push(socialFieldDetail);
                this.onVCardContentChange();
                break;
            case SOCIAL_MEDIA.TikTok:
                socialFieldDetail = {
                    title: 'TikTok',
                    image: '../../../assets/img/vcard-plus-social-icons/tiktok_icon.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.language_data[lang].social_links.tiktok,
                    font_aweomse_class: socialIconsFontClasses['tiktok']
                }
                this.socialFieldDetailsMultiLang[lang].push(socialFieldDetail);
                this.onVCardContentChange();
                break;
            case SOCIAL_MEDIA.SnapChat:
                socialFieldDetail = {
                    title: 'Snapchat',
                    image: '../../../assets/img/vcard-plus-social-icons/snapchat_icon.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.language_data[lang].social_links.snapchat,
                    font_aweomse_class: socialIconsFontClasses['snapchat']
                }
                this.socialFieldDetailsMultiLang[lang].push(socialFieldDetail);
                this.onVCardContentChange();
                break;
            case SOCIAL_MEDIA.Whatsapp:
                socialFieldDetail = {
                    title: 'Whatsapp',
                    image: '../../../assets/img/vcard-plus-social-icons/whatsapp_icon.png',
                    pos: id,
                    placeholder: 'Enter phone no with country code',
                    model: this.product.language_data[lang].social_links.whatsapp,
                    font_aweomse_class: socialIconsFontClasses['whatsapp']
                }
                this.socialFieldDetailsMultiLang[lang].push(socialFieldDetail);
                this.onVCardContentChange();
                break;
            case SOCIAL_MEDIA.Telegram:
                socialFieldDetail = {
                    title: 'Telegram',
                    image: '../../../assets/img/vcard-plus-social-icons/telegram.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.language_data[lang].social_links.telegram,
                    font_aweomse_class: socialIconsFontClasses['telegram']
                }
                this.socialFieldDetailsMultiLang[lang].push(socialFieldDetail);
                this.onVCardContentChange();
                break;
            case SOCIAL_MEDIA.Vimeo:
                socialFieldDetail = {
                    title: 'Vimeo',
                    image: '../../../assets/img/vcard-plus-social-icons/Vimeo.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.language_data[lang].social_links.vimeo,
                    font_aweomse_class: socialIconsFontClasses['vimeo']
                }
                this.socialFieldDetailsMultiLang[lang].push(socialFieldDetail);
                this.onVCardContentChange();
                break;
            case SOCIAL_MEDIA.Youtube:
                socialFieldDetail = {
                    title: 'Youtube',
                    image: '../../../assets/img/vcard-plus-social-icons/youtube_icon.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.language_data[lang].social_links.youtube,
                    font_aweomse_class: socialIconsFontClasses['youtube']
                }
                this.socialFieldDetailsMultiLang[lang].push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.Wistia:
                socialFieldDetail = {
                    title: 'Wistia',
                    image: '../../../assets/img/vcard-plus-social-icons/Vistea.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.language_data[lang].social_links.wistia
                }
                this.socialFieldDetailsMultiLang[lang].push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.Twitch:
                socialFieldDetail = {
                    title: 'Twitch',
                    image: '../../../assets/img/vcard-plus-social-icons/Twitch.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.language_data[lang].social_links.twitch,
                    font_aweomse_class: socialIconsFontClasses['twitch']
                }
                this.socialFieldDetailsMultiLang[lang].push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.Discord:
                socialFieldDetail = {
                    title: 'Discord',
                    image: '../../../assets/img/vcard-plus-social-icons/Discord.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.language_data[lang].social_links.discord,
                    font_aweomse_class: socialIconsFontClasses['discord']
                }
                this.socialFieldDetailsMultiLang[lang].push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.Pinterest:
                socialFieldDetail = {
                    title: 'Pinterest',
                    image: '../../../assets/img/vcard-plus-social-icons/pinterest_icon.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.language_data[lang].social_links.pinterest,
                    font_aweomse_class: socialIconsFontClasses['pinterest']
                }
                this.socialFieldDetailsMultiLang[lang].push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.Yelp:
                socialFieldDetail = {
                    title: 'Yelp',
                    image: '../../../assets/img/vcard-plus-social-icons/Yelp.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.language_data[lang].social_links.yelp,
                    font_aweomse_class: socialIconsFontClasses['yelp']
                }
                this.socialFieldDetailsMultiLang[lang].push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.Paypal:
                socialFieldDetail = {
                    title: 'Paypal',
                    image: '../../../assets/img/vcard-plus-social-icons/Paypal.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.language_data[lang].social_links.paypal,
                    font_aweomse_class: socialIconsFontClasses['paypal']
                }
                this.socialFieldDetailsMultiLang[lang].push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.Venmo:
                socialFieldDetail = {
                    title: 'Venmo',
                    image: '../../../assets/img/vcard-plus-social-icons/Venmo.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.language_data[lang].social_links.venmo
                }
                this.socialFieldDetailsMultiLang[lang].push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.CashApp:
                socialFieldDetail = {
                    title: 'CashApp',
                    image: '../../../assets/img/vcard-plus-social-icons/Cashapp.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.language_data[lang].social_links.cashapp
                }
                this.socialFieldDetailsMultiLang[lang].push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.Calendly:
                socialFieldDetail = {
                    title: 'Calendly',
                    image: '../../../assets/img/vcard-plus-social-icons/Calendly.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.language_data[lang].social_links.calendly
                }
                this.socialFieldDetailsMultiLang[lang].push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.Shopify:
                socialFieldDetail = {
                    title: 'Shopify',
                    image: '../../../assets/img/vcard-plus-social-icons/shopify.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.language_data[lang].social_links.shopify,
                    font_aweomse_class: socialIconsFontClasses['shopify']
                }
                this.socialFieldDetailsMultiLang[lang].push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.Github:
                socialFieldDetail = {
                    title: 'Github',
                    image: '../../../assets/img/vcard-plus-social-icons/Github.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.language_data[lang].social_links.github,
                    font_aweomse_class: socialIconsFontClasses['github']
                }
                this.socialFieldDetailsMultiLang[lang].push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.Dribbble:
                socialFieldDetail = {
                    title: 'Dribbble',
                    image: '../../../assets/img/vcard-plus-social-icons/Dribbble.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.language_data[lang].social_links.dribbble,
                    font_aweomse_class: socialIconsFontClasses['dribbble']
                }
                this.socialFieldDetailsMultiLang[lang].push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.Behance:
                socialFieldDetail = {
                    title: 'Behance',
                    image: '../../../assets/img/vcard-plus-social-icons/Behance.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.language_data[lang].social_links.behance,
                    font_aweomse_class: socialIconsFontClasses['behance']
                }
                this.socialFieldDetailsMultiLang[lang].push(socialFieldDetail);
                break;
            case SOCIAL_MEDIA.CustomUrl:
                socialFieldDetail = {
                    title: 'Custom URL',
                    image: '../../../assets/img/vcard-plus-social-icons/URL.png',
                    pos: id,
                    placeholder: 'Enter URL',
                    model: this.product.language_data[lang].social_links.custom_url
                }
                this.socialFieldDetailsMultiLang[lang].push(socialFieldDetail);
                break;
        }
        this.resetScialIconsSearch()

    }

    addSocialField(id: number) {
        switch (id) {
            case SOCIAL_MEDIA.Facebook:
                this.product.social_links.facebook = 'https://www.facebook.com/username';
                if (!this.isFacebook) {
                    this.isFacebook = true;
                    this.updateSocialFieldDetails(id);
                }
                break;
            case SOCIAL_MEDIA.Instagram:
                this.product.social_links.instagram = 'https://www.instagram.com/username';
                if (!this.isInstagram) {
                    this.isInstagram = true;
                    this.updateSocialFieldDetails(id);
                }
                break;
            case SOCIAL_MEDIA.Twitter:
                this.product.social_links.twitter = 'http://twitter.com/username';
                if (!this.isTwitter) {
                    this.isTwitter = true;
                    this.updateSocialFieldDetails(id);
                }
                break;
            case SOCIAL_MEDIA.LinkedIn:
                this.product.social_links.linkedin = 'https://www.linkedin.com/in/username';
                if (!this.isLinkedIn) {
                    this.isLinkedIn = true;
                    this.updateSocialFieldDetails(id);
                }
                break;
            case SOCIAL_MEDIA.TikTok:
                this.product.social_links.tiktok = 'https://www.tiktok.com/@username';
                if (!this.isTikTok) {
                    this.isTikTok = true;
                    this.updateSocialFieldDetails(id);
                }
                break;
            case SOCIAL_MEDIA.SnapChat:
                this.product.social_links.snapchat = 'https://snapchat.com/add/username';
                if (!this.isSnapChat) {
                    this.isSnapChat = true;
                    this.updateSocialFieldDetails(id);
                }
                break;
            case SOCIAL_MEDIA.Whatsapp:
                this.product.social_links.whatsapp = '';
                if (!this.isWhatsapp) {
                    this.isWhatsapp = true;
                    this.updateSocialFieldDetails(id);
                }
                break;
            case SOCIAL_MEDIA.Telegram:
                this.product.social_links.telegram = 'https://telegram.me/username';
                if (!this.isTelegram) {
                    this.isTelegram = true;
                    this.updateSocialFieldDetails(id);
                }
                break;
            case SOCIAL_MEDIA.Vimeo:
                this.product.social_links.vimeo = '';
                if (!this.isVimeo) {
                    this.isVimeo = true;
                    this.updateSocialFieldDetails(id);
                }
                break;
            case SOCIAL_MEDIA.Youtube:
                this.product.social_links.youtube = '';
                if (!this.isYoutube) {
                    this.isYoutube = true;
                    this.updateSocialFieldDetails(id);
                }
                break;
            case SOCIAL_MEDIA.Wistia:
                this.product.social_links.wistia = '';
                if (!this.isWistia) {
                    this.isWistia = true;
                    this.updateSocialFieldDetails(id);
                }
                break;
            case SOCIAL_MEDIA.Twitch:
                this.product.social_links.twitch = 'https://twitch.tv/username';
                if (!this.isTwitch) {
                    this.isTwitch = true;
                    this.updateSocialFieldDetails(id);
                }
                break;
            case SOCIAL_MEDIA.Discord:
                this.product.social_links.discord = 'https://discord.gg/username';
                if (!this.isDiscord) {
                    this.isDiscord = true;
                    this.updateSocialFieldDetails(id);
                }
                break;
            case SOCIAL_MEDIA.Pinterest:
                this.product.social_links.pinterest = 'https://www.pinterest.com/username';
                if (!this.isPinterest) {
                    this.isPinterest = true;
                    this.updateSocialFieldDetails(id);
                }
                break;
            case SOCIAL_MEDIA.Yelp:
                this.product.social_links.yelp = 'https://www.yelp.com/biz/username';
                if (!this.isYelp) {
                    this.isYelp = true;
                    this.updateSocialFieldDetails(id);
                }
                break;
            case SOCIAL_MEDIA.Paypal:
                this.product.social_links.paypal = 'https://paypal.me/username';
                if (!this.isPaypal) {
                    this.isPaypal = true;
                    this.updateSocialFieldDetails(id);
                }
                break;
            case SOCIAL_MEDIA.Venmo:
                this.product.social_links.venmo = 'https://venmo.com/username';
                if (!this.isVenmo) {
                    this.isVenmo = true;
                    this.updateSocialFieldDetails(id);
                }
                break;
            case SOCIAL_MEDIA.CashApp:
                this.product.social_links.cashapp = 'https://cash.app/$username';
                if (!this.isCashApp) {
                    this.isCashApp = true;
                    this.updateSocialFieldDetails(id);
                }
                break;
            case SOCIAL_MEDIA.Calendly:
                this.product.social_links.calendly = '';
                if (!this.isCalendly) {
                    this.isCalendly = true;
                    this.updateSocialFieldDetails(id);
                }
                break;
            case SOCIAL_MEDIA.Shopify:
                this.product.social_links.shopify = '';
                if (!this.isShopify) {
                    this.isShopify = true;
                    this.updateSocialFieldDetails(id);
                }
                break;
            case SOCIAL_MEDIA.Github:
                this.product.social_links.github = 'https://github.com/username';
                if (!this.isGithub) {
                    this.isGithub = true;
                    this.updateSocialFieldDetails(id);
                }
                break;
            case SOCIAL_MEDIA.Dribbble:
                this.product.social_links.dribbble = 'https://dribbble.com/username';
                if (!this.isDribbble) {
                    this.isDribbble = true;
                    this.updateSocialFieldDetails(id);
                }
                break;
            case SOCIAL_MEDIA.Behance:
                this.product.social_links.behance = 'https://www.behance.net/username';
                if (!this.isBehance) {
                    this.isBehance = true;
                    this.updateSocialFieldDetails(id);
                }
                break;
            case SOCIAL_MEDIA.CustomUrl:
                this.product.social_links.custom_url = '';
                this.showCustomIcon = false;
                if (!this.isCustomUrl) {
                    this.isCustomUrl = true;
                    this.updateSocialFieldDetails(id);
                }
                break;
        }
        this.onVCardContentChange();

    }

    addSocialFieldMultiLang(id: number) {
        switch (id) {
            case SOCIAL_MEDIA.Facebook:
                this.product.language_data[this.currentLanguage].social_links.facebook = 'https://www.facebook.com/username';
                if (!this.MultiLangLinks[this.currentLanguage].isFacebook) {
                    this.MultiLangLinks[this.currentLanguage].isFacebook = true;
                    this.updateSocialFieldDetailsMultiLang(id);
                }
                break;
            case SOCIAL_MEDIA.Instagram:
                this.product.language_data[this.currentLanguage].social_links.instagram = 'https://www.instagram.com/username';
                if (!this.MultiLangLinks[this.currentLanguage].isInstagram) {
                    this.MultiLangLinks[this.currentLanguage].isInstagram = true;
                    this.updateSocialFieldDetailsMultiLang(id);
                }
                break;
            case SOCIAL_MEDIA.Twitter:
                this.product.language_data[this.currentLanguage].social_links.twitter = 'http://twitter.com/username';
                if (!this.MultiLangLinks[this.currentLanguage].isTwitter) {
                    this.MultiLangLinks[this.currentLanguage].isTwitter = true;
                    this.updateSocialFieldDetailsMultiLang(id);
                }
                break;
            case SOCIAL_MEDIA.LinkedIn:
                this.product.language_data[this.currentLanguage].social_links.linkedin = 'https://www.linkedin.com/in/username';
                if (!this.MultiLangLinks[this.currentLanguage].isLinkedIn) {
                    this.MultiLangLinks[this.currentLanguage].isLinkedIn = true;
                    this.updateSocialFieldDetailsMultiLang(id);
                }
                break;
            case SOCIAL_MEDIA.TikTok:
                this.product.language_data[this.currentLanguage].social_links.tiktok = 'https://www.tiktok.com/@username';
                if (!this.MultiLangLinks[this.currentLanguage].isTikTok) {
                    this.MultiLangLinks[this.currentLanguage].isTikTok = true;
                    this.updateSocialFieldDetailsMultiLang(id);
                }
                break;
            case SOCIAL_MEDIA.SnapChat:
                this.product.language_data[this.currentLanguage].social_links.snapchat = 'https://snapchat.com/add/username';
                if (!this.MultiLangLinks[this.currentLanguage].isSnapChat) {
                    this.MultiLangLinks[this.currentLanguage].isSnapChat = true;
                    this.updateSocialFieldDetailsMultiLang(id);
                }
                break;
            case SOCIAL_MEDIA.Whatsapp:
                this.product.language_data[this.currentLanguage].social_links.whatsapp = '';
                if (!this.MultiLangLinks[this.currentLanguage].isWhatsapp) {
                    this.MultiLangLinks[this.currentLanguage].isWhatsapp = true;
                    this.updateSocialFieldDetailsMultiLang(id);
                }
                break;
            case SOCIAL_MEDIA.Telegram:
                this.product.language_data[this.currentLanguage].social_links.telegram = 'https://telegram.me/username';
                if (!this.MultiLangLinks[this.currentLanguage].isTelegram) {
                    this.MultiLangLinks[this.currentLanguage].isTelegram = true;
                    this.updateSocialFieldDetailsMultiLang(id);
                }
                break;
            case SOCIAL_MEDIA.Vimeo:
                this.product.language_data[this.currentLanguage].social_links.vimeo = '';
                if (!this.MultiLangLinks[this.currentLanguage].isVimeo) {
                    this.MultiLangLinks[this.currentLanguage].isVimeo = true;
                    this.updateSocialFieldDetailsMultiLang(id);
                }
                break;
            case SOCIAL_MEDIA.Youtube:
                this.product.language_data[this.currentLanguage].social_links.youtube = '';
                if (!this.MultiLangLinks[this.currentLanguage].isYoutube) {
                    this.MultiLangLinks[this.currentLanguage].isYoutube = true;
                    this.updateSocialFieldDetailsMultiLang(id);
                }
                break;
            case SOCIAL_MEDIA.Wistia:
                this.product.language_data[this.currentLanguage].social_links.wistia = '';
                if (!this.MultiLangLinks[this.currentLanguage].isWistia) {
                    this.MultiLangLinks[this.currentLanguage].isWistia = true;
                    this.updateSocialFieldDetailsMultiLang(id);
                }
                break;
            case SOCIAL_MEDIA.Twitch:
                this.product.language_data[this.currentLanguage].social_links.twitch = 'https://twitch.tv/username';
                if (!this.MultiLangLinks[this.currentLanguage].isTwitch) {
                    this.MultiLangLinks[this.currentLanguage].isTwitch = true;
                    this.updateSocialFieldDetailsMultiLang(id);
                }
                break;
            case SOCIAL_MEDIA.Discord:
                this.product.language_data[this.currentLanguage].social_links.discord = 'https://discord.gg/username';
                if (!this.MultiLangLinks[this.currentLanguage].isDiscord) {
                    this.MultiLangLinks[this.currentLanguage].isDiscord = true;
                    this.updateSocialFieldDetailsMultiLang(id);
                }
                break;
            case SOCIAL_MEDIA.Pinterest:
                this.product.language_data[this.currentLanguage].social_links.pinterest = 'https://www.pinterest.com/username';
                if (!this.MultiLangLinks[this.currentLanguage].isPinterest) {
                    this.MultiLangLinks[this.currentLanguage].isPinterest = true;
                    this.updateSocialFieldDetailsMultiLang(id);
                }
                break;
            case SOCIAL_MEDIA.Yelp:
                this.product.language_data[this.currentLanguage].social_links.yelp = 'https://www.yelp.com/biz/username';
                if (!this.MultiLangLinks[this.currentLanguage].isYelp) {
                    this.MultiLangLinks[this.currentLanguage].isYelp = true;
                    this.updateSocialFieldDetailsMultiLang(id);
                }
                break;
            case SOCIAL_MEDIA.Paypal:
                this.product.language_data[this.currentLanguage].social_links.paypal = 'https://paypal.me/username';
                if (!this.MultiLangLinks[this.currentLanguage].isPaypal) {
                    this.MultiLangLinks[this.currentLanguage].isPaypal = true;
                    this.updateSocialFieldDetailsMultiLang(id);
                }
                break;
            case SOCIAL_MEDIA.Venmo:
                this.product.language_data[this.currentLanguage].social_links.venmo = 'https://venmo.com/username';
                if (!this.MultiLangLinks[this.currentLanguage].isVenmo) {
                    this.MultiLangLinks[this.currentLanguage].isVenmo = true;
                    this.updateSocialFieldDetailsMultiLang(id);
                }
                break;
            case SOCIAL_MEDIA.CashApp:
                this.product.language_data[this.currentLanguage].social_links.cashapp = 'https://cash.app/$username';
                if (!this.MultiLangLinks[this.currentLanguage].isCashApp) {
                    this.MultiLangLinks[this.currentLanguage].isCashApp = true;
                    this.updateSocialFieldDetailsMultiLang(id);
                }
                break;
            case SOCIAL_MEDIA.Calendly:
                this.product.language_data[this.currentLanguage].social_links.calendly = '';
                if (!this.MultiLangLinks[this.currentLanguage].isCalendly) {
                    this.MultiLangLinks[this.currentLanguage].isCalendly = true;
                    this.updateSocialFieldDetailsMultiLang(id);
                }
                break;
            case SOCIAL_MEDIA.Shopify:
                this.product.language_data[this.currentLanguage].social_links.shopify = '';
                if (!this.MultiLangLinks[this.currentLanguage].isShopify) {
                    this.MultiLangLinks[this.currentLanguage].isShopify = true;
                    this.updateSocialFieldDetailsMultiLang(id);
                }
                break;
            case SOCIAL_MEDIA.Github:
                this.product.language_data[this.currentLanguage].social_links.github = 'https://github.com/username';
                if (!this.MultiLangLinks[this.currentLanguage].isGithub) {
                    this.MultiLangLinks[this.currentLanguage].isGithub = true;
                    this.updateSocialFieldDetailsMultiLang(id);
                }
                break;
            case SOCIAL_MEDIA.Dribbble:
                this.product.language_data[this.currentLanguage].social_links.dribbble = 'https://dribbble.com/username';
                if (!this.MultiLangLinks[this.currentLanguage].isDribbble) {
                    this.MultiLangLinks[this.currentLanguage].isDribbble = true;
                    this.updateSocialFieldDetailsMultiLang(id);
                }
                break;
            case SOCIAL_MEDIA.Behance:
                this.product.language_data[this.currentLanguage].social_links.behance = 'https://www.behance.net/username';
                if (!this.MultiLangLinks[this.currentLanguage].isBehance) {
                    this.MultiLangLinks[this.currentLanguage].isBehance = true;
                    this.updateSocialFieldDetailsMultiLang(id);
                }
                break;
            case SOCIAL_MEDIA.CustomUrl:
                this.product.language_data[this.currentLanguage].social_links.custom_url = '';
                if (!this.MultiLangLinks[this.currentLanguage].isCustomUrl) {
                    this.MultiLangLinks[this.currentLanguage].isCustomUrl = true;
                    this.updateSocialFieldDetailsMultiLang(id);
                }
                break;
        }
        this.onVCardContentChange();

    }

    removeSocialField(index: number) {
        const pos = this.socialFieldDetails[index]['pos'];
        this.socialFieldDetails.splice(index, 1);
        switch (pos) {
            case SOCIAL_MEDIA.Facebook:
                this.product.social_links.facebook = '';
                this.isFacebook = false;
                break;
            case SOCIAL_MEDIA.Instagram:
                this.product.social_links.instagram = '';
                this.isInstagram = false;
                break;
            case SOCIAL_MEDIA.Twitter:
                this.product.social_links.twitter = '';
                this.isTwitter = false;
                break;
            case SOCIAL_MEDIA.LinkedIn:
                this.product.social_links.linkedin = '';
                this.isLinkedIn = false;
                break;
            case SOCIAL_MEDIA.TikTok:
                this.product.social_links.tiktok = '';
                this.isTikTok = false;
                break;
            case SOCIAL_MEDIA.SnapChat:
                this.product.social_links.snapchat = '';
                this.isSnapChat = false;
                break;
            case SOCIAL_MEDIA.Whatsapp:
                this.product.social_links.whatsapp = '';
                this.isWhatsapp = false;
                break;
            case SOCIAL_MEDIA.Telegram:
                this.product.social_links.telegram = '';
                this.isTelegram = false;
                break;
            case SOCIAL_MEDIA.Vimeo:
                this.product.social_links.vimeo = '';
                this.isVimeo = false;
                break;
            case SOCIAL_MEDIA.Youtube:
                this.product.social_links.youtube = '';
                this.isYoutube = false;
                break;
            case SOCIAL_MEDIA.Wistia:
                this.product.social_links.wistia = '';
                this.isWistia = false;
                break;
            case SOCIAL_MEDIA.Twitch:
                this.product.social_links.twitch = '';
                this.isTwitch = false;
                break;
            case SOCIAL_MEDIA.Discord:
                this.product.social_links.discord = '';
                this.isDiscord = false;
                break;
            case SOCIAL_MEDIA.Pinterest:
                this.product.social_links.pinterest = '';
                this.isPinterest = false;
                break;
            case SOCIAL_MEDIA.Yelp:
                this.product.social_links.yelp = '';
                this.isYelp = false;
                break;
            case SOCIAL_MEDIA.Paypal:
                this.product.social_links.paypal = '';
                this.isPaypal = false;
                break;
            case SOCIAL_MEDIA.Venmo:
                this.product.social_links.venmo = '';
                this.isVenmo = false;
                break;
            case SOCIAL_MEDIA.CashApp:
                this.product.social_links.cashapp = '';
                this.isCashApp = false;
                break;
            case SOCIAL_MEDIA.Calendly:
                this.product.social_links.calendly = '';
                this.isCalendly = false;
                break;
            case SOCIAL_MEDIA.Shopify:
                this.product.social_links.shopify = '';
                this.isShopify = false;
                break;
            case SOCIAL_MEDIA.Github:
                this.product.social_links.github = '';
                this.isGithub = false;
                break;
            case SOCIAL_MEDIA.Dribbble:
                this.product.social_links.dribbble = '';
                this.isDribbble = false;
                break;
            case SOCIAL_MEDIA.Behance:
                this.product.social_links.behance = '';
                this.isBehance = false;
                break;
            case SOCIAL_MEDIA.CustomUrl:
                this.product.social_links.custom_url = '';
                this.isCustomUrl = false;
                break;
        }
        this.onVCardContentChange();
    }

    removeSocialFieldMultiLang(index: number) {
        const pos = this.socialFieldDetailsMultiLang[this.currentLanguage][index]['pos'];
        this.socialFieldDetailsMultiLang[this.currentLanguage].splice(index, 1);
        switch (pos) {
            case SOCIAL_MEDIA.Facebook:
                this.product.language_data[this.currentLanguage].social_links.facebook = '';
                this.MultiLangLinks[this.currentLanguage].isFacebook = false;
                break;
            case SOCIAL_MEDIA.Instagram:
                this.product.language_data[this.currentLanguage].social_links.instagram = '';
                this.MultiLangLinks[this.currentLanguage].isInstagram = false;
                break;
            case SOCIAL_MEDIA.Twitter:
                this.product.language_data[this.currentLanguage].social_links.twitter = '';
                this.MultiLangLinks[this.currentLanguage].isTwitter = false;
                break;
            case SOCIAL_MEDIA.LinkedIn:
                this.product.language_data[this.currentLanguage].social_links.linkedin = '';
                this.MultiLangLinks[this.currentLanguage].isLinkedIn = false;
                break;
            case SOCIAL_MEDIA.TikTok:
                this.product.language_data[this.currentLanguage].social_links.tiktok = '';
                this.MultiLangLinks[this.currentLanguage].isTikTok = false;
                break;
            case SOCIAL_MEDIA.SnapChat:
                this.product.language_data[this.currentLanguage].social_links.snapchat = '';
                this.MultiLangLinks[this.currentLanguage].isSnapChat = false;
                break;
            case SOCIAL_MEDIA.Whatsapp:
                this.product.language_data[this.currentLanguage].social_links.whatsapp = '';
                this.MultiLangLinks[this.currentLanguage].isWhatsapp = false;
                break;
            case SOCIAL_MEDIA.Telegram:
                this.product.language_data[this.currentLanguage].social_links.telegram = '';
                this.MultiLangLinks[this.currentLanguage].isTelegram = false;
                break;
            case SOCIAL_MEDIA.Vimeo:
                this.product.language_data[this.currentLanguage].social_links.vimeo = '';
                this.MultiLangLinks[this.currentLanguage].isVimeo = false;
                break;
            case SOCIAL_MEDIA.Youtube:
                this.product.language_data[this.currentLanguage].social_links.youtube = '';
                this.MultiLangLinks[this.currentLanguage].isYoutube = false;
                break;
            case SOCIAL_MEDIA.Wistia:
                this.product.language_data[this.currentLanguage].social_links.wistia = '';
                this.MultiLangLinks[this.currentLanguage].isWistia = false;
                break;
            case SOCIAL_MEDIA.Twitch:
                this.product.language_data[this.currentLanguage].social_links.twitch = '';
                this.MultiLangLinks[this.currentLanguage].isTwitch = false;
                break;
            case SOCIAL_MEDIA.Discord:
                this.product.language_data[this.currentLanguage].social_links.discord = '';
                this.MultiLangLinks[this.currentLanguage].isDiscord = false;
                break;
            case SOCIAL_MEDIA.Pinterest:
                this.product.language_data[this.currentLanguage].social_links.pinterest = '';
                this.MultiLangLinks[this.currentLanguage].isPinterest = false;
                break;
            case SOCIAL_MEDIA.Yelp:
                this.product.language_data[this.currentLanguage].social_links.yelp = '';
                this.MultiLangLinks[this.currentLanguage].isYelp = false;
                break;
            case SOCIAL_MEDIA.Paypal:
                this.product.language_data[this.currentLanguage].social_links.paypal = '';
                this.MultiLangLinks[this.currentLanguage].isPaypal = false;
                break;
            case SOCIAL_MEDIA.Venmo:
                this.product.language_data[this.currentLanguage].social_links.venmo = '';
                this.MultiLangLinks[this.currentLanguage].isVenmo = false;
                break;
            case SOCIAL_MEDIA.CashApp:
                this.product.language_data[this.currentLanguage].social_links.cashapp = '';
                this.MultiLangLinks[this.currentLanguage].isCashApp = false;
                break;
            case SOCIAL_MEDIA.Calendly:
                this.product.language_data[this.currentLanguage].social_links.calendly = '';
                this.MultiLangLinks[this.currentLanguage].isCalendly = false;
                break;
            case SOCIAL_MEDIA.Shopify:
                this.product.language_data[this.currentLanguage].social_links.shopify = '';
                this.MultiLangLinks[this.currentLanguage].isShopify = false;
                break;
            case SOCIAL_MEDIA.Github:
                this.product.language_data[this.currentLanguage].social_links.github = '';
                this.MultiLangLinks[this.currentLanguage].isGithub = false;
                break;
            case SOCIAL_MEDIA.Dribbble:
                this.product.language_data[this.currentLanguage].social_links.dribbble = '';
                this.MultiLangLinks[this.currentLanguage].isDribbble = false;
                break;
            case SOCIAL_MEDIA.Behance:
                this.product.language_data[this.currentLanguage].social_links.behance = '';
                this.MultiLangLinks[this.currentLanguage].isBehance = false;
                break;
            case SOCIAL_MEDIA.CustomUrl:
                this.product.language_data[this.currentLanguage].social_links.custom_url = '';
                this.MultiLangLinks[this.currentLanguage].isCustomUrl = false;
                break;
        }
        this.onVCardContentChange();
    }

    onChangeSocialLink(event, pos: number) {
        switch (pos) {
            case SOCIAL_MEDIA.Facebook:
                this.product.social_links.facebook = event.target.value;
                break;
            case SOCIAL_MEDIA.Instagram:
                this.product.social_links.instagram = event.target.value;
                break;
            case SOCIAL_MEDIA.Twitter:
                this.product.social_links.twitter = event.target.value;
                break;
            case SOCIAL_MEDIA.LinkedIn:
                this.product.social_links.linkedin = event.target.value;
                break;
            case SOCIAL_MEDIA.TikTok:
                this.product.social_links.tiktok = event.target.value;
                break;
            case SOCIAL_MEDIA.SnapChat:
                this.product.social_links.snapchat = event.target.value;
                break;
            case SOCIAL_MEDIA.Whatsapp:
                this.product.social_links.whatsapp = event.target.value;
                break;
            case SOCIAL_MEDIA.Telegram:
                this.product.social_links.telegram = event.target.value;
                break;
            case SOCIAL_MEDIA.Vimeo:
                this.product.social_links.vimeo = event.target.value;
                break;
            case SOCIAL_MEDIA.Youtube:
                this.product.social_links.youtube = event.target.value;
                break;
            case SOCIAL_MEDIA.Wistia:
                this.product.social_links.wistia = event.target.value;
                break;
            case SOCIAL_MEDIA.Twitch:
                this.product.social_links.twitch = event.target.value;
                break;
            case SOCIAL_MEDIA.Discord:
                this.product.social_links.discord = event.target.value;
                break;
            case SOCIAL_MEDIA.Pinterest:
                this.product.social_links.pinterest = event.target.value;
                break;
            case SOCIAL_MEDIA.Yelp:
                this.product.social_links.yelp = event.target.value;
                break;
            case SOCIAL_MEDIA.Paypal:
                this.product.social_links.paypal = event.target.value;
                break;
            case SOCIAL_MEDIA.Venmo:
                this.product.social_links.venmo = event.target.value;
                break;
            case SOCIAL_MEDIA.CashApp:
                this.product.social_links.cashapp = event.target.value;
                break;
            case SOCIAL_MEDIA.Calendly:
                this.product.social_links.calendly = event.target.value;
                break;
            case SOCIAL_MEDIA.Shopify:
                this.product.social_links.shopify = event.target.value;
                break;
            case SOCIAL_MEDIA.Github:
                this.product.social_links.github = event.target.value;
                break;
            case SOCIAL_MEDIA.Dribbble:
                this.product.social_links.dribbble = event.target.value;
                break;
            case SOCIAL_MEDIA.Behance:
                this.product.social_links.behance = event.target.value;
                break;
            case SOCIAL_MEDIA.CustomUrl:
                this.product.social_links.custom_url = event.target.value;
                break;
        }
    }

    onChangeSocialLinkMultiLang(event, pos: number) {
        switch (pos) {
            case SOCIAL_MEDIA.Facebook:
                this.product.language_data[this.currentLanguage].social_links.facebook = event.target.value;
                break;
            case SOCIAL_MEDIA.Instagram:
                this.product.language_data[this.currentLanguage].social_links.instagram = event.target.value;
                break;
            case SOCIAL_MEDIA.Twitter:
                this.product.language_data[this.currentLanguage].social_links.twitter = event.target.value;
                break;
            case SOCIAL_MEDIA.LinkedIn:
                this.product.language_data[this.currentLanguage].social_links.linkedin = event.target.value;
                break;
            case SOCIAL_MEDIA.TikTok:
                this.product.language_data[this.currentLanguage].social_links.tiktok = event.target.value;
                break;
            case SOCIAL_MEDIA.SnapChat:
                this.product.language_data[this.currentLanguage].social_links.snapchat = event.target.value;
                break;
            case SOCIAL_MEDIA.Whatsapp:
                this.product.language_data[this.currentLanguage].social_links.whatsapp = event.target.value;
                break;
            case SOCIAL_MEDIA.Telegram:
                this.product.language_data[this.currentLanguage].social_links.telegram = event.target.value;
                break;
            case SOCIAL_MEDIA.Vimeo:
                this.product.language_data[this.currentLanguage].social_links.vimeo = event.target.value;
                break;
            case SOCIAL_MEDIA.Youtube:
                this.product.language_data[this.currentLanguage].social_links.youtube = event.target.value;
                break;
            case SOCIAL_MEDIA.Wistia:
                this.product.language_data[this.currentLanguage].social_links.wistia = event.target.value;
                break;
            case SOCIAL_MEDIA.Twitch:
                this.product.language_data[this.currentLanguage].social_links.twitch = event.target.value;
                break;
            case SOCIAL_MEDIA.Discord:
                this.product.language_data[this.currentLanguage].social_links.discord = event.target.value;
                break;
            case SOCIAL_MEDIA.Pinterest:
                this.product.language_data[this.currentLanguage].social_links.pinterest = event.target.value;
                break;
            case SOCIAL_MEDIA.Yelp:
                this.product.language_data[this.currentLanguage].social_links.yelp = event.target.value;
                break;
            case SOCIAL_MEDIA.Paypal:
                this.product.language_data[this.currentLanguage].social_links.paypal = event.target.value;
                break;
            case SOCIAL_MEDIA.Venmo:
                this.product.language_data[this.currentLanguage].social_links.venmo = event.target.value;
                break;
            case SOCIAL_MEDIA.CashApp:
                this.product.language_data[this.currentLanguage].social_links.cashapp = event.target.value;
                break;
            case SOCIAL_MEDIA.Calendly:
                this.product.language_data[this.currentLanguage].social_links.calendly = event.target.value;
                break;
            case SOCIAL_MEDIA.Shopify:
                this.product.language_data[this.currentLanguage].social_links.shopify = event.target.value;
                break;
            case SOCIAL_MEDIA.Github:
                this.product.language_data[this.currentLanguage].social_links.github = event.target.value;
                break;
            case SOCIAL_MEDIA.Dribbble:
                this.product.language_data[this.currentLanguage].social_links.dribbble = event.target.value;
                break;
            case SOCIAL_MEDIA.Behance:
                this.product.language_data[this.currentLanguage].social_links.behance = event.target.value;
                break;
            case SOCIAL_MEDIA.CustomUrl:
                this.product.language_data[this.currentLanguage].social_links.custom_url = event.target.value;
                break;
        }
    }

    handleFloatingButton() {
        this.isPreviewButtonClicked = !this.isPreviewButtonClicked
        this.isPreviewButtonClicked ? this.previewModal.show() : this.previewModal.hide();
    }

    downloadOnScan() {
        this.product.autodownload_v2 = !this.product.autodownload_v2;
    }

    setAddressField(event: any) {
        this.geocoder = new google.maps.Geocoder;
        const latlng = new google.maps.LatLng(event['coords'].lat, event['coords'].lng);
        this.geocoder.geocode({'latLng': latlng}, (results, status) => {
            if (status !== google.maps.GeocoderStatus.OK) {
                this.messageModal.show('Error Fetching Results', 'danger')
            }
            if (status === google.maps.GeocoderStatus.OK) {
                const address = (results[0].formatted_address);
                this.product.address_v2 = address;
                this.onVCardContentChange();
            } else {
                this.messageModal.show('Error fetching Location', 'danger');
            }
        });
    }

    setAddressFieldMultiLang(event: any) {
        this.geocoder = new google.maps.Geocoder;
        const latlng = new google.maps.LatLng(event['coords'].lat, event['coords'].lng);
        this.geocoder.geocode({'latLng': latlng}, (results, status) => {
            if (status !== google.maps.GeocoderStatus.OK) {
                this.messageModal.show('Error Fetching Results', 'danger')
            }
            if (status === google.maps.GeocoderStatus.OK) {
                const address = (results[0].formatted_address);
                this.product.language_data[this.currentLanguage].address_v2 = address;
                this.onVCardContentChange();
            } else {
                this.messageModal.show('Error fetching Location', 'danger');
            }
        });
    }

    selectLayout(layout, isNew) {
        this.showLayoutSelectStep = false;
        const previous_layout = this.product.layout;
        this.product.layout = layout.name;
        if (isNew) {
            this.product.customizations = layout['customizations'];
        }
        if (['3', '5'].includes(previous_layout) && !['3', '5'].includes(this.product.layout)) {
            const white_color = ['#fff', '#FFF', '#ffffff', '#FFFFFF']
            if (white_color.includes(this.product.customizations.user_info_color)) {
                this.product.customizations.user_info_color = '#000000'
            }
        }
        this.vCardFontStyle = this.product.customizations.font_style;
        this.onVCardContentChange();
    }

    updateProfilePic() {
        this.trackProfilePicChangeClick()
        if (!this.hasWriteAccess || (this.priortizeOrgSettings && this.orgCardSetting.user_image_url) || this.cardSetting.user_image_url) {
            return
        }
        this.imageUpdateModal.show();
    }

    trackProfilePicChangeClick() {
        this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Engagement, AMPLITUDE_EVENTS.CARD_PHOTO_EDIT, this.photoUploadEventProperties)
    }

    changeProfilePic() {
        this.closeProfileModal();
        this.updateLogo(IMAGE_TYPE.DBCUserImage);
    }

    closeProfileModal() {
        this.imageUpdateModal.hide();
    }

    ngOnDestroy(): void {
        if (this.contentChangeSubject) {
            this.contentChangeSubject.unsubscribe();
        }
        if (this.isFrameLoadingSubject) {
            this.isFrameLoadingSubject.unsubscribe();
        }
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    setUploadToFolder(folder) {

        this.uploadToFolder = folder
    }

    resetCountryName() {
        this.product.country = ''
        this.onVCardContentChange()
    }

    setTitleFontSize(event) {
        if (event && event.value) {
            this.product.customizations.title_font_size = event.value;
            this.onVCardContentChange();
        }
    }

    setFontFamily(event) {
        if (event && event.value) {
            this.vCardFontStyle = event.value;
            this.product.customizations.font_style = event.value;
            this.onVCardContentChange();
        }
    }

    changeFontList(event) {
        if (event && event.length) {
            this.fontList = Utils.new_available_fonts;
        } else {
            this.fontList = Utils.available_fonts;
        }
    }

    handleOldData() {
        const {font_style, title_font_size} = this.product.customizations;
        this.product.customizations.font_style = font_style || 'Arial';
        this.product.customizations.title_font_size = title_font_size || 30;
        // Convert Data to new data
        this.handlePhoneData();
        this.handleEmailData();
        this.handleWebsiteData();
        this.handleCustomFieldData();

        this.product.phone_v2 =
            this.product.phone_v2.map(phone => {
                return ({
                    ...phone,
                    valid: TEXT_FIELD_VALIDATIONS.VALID // Not having validation for phone number field
                })
            });
        this.product.email_v2 =
            this.product.email_v2.map(email => {
                return ({
                    ...email,
                    valid: email['value'].trim().length === 0 || Utils.emailRegex.test(email['value']) ? TEXT_FIELD_VALIDATIONS.VALID : TEXT_FIELD_VALIDATIONS.ERROR
                }
                )
            });
        this.product.website_v2 =
            this.product.website_v2.map(website => {
                return ({
                    ...website,
                    valid: website['value'].trim().length === 0 || Utils.stringUrlRegex.test(website['value']) ? TEXT_FIELD_VALIDATIONS.VALID : TEXT_FIELD_VALIDATIONS.ERROR
                })
            });

        this.validateData();
    }

    handlePhoneData() {
        if (this.product.phone && !this.product.phone_v2.length) {
            const phone_data = this.product.phone;
            if (phone_data.home && phone_data.home !== '') {
                this.product.phone_v2.push(
                    {
                        value: phone_data.home,
                        label: 'Home'
                    }
                )
                this.product.phone.home = ''
            }
            if (phone_data.mobile && phone_data.mobile !== '') {
                this.product.phone_v2.push(
                    {
                        value: phone_data.mobile,
                        label: 'Mobile'
                    }
                )
                this.product.phone.mobile = ''
            }

            if (phone_data.work && phone_data.work !== '') {
                this.product.phone_v2.push(
                    {
                        value: phone_data.work,
                        label: 'Work'
                    }
                )
                this.product.phone.work = ''
            }
        }
        if (!this.product.phone_v2.length) {
            this.addNewField('phone')
        }
    }

    handleEmailData() {
        if (this.product.email && this.product.email !== '' && !this.product.email_v2.length) {
            this.product.email_v2.push(
                {
                    value: this.product.email,
                    label: ''
                }
            )
            this.product.email = '';
        }

        if (!this.product.email_v2.length) {
            this.addNewField('email');
        }
    }

    handleWebsiteData() {
        if (this.product.website && this.product.website !== '' && !this.product.website_v2.length) {
            this.product.website_v2.push(
                {
                    value: this.product.website,
                    label: ''
                }
            )
            this.product.website = '';
        }
        if (!this.product.website_v2.length) {
            this.addNewField('website')
        }
    }

    handleCustomFieldData() {
        if (!this.product.custom_fields.length) {
            this.addNewField('custom_fields')
        }
    }

    addNewField(contactType) {
        if (contactType !== 'custom_fields') {
            contactType += '_v2';
        }
        this.product[contactType].push(
            {
                value: '',
                label: contactType ===    'website_v2' ? 'Company' : contactType === 'custom_fields' ? '' : 'Work',
                valid: TEXT_FIELD_VALIDATIONS.VALID
            }
        )
        this.updateContactInfoList()
    }

    addNewFieldMultiLang(contactType, language = this.currentLanguage) {
        if (contactType !== 'custom_fields') {
            contactType += '_v2';
        }
        this.product.language_data[language][contactType].push(
            {
                value: '',
                label: contactType === 'website_v2' ? 'Company' : contactType === 'custom_fields' ? '' : 'Work',
                valid: TEXT_FIELD_VALIDATIONS.VALID
            }
        )
        this.updateContactInfoListForMultiLang()
    }

    removeField(contactType, index) {
        if (contactType !== 'custom_fields') {
            contactType += '_v2';
        }
        this.product[contactType].splice(index, 1);
        this.updateContactValuesInCIList();
        this.onVCardContentChange();
    }

    removeFieldMultiLang(contactType, index) {
        if (contactType !== 'custom_fields') {
            contactType += '_v2';
        }
        this.product.language_data[this.currentLanguage][contactType].splice(index, 1);
        this.onVCardContentChange();
    }

    setLabel(contactType, event, index) {
        if (contactType !== 'custom_fields') {
            contactType += '_v2';
        }
        this.product[contactType][index]['label'] = event.value;
        this.updateContactInfoList();
    }

    setLabelMultiLang(contactType, event, index) {
        if (contactType !== 'custom_fields') {
            contactType += '_v2';
        }
        this.product.language_data[this.currentLanguage][contactType][index]['label'] = event.value;
        this.onVCardContentChange();
    }


    inputChange(contactType, event, index) {
        if (contactType !== 'custom_fields') {
            contactType += '_v2';
        }
        const contactTypeRegex = contactType === 'website_v2' ? Utils.stringUrlRegex : Utils.emailRegex;
        if (this.product[contactType][index]['value'] !== '') {
            this.updateContactValuesInCIList();
            this.product[contactType][index]['valid'] = contactTypeRegex.test(event) ? TEXT_FIELD_VALIDATIONS.VALID : TEXT_FIELD_VALIDATIONS.ERROR;
            this.validations[contactType] = contactTypeRegex.test(event)
        } else {
            this.product[contactType][index]['valid'] = TEXT_FIELD_VALIDATIONS.VALID;
            this.validations[contactType] = true;
        }
        if (contactType === 'custom_fields') {
            this.validations[contactType] = true;
        }
        this.validateData();
        this.onVCardContentChange();
    }

    /* CIList: contact_info_list */
    updateContactValuesInCIList() {
        this.product.__contact_info_list__.forEach((contact, index) => {
            switch (contact.key) {
                case CONTACT_DETAIL.PHONE_V2:
                    this.product.__contact_info_list__[index].value = [...this.product.phone_v2];
                    break;
                case CONTACT_DETAIL.EMAIL_V2:
                    this.product.__contact_info_list__[index].value = [...this.product.email_v2];
                    break;
                case CONTACT_DETAIL.WEBSITE_V2:
                    this.product.__contact_info_list__[index].value = [...this.product.website_v2];
                    break;
                case CONTACT_DETAIL.CUSTOM_FIELDS:
                    this.product.__contact_info_list__[index].value = [...this.product.custom_fields];
                    break;
            }
        })
    }

    inputChangeMultiLang(contactType, event, index, language = this.currentLanguage) {
        if (contactType !== 'custom_fields') {
            contactType += '_v2';
        }
        const elementID = contactType + '_ml';
        const contactTypeRegex = contactType === 'website_v2' ? Utils.stringUrlRegex : Utils.emailRegex;
        if (this.product.language_data[language][contactType][index]['value'] !== '') {
            this.product.language_data[language][contactType][index]['valid'] = contactTypeRegex.test(event) ? TEXT_FIELD_VALIDATIONS.VALID : TEXT_FIELD_VALIDATIONS.ERROR;
            this.validationsMultiLang[language][elementID] = contactTypeRegex.test(event)
        } else {
            this.product.language_data[language][contactType][index]['valid'] = TEXT_FIELD_VALIDATIONS.VALID;
            this.validationsMultiLang[language][elementID] = true;
        }
        if (contactType === 'custom_fields') {
            this.validationsMultiLang[language][elementID] = true;
        }
        this.validateDataMultiLang();
        this.onVCardContentChange();
    }

    populateMapWithExistingValue() {
        const address_url = this.product.address_url;
        if (address_url) {
            const tempURL = new URL(address_url);
            const latLong = tempURL.searchParams.get('query').split(',');

            if (latLong) {
                const lat = parseFloat(latLong[0]);
                const lng = parseFloat(latLong[1]);


                this.locationModel.latitude = lat;
                this.locationModel.longitude = lng;

                const coordinates = {lat: this.locationModel.latitude, lng: this.locationModel.longitude};
                this.locationData = coordinates;
                this.center = coordinates;

                this.geocoder = new google.maps.Geocoder;
                const latlng = new google.maps.LatLng(lat, lng);

                this.geocoder.geocode({'latLng': latlng}, (results, status) => {
                    if (status === google.maps.GeocoderStatus.OK) {
                        this.searchControl.setValue(results[0].formatted_address);
                    } else {
                        this.messageModal.show('Error fetching Location', 'danger');
                    }
                });
            }
        }
    }

    populateMapWithExistingValueMultiLang(language) {
        const address_url = this.product.language_data[language]?.address_url;
        if (address_url) {
            const tempURL = new URL(address_url);
            const latLong = tempURL.searchParams.get('query').split(',');

            if (latLong) {
                const lat = parseFloat(latLong[0]);
                const lng = parseFloat(latLong[1]);


                this.locationModelMultiLang[language].latitude = lat;
                this.locationModelMultiLang[language].longitude = lng;


                const coordinates = {lat: this.locationModelMultiLang[language].latitude, lng: this.locationModelMultiLang[language].longitude};
                this.locationDataMultiLang[language] = coordinates;
                this.centerMultiLang[language] = coordinates;

                this.geocoder = new google.maps.Geocoder;
                const latlng = new google.maps.LatLng(lat, lng);

                this.geocoder.geocode({'latLng': latlng}, (results, status) => {
                    if (status === google.maps.GeocoderStatus.OK) {
                        const searchControlObject = this.searchControlMultiLang[language] = new UntypedFormControl();
                        searchControlObject.setValue(results[0].formatted_address);
                    } else {
                        this.messageModal.show('Error fetching Location', 'danger');
                    }
                });
            }
        }
    }

    next() {
        this.onNext.emit();
    }

    validateData() {
        let validationFlag = false;
        this.validations.email_v2 = true;
        this.validations.website_v2 = true;
        this.product.email_v2.forEach(email => {
            if (email['valid'] && email['valid'] === TEXT_FIELD_VALIDATIONS.ERROR) {
                validationFlag = true;
                this.validations.email_v2 = false;
            }
        });
        this.product.website_v2.forEach(website => {
            if (website['valid'] && website['valid'] === TEXT_FIELD_VALIDATIONS.ERROR) {
                validationFlag = true;
                this.validations.website_v2 = false;
            }
        });
        this.disableNextButtonVcard = validationFlag;
    }

    validateDataMultiLang(language = this.currentLanguage) {
        let validationFlag = false;
        this.validationsMultiLang[language].email_v2_ml = true;
        this.validationsMultiLang[language].website_v2_ml = true;
        this.product.language_data[language].email_v2.forEach(email => {
            if (email['valid'] && email['valid'] === TEXT_FIELD_VALIDATIONS.ERROR) {
                validationFlag = true;
                this.validationsMultiLang[language].email_v2_ml = false;
            }
        });
        this.product.language_data[language].website_v2.forEach(website => {
            if (website['valid'] && website['valid'] === TEXT_FIELD_VALIDATIONS.ERROR) {
                validationFlag = true;
                this.validationsMultiLang[language].website_v2_ml = false;
            }
        });
        this.disableNextButtonVcard = validationFlag;
    }

    handleAddress() {
        if (!this.product.address_v2) {
            const {address_line1, address_line2, zip, country, city} = this.product;
            let tempAddress = '';
            if (address_line1) {
                tempAddress += address_line1 + ', ';
            }
            if (address_line2) {
                tempAddress += address_line2 + ', ';
            }
            if (city) {
                tempAddress += city + ', ';
            }
            if (zip) {
                tempAddress += zip + ', ';
            }
            if (country) {
                tempAddress += country;
            }
            tempAddress = tempAddress.trim();
            if (tempAddress.substring(tempAddress.length - 1) === ',') {
                tempAddress = tempAddress.substring(0, tempAddress.length - 1);
            }
            this.product.address_v2 = tempAddress;
            this.emptyOldAdressFields();
        }
    }

    emptyOldAdressFields() {
        this.product.address_line1 = '';
        this.product.address_line2 = '';
        this.product.zip = '';
        this.product.country = '';
        this.product.city = '';
    }

    removeLocation() {
        this.product.address_url = ''
        document.getElementById('vcard-address')['value'] = ''
        this.onVCardContentChange()
    }

    removeLocationMultiLang() {
        this.product.language_data[this.currentLanguage].address_url = ''
        document.getElementById('vcard-address-multi-lang')['value'] = ''
        this.onVCardContentChange()
    }

    firstNameValidation(event) {
        if (!event || !event.trim() || event.length > 255) {
            this.validations.first_name = false;
        } else {
            this.validations.first_name = true;
        }
        this.onVCardContentChange();
    }

    firstNameValidationMultiLang(event, language = this.currentLanguage) {
        if (!event || !event.trim() || event.length > 255) {
            this.validationsMultiLang[language].first_name_ml = false;
        } else {
            this.validationsMultiLang[language].first_name_ml = true;
        }
        this.onVCardContentChange();
    }

    appendCustomLabelsToExistingLabels() {

        for (const label of this.orgCardSetting.custom_labels) {
            this.custom_labels.push({ name : label , value : label })
        }

        this.labelsForPhone = [...this.labelsForPhone, ...this.custom_labels]
        this.labelsForEmail = [...this.labelsForEmail, ...this.custom_labels]
        this.labelsForWebsite = [...this.labelsForWebsite, ...this.custom_labels]

    }

    onMultiLanguageContentSwitchChange() {
        if (this.user.organization.dbc_feature_permissions.multi_language) {
            this.isMultiLanguageContent = !this.isMultiLanguageContent;
            if (this.isMultiLanguageContent) {
                this.makeDefaultLanguage(this.product.default_language ? this.product.default_language : this.product.INITIAL_DEFAULT_LANGUAGE);
                if (!this.product.language_data) {
                    this.product.language_data[this.product.INITIAL_DEFAULT_LANGUAGE] = new MultiLanguageContent();
                }

                this.currentLanguage = this.product.default_language;

                // initializing auto complete of app-map component
                this.locationDataMultiLang[this.product.default_language] = deepCopy(this.locationData);
                this.centerMultiLang[this.product.default_language] = deepCopy(this.center);
                this.initializeAutoCompleteMultiLang();

                // populate data(both language specific and non language specific) from original DBC model to language_data model
                this.product.language_data[this.product.default_language] = new MultiLanguageContent();
                this.transferLanguageSpecificFields(this.product, this.product.language_data[this.product.default_language]);
                this.setValidationObjectForMultiLang(this.product.default_language);

                this.socialFieldDetailsMultiLang[this.product.default_language] = deepCopy(this.socialFieldDetails);
                this.MultiLangLinks[this.product.default_language] = {};
                for (const key in this.socialLinkStates) {
                    if (this.socialLinkStates.hasOwnProperty(key)) {
                        this.MultiLangLinks[this.product.default_language][key] = deepCopy(this[key]);
                    }
                }

                this.initializeContactFields();
                this.populateMapWithExistingValueMultiLang(this.product.default_language);
            } else {
                this.transferLanguageSpecificFields(this.product.language_data[this.product.default_language], this.product);

                this.initializeAutoCompleteMultiLang();
                this.locationData = deepCopy(this.locationDataMultiLang[this.product.default_language]);
                this.center = deepCopy(this.centerMultiLang[this.product.default_language]);

                this.socialFieldDetails = deepCopy(this.socialFieldDetailsMultiLang[this.product.default_language]);
                for (const key in this.socialLinkStates) {
                    if (this.socialLinkStates.hasOwnProperty(key)) {
                        this[key] = deepCopy(this.MultiLangLinks[this.product.default_language][key]);
                    }
                }
                this.initializeAutoComplete();
            }
            this.isMultiLanguageContentChange.emit(this.isMultiLanguageContent);
            this.currentLanguageChange.emit(this.currentLanguage);
            this.productChange.emit(this.product);
        } else {
            this.switchMultiLanguage['checked'] = false;
            if (this.authService.getUser().isOnOldDbcPlan) {
                this.upsellService.show('This is a TEAM plan feature!',
                    'To access multi-language support for your cards, you need to upgrade your plan.', null, 'Upgrade Plan', 'multilanguage-upsell')
            } else {
                this.upsellService.show('Create multi-lingual cards',
                    'Upgrade to the Team plan to enable multi-language support for your digital business card.', null, 'Upgrade to Team', 'multilanguage-upsell')
            }
        }
    }

    initializeContactFields(language = this.currentLanguage) {
        if (!this.product.language_data[language].phone_v2.length) {
            this.addNewFieldMultiLang('phone', language)
        }
        if (!this.product.language_data[language].email_v2.length) {
            this.addNewFieldMultiLang('email', language);
        }
        if (!this.product.language_data[language].website_v2.length) {
            this.addNewFieldMultiLang('website', language);
        }
        if (!this.product.language_data[language].custom_fields.length) {
            this.addNewFieldMultiLang('custom_fields', language);
        }
    }

    transferLanguageSpecificFields(from: DigitalBusinessCard | MultiLanguageContent, to: DigitalBusinessCard | MultiLanguageContent, fields: Array<string> = DBC_LANGUAGE_SPECIFIC_FIELDS) {
        for (const field of fields) {
            to[field] = deepCopy(from[field]);
        }
    }

    onMultiLanguageContentLanguageAdded(languageData) {
        if (this.multiLangList.includes(languageData.code)) {
            this.messageModal.show('Language already selected', 'warning');
            return;
        }

        this.currentLanguage = languageData.code;
        this.product.language_data[this.currentLanguage] = new MultiLanguageContent(); // see line 2847 why we need 2 places initialize

        // initializing auto complete of app-map component
        this.locationModelMultiLang[this.currentLanguage] = {
            latitude: 0,
            longitude: 0,
            placeId: '',
            url: ''
        };
        this.locationDataMultiLang[this.currentLanguage] = {lat: 0, lng: 0};
        this.searchControlMultiLang[this.currentLanguage] = new UntypedFormControl();
        this.centerMultiLang[this.currentLanguage] = {lat: 0, lng: 0};
        this.initializeAutoCompleteMultiLang();

        this.product.language_data[this.currentLanguage] = new MultiLanguageContent();
        this.transferLanguageSpecificFields(this.product.language_data[this.product.default_language], this.product.language_data[this.currentLanguage], DBC_CARRY_FORWARD_FIELDS);
        this.product.language_data[this.currentLanguage].fillRestrictedFieldsData(this.cardSetting, this.selectedTemplate)

        this.socialFieldDetailsMultiLang[this.currentLanguage] = deepCopy(this.socialFieldDetailsMultiLang[this.product.default_language]);
        this.MultiLangLinks[this.currentLanguage] = {};
        for (const key in this.socialLinkStates) {
            if (this.socialLinkStates.hasOwnProperty(key)) {
                this.MultiLangLinks[this.currentLanguage][key] = deepCopy(this.MultiLangLinks[this.product.default_language][key]);
            }
        }

        this.initializeContactFields();

        this.setValidationObjectForMultiLang(this.currentLanguage);
        this.validationsMultiLang[this.currentLanguage].first_name_ml = true;

        this.multiLangList.push(languageData.code);
        this.isMultiLanguageContentChange.emit(this.isMultiLanguageContent);
        this.currentLanguageChange.emit(this.currentLanguage);
        this.multiLangListChange.emit(this.multiLangList);
        this.productChange.emit(this.product);
    }

    selectLanguage(language) {
        this.initializeAutoCompleteMultiLang(language);
        this.currentLanguage = language;
        this.isMultiLanguageContentChange.emit(this.isMultiLanguageContent);
        this.currentLanguageChange.emit(this.currentLanguage);
        this.multiLangListChange.emit(this.multiLangList);
        this.productChange.emit(this.product);

        this.resetScialIconsSearch();
    }

    makeDefaultLanguage(language) {
        this.product.default_language = language;
        this.productChange.emit(this.product);
    }

    onMultiLanguageContentLanguageRemoved(language) {
        if (Utils.ObjectKeys(this.product.language_data).length > 1) {
            const multi_language_content = this.product.language_data;
            delete multi_language_content[language];
            delete this.validationsMultiLang[language];

            if (this.product.default_language === language) {
                this.product.default_language = Utils.ObjectKeys(this.product.language_data)[0];
            }
            if (this.currentLanguage === language) {
                this.currentLanguage = this.product.default_language
            }

            this.currentLanguageChange.emit(this.currentLanguage);
            this.multiLangList = this.multiLangList.filter(lang => lang !== language);
            this.multiLangListChange.emit(this.multiLangList);
            this.productChange.emit(this.product);
        }
    }

    reverseMapObject(obj: object): {[key: string]: string} {
        const reversedObject: { [key: string]: string } = {};
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                reversedObject[obj[key]] = key;
            }
        }
        return reversedObject;
    }

    setValidationObjectForMultiLang(language: string = this.currentLanguage) {
        const validationObject = deepCopy({first_name_ml: false,
            email_v2_ml: false,
            website_v2_ml: false});
        this.validationsMultiLang[language] = deepCopy(validationObject);
        this.firstNameValidationMultiLang(this.product.language_data[language].first_name, language);

        for (const contactType of ['email_v2', 'website_v2']) {
            const key = contactType + '_ml';
            const contactTypeRegex = key === 'website_v2_ml' ? Utils.stringUrlRegex : Utils.emailRegex;
            if (this.product.language_data[language][contactType] !== '') {
                this.validationsMultiLang[language][key] = contactTypeRegex.test(this.product.language_data[language][contactType]);
            } else {
                this.validationsMultiLang[language][key] = true;
            }
            this.validateDataMultiLang(language);
            this.onVCardContentChange();
        }
    }

    handleIconClick(property: CARD_PROPERTY_TYPES) {
        if (this.cardType === DBCCardTypes.TEMPLATE_CARDS) {
            switch (property) {
                case CARD_PROPERTY_TYPES.AUTODOWNLOAD_V2:
                case CARD_PROPERTY_TYPES.LEAD_COLLECTION_V2:
                case CARD_PROPERTY_TYPES.LOCATION_ENABLED_V2:
                case CARD_PROPERTY_TYPES.EMAIL_WALLET_PASS:
                case CARD_PROPERTY_TYPES.IP_LOCATION_ENABLED:
                    this.cardSetting[property] = this.cardSetting[property] === DBC_ORG_PERMISSION_OPTIONS.YES ? DBC_ORG_PERMISSION_OPTIONS.NO : DBC_ORG_PERMISSION_OPTIONS.YES;
                    break;
                default:
                    this.cardSetting[property] = !this.cardSetting[property]
                    break;
            }
            this.cardSettingChange.emit(this.cardSetting);
        }
        this.onVCardContentChange();
    }

    showLayoutSelectScreen() {
        this.cardUserInputChange.emit({
            type: CARD_USER_INPUT_OPERATIONS.LAYOUT_SELECT
        });
    }

    onTemplateSelect(event) {
        this.selectedTemplate = event.template;
        this.cardUserInputChange.emit({
            type: CARD_USER_INPUT_OPERATIONS.SELECT_TEMPLATE,
            value: event.value,
            template: event.template
        })
    }

    onIconSearch(event) {
        this.iconSearchInputSubject.next(event.target.value);
    }

    filterIconListOnSearch() {
        if (!this.searchedIconInput) {
            this.socialMediaMetaMobileList = socialMediaMetaData;
            return;
        }
        this.socialMediaMetaMobileList = socialMediaMetaData.filter((socialMedia) => {
            return socialMedia.title.toLowerCase().includes(this.searchedIconInput.toLowerCase());
        });
        if (!this.isMultiLanguageContent) {
            if (this.socialMediaMetaMobileList.length === 0 && !this.socialFieldDetails.find(icon => icon.pos === 24)) {
                this.showCustomIcon = true;
            } else {
                this.showCustomIcon = false;
            }
        } else {
            if (this.socialMediaMetaMobileList.length === 0 && !this.socialFieldDetailsMultiLang[this.currentLanguage].find(icon => icon.pos === 24)) {
                this.showCustomIcon = true;
            } else {
                this.showCustomIcon = false;
            }
        }
    }

    resetScialIconsSearch() {
        this.iconSearchInputSubject.next('');
    }
    checkForMobileView() {
        if (this.isMobileScreen) {
            const checkSectionKey = 'personalInfo';
            Object.keys(this.collapseSection).forEach(key => {
                this.collapseSection[key] = true;
            });
            Object.keys(this.collapseMultilanguage).forEach(key => {
                this.collapseMultilanguage[key] = true;
            });
            this.collapseMultilanguage[checkSectionKey] = false;
            this.collapseSection[checkSectionKey] = false;
        } else {
            Object.keys(this.collapseSection).forEach(key => {
                this.collapseSection[key] = false;
            });
            Object.keys(this.collapseMultilanguage).forEach(key => {
                this.collapseMultilanguage[key] = false;
            });
        }
    }
    updateCollapse(section) {
        if (this.isMobileScreen) {
            if (this.collapseSection[section]) {
                Object.keys(this.collapseSection).forEach(key => {
                    if (this.collapseSection[key] !== this.collapseSection[section]) {
                        this.collapseSection[key] = true;
                    }
                });
            }
            this.collapseSection[section] = !this.collapseSection[section];
        } else {
            this.collapseSection[section] = !this.collapseSection[section];
        }
    }
    updateCollapseMultilanguage(section) {
        if (this.isMobileScreen) {
            if (this.collapseMultilanguage[section]) {
                Object.keys(this.collapseMultilanguage).forEach(key => {
                    if (this.collapseMultilanguage[key] !== this.collapseMultilanguage[section]) {
                        this.collapseMultilanguage[key] = true;
                    }
                });
            }
            this.collapseMultilanguage[section] = !this.collapseMultilanguage[section];
        } else {
            this.collapseMultilanguage[section] = !this.collapseMultilanguage[section];
        }
    }

    updateContactDetailsOrdering($event: CdkDragDrop<string[]>) {
        moveItemInArray(this.product.__contact_info_list__, $event.previousIndex, $event.currentIndex)
        this.product.contact_info_ordering = this.updateContactInfoOrdering({...this.product});
        this.onVCardContentChange()
    }

    updateContactInfoList() {
        this.product.__contact_info_list__ = buildContactInfoList({...this.product});
        this.product.contact_info_ordering = this.updateContactInfoOrdering({...this.product});
        this.onVCardContentChange();
    }

    updateContactInfoListForMultiLang() {
        this.product.language_data[this.currentLanguage].__contact_info_list__ = buildContactInfoList({...this.product.language_data[this.currentLanguage]})
        this.product.language_data[this.currentLanguage].contact_info_ordering = this.updateContactInfoOrdering({...this.product.language_data[this.currentLanguage]})
        this.onVCardContentChange();
    }

    updateContactDetailsOrderingForMultiLang($event: CdkDragDrop<string[]>) {
        moveItemInArray(this.product.language_data[this.currentLanguage].__contact_info_list__, $event.previousIndex, $event.currentIndex)
        this.product.language_data[this.currentLanguage].contact_info_ordering = this.updateContactInfoOrdering({...this.product.language_data[this.currentLanguage]})

        /* dbc object taking default language ordering */
        if (this.currentLanguage === this.product.default_language) {
            this.product.contact_info_ordering = this.product.language_data[this.currentLanguage].contact_info_ordering;
        }

        this.onVCardContentChange()
    }

    updateContactInfoOrdering({contact_info_ordering, __contact_info_list__}) {

        __contact_info_list__.forEach((contact, index) => {
            const {key} = contact;
            contact_info_ordering[index] = key;
        })

        return contact_info_ordering;
    }

    logEventOnPageView() {
        this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Engagement, AMPLITUDE_EVENTS.PAGE_VIEWED, {
            page_name : 'add_card_build_step_page'
        })
    }

    logEventOnSavingProfilePicture() {
        this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Engagement, AMPLITUDE_EVENTS.CARD_PHOTO_SAVED, this.photoUploadEventProperties)
    }

    setLogoSize(value: number): void {
        if (this.isMultiLanguageContent) {
            this.product.language_data[this.currentLanguage].logo_size = value;
        } else {
            this.product.logo_size = value;
        }
        this.onVCardContentChange()
    }

    showProcessingDialog(){
        this.isProcessingImage = true;
    }

    hideProcessingDialog(){
        this.isProcessingImage = false;
    }
}
