import {TEMPLATE_NAMES} from '../../digital-business-card/templates-utils';
/**
 * @interface {NavBarItemPermissions}
 * This interface defines the permissions for the side navigation bar items
 * All of them should be boolean values
 * Based on the user's role and plan, the permissions will be set
 * **/

export interface NavBarItemPermissions {
    overview: boolean;
    myCards: boolean;
    orgCards: boolean;
    analytics: boolean;
    contactManager: boolean;
    assetLib: boolean;
    template: boolean;
    settings: boolean;
    integrations: boolean;
    api: boolean;
    logs: boolean;
    help: boolean
}

/**
 * This is the default permissions for the navigation bar items
 * By default we are allowing everything to be visible
 * **/
export const DEFAULT_NAV_PERMISSIONS: NavBarItemPermissions = {
    overview: true,
    myCards: true,
    orgCards: true,
    analytics: true,
    contactManager: true,
    assetLib: true,
    template: true,
    settings: true,
    integrations: true,
    api: true,
    logs: true,
    help: true
}

/**
 * @enum {MenuOptions}
 * This enum defines the menu options available in the avatar menu
 * **/
export enum MenuOptions {
    ACCOUNT_SETTINGS = 1,
    ORGANIZATIONS = 2,
    USER_MANAGEMENT = 3,
    BILLING = 4,
    CUSTOM_DOMAINS = 5,
    REFER_A_FRIEND = 6,
    LOGOUT = 7,
    VIEW_PLANS = 8,
    NOTIFICATIONS = 9,
}

/**
 * @interface {AvatarItemPermissions}
 * This interface defines the permissions for the avatar menu items
 * All of them should be boolean values
 * Based on the user's role and plan, the permissions will be set
 * **/
export interface AvatarItemPermissions {
    [MenuOptions.ACCOUNT_SETTINGS]: boolean;
    [MenuOptions.ORGANIZATIONS]: boolean;
    [MenuOptions.USER_MANAGEMENT]: boolean;
    [MenuOptions.BILLING]: boolean;
    [MenuOptions.CUSTOM_DOMAINS]: boolean;
    [MenuOptions.VIEW_PLANS]: boolean;
    [MenuOptions.REFER_A_FRIEND]: boolean;
    [MenuOptions.LOGOUT]: boolean;
}

/**
 * This is the default permissions for the avatar menu items
 * By default we are allowing everything to be visible except 'refer a friend'
 */
export const DEFAULT_AVATAR_PERMISSIONS: AvatarItemPermissions = {
    [MenuOptions.ACCOUNT_SETTINGS]: true,
    [MenuOptions.ORGANIZATIONS]: true,
    [MenuOptions.USER_MANAGEMENT]: true,
    [MenuOptions.BILLING]: true,
    [MenuOptions.CUSTOM_DOMAINS]: true,
    [MenuOptions.VIEW_PLANS]: true,
    [MenuOptions.REFER_A_FRIEND]: false,
    [MenuOptions.LOGOUT]: true
}

export interface LayoutPermissions {
    [key: string]: boolean;
}
export const DEFAULT_LAYOUT_PERMISSIONS: LayoutPermissions = {
    [TEMPLATE_NAMES.Brisk_Motors]: true,
    [TEMPLATE_NAMES.Murdoch_Associates]: true,
    [TEMPLATE_NAMES.McGee_Security_Corp]: true,
    [TEMPLATE_NAMES.Schoen_Co]: true,
    [TEMPLATE_NAMES.Eurus_Sports]: true,
    [TEMPLATE_NAMES.AIB_Global]: true,
    [TEMPLATE_NAMES.Salford_Health]: true,
    [TEMPLATE_NAMES.Onama_Tech]: true,
    [TEMPLATE_NAMES.Harzone]: true,
    [TEMPLATE_NAMES.Cinco]: true,
    [TEMPLATE_NAMES.Anakin]: true
}

/**
 * This interface defines the permissions for the analytics, leads
 * **/
export interface AnalyticsPermissions {
    advanced_analytics: boolean
    advanced_leads: boolean
}

export const DEFAULT_ANALYTICS_PERMISSIONS: AnalyticsPermissions = {
    advanced_analytics: true,
    advanced_leads: true
}

/**
 * This interface defines the permissions for showing the upsell touchpoints in DBC plan first_flow journeys
 * **/
export interface DbcFormUpsellTouchpointsPermission {
    multi_language: boolean;
    advanced_customization: boolean;
    custom_slug: boolean;
    qr_template: boolean;
    layout_design: boolean;
    card_template: boolean;
    custom_fonts: boolean;
}

export const DEFAULT_UPSELL_TOUCHPOINT_PERMISSIONS: DbcFormUpsellTouchpointsPermission = {
    multi_language: true,
    advanced_customization: true,
    custom_slug: true,
    qr_template: true,
    layout_design: true,
    card_template: true,
    custom_fonts: true
}

/**
 * This interface defines the permissions for showing the features visibility in DBC product journeys
 * **/
export interface FeatureVisibilityPermisions {
    card_template: boolean;
    user_agreement: boolean;
    label_management: boolean;
    qr_template: boolean;
}

export const DEFAULT_FEATURE_VISIBILITY_PERMISSIONS: FeatureVisibilityPermisions = {
    card_template: true,
    user_agreement: true,
    label_management: true,
    qr_template: true,
}

/** Role base access control strategy interface **/
interface RBACStrategy {
    /**
     * @returns NavBarItemPermissions
     * @description returns the navigation menu item permission for DBC product
     * **/
    getNavbarOptions(): NavBarItemPermissions;

    /**
     * @returns AvatarItemPermissions
     * @description returns the avatar menu item permission options for DBC product
     * **/
    getAvatarOptions(): AvatarItemPermissions;

    /**
     * @returns AnalyticsPermissions
     * @description returns the analytics permission options for DBC product
     * **/
    getAnalyticsPermissions(): AnalyticsPermissions;

    /**
     * @returns DbcFormUpsellTouchpointsPermission
     * @description returns the upsell touchpoint permission options for DBC product
     * **/
    getFirstFlowUpsellTouchpointsPermission(): DbcFormUpsellTouchpointsPermission;

    /**
     * @returns LayoutPermissions
     * @description returns the layouts permission options for DBC product
     * **/
    getLayoutsPermissions(): LayoutPermissions;

    /**
     * @returns FeatureVisibilityPermisions
     * @description returns the features visibility options for DBC product
     * **/
    getFeaturesVisibilityPermissions(): FeatureVisibilityPermisions;
}

// Freemium plan strategy implementation
export class DBCFreemiumStrategy implements RBACStrategy {
    getNavbarOptions(): NavBarItemPermissions {
        return {
            overview: true,
            myCards: true,
            orgCards: false,
            analytics: true,
            contactManager: true,
            assetLib: false,
            template: false,
            settings: false,
            integrations: true,
            api: false,
            logs: true,
            help: true
        };
    }
    getAvatarOptions(): AvatarItemPermissions {
        return {
            [MenuOptions.ACCOUNT_SETTINGS]: true,
            [MenuOptions.ORGANIZATIONS]: false,
            [MenuOptions.USER_MANAGEMENT]: true,
            [MenuOptions.CUSTOM_DOMAINS]: false,
            [MenuOptions.VIEW_PLANS]: true,
            [MenuOptions.BILLING]: true,
            [MenuOptions.LOGOUT]: true,
            [MenuOptions.REFER_A_FRIEND]: false
        };
    }

    getAnalyticsPermissions(): AnalyticsPermissions {
        return {
            advanced_analytics: false,
            advanced_leads: false
        };
    }

    getLayoutsPermissions(): LayoutPermissions {
        return {
            [TEMPLATE_NAMES.Brisk_Motors]: true,
            [TEMPLATE_NAMES.Murdoch_Associates]: true,
            [TEMPLATE_NAMES.McGee_Security_Corp]: true,
            [TEMPLATE_NAMES.Schoen_Co]: true,
            [TEMPLATE_NAMES.Eurus_Sports]: true,
            [TEMPLATE_NAMES.AIB_Global]: true,
            [TEMPLATE_NAMES.Salford_Health]: false,
            [TEMPLATE_NAMES.Onama_Tech]: false,
            [TEMPLATE_NAMES.Harzone]: false,
            [TEMPLATE_NAMES.Cinco]: false,
            [TEMPLATE_NAMES.Anakin]: false
        };
    }

    getFirstFlowUpsellTouchpointsPermission(): DbcFormUpsellTouchpointsPermission {
        return {
            multi_language: false,
            advanced_customization: false,
            custom_slug: false,
            qr_template: false,
            layout_design: false,
            card_template: false,
            custom_fonts: false
        };
    }

    getFeaturesVisibilityPermissions(): FeatureVisibilityPermisions {
        return {
            card_template: false,
            user_agreement: false,
            label_management: false,
            qr_template: false,
        };
    }
}
