import { Injectable } from '@angular/core';
import { Observable, from} from 'rxjs';
import { catchError, map} from 'rxjs/operators';
import heic2any from 'heic2any';

@Injectable({
    providedIn: 'root'
})
export class FileConversionService {
    constructor() {}

    heicToJpeg(file: File): Observable<File | null > {
        if (!file) {
            throw new Error('Invalid file');
        }

        let conversionPromise: Promise<Blob>;
        if (file.type === 'image/heic') {
            conversionPromise = heic2any({ blob: file, toType: 'image/jpeg', quality: 0.9 }) as Promise<Blob>;
        } else {
            throw new Error('Unsupported conversion');
        }

        return from(conversionPromise).pipe(
            map(blob => new File([blob], file.name.replace(/\.[^.]+$/, '.jpeg'), { type: 'image/jpeg' })),
            catchError(error => {
                console.error('File conversion failed:', error);
                throw new Error('Unsupported conversion');
            })
        );
    }
}
