import {DBConfig} from 'ngx-indexed-db';

export const indexedDBConfig: DBConfig = {
    name: 'onboarding-dbc',
    version: 1,
    objectStoresMeta: [
        {
            store: 'CARD',
            storeConfig: { keyPath: 'key', autoIncrement: false },
            storeSchema: [
                { name: 'key', keypath: 'key', options: { unique: true } },
                { name: 'value', keypath: 'value', options: { unique: false } }
            ]
        }
    ]
};
