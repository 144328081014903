import {Component, OnDestroy, OnInit} from '@angular/core';
import {HOMEPAGE_ROUTE, PRODUCT_TYPES} from 'app/shared/utils';
import {SsoAuthService} from 'app/global-services/sso-auth.service';
import {datadogRum} from '@datadog/browser-rum';
import {environment} from '../../../environments/environment';
import * as LDClient from 'launchdarkly-js-client-sdk';
import {Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {DBC_ONBOARDING_URLS} from '../../dbc-onboarding/dbc-onboard.utils';
import { AMPLITUDE_EVENT_CATEGORIES, AMPLITUDE_EVENTS, AmplitudeService } from 'app/global-services/amplitude.service';

enum qrCodesSvgPaths {
    DEFAULT_STATE = '../../../assets/img/signup-flow/qr-codes-light.svg',
    HOVER_STATE = '../../../assets/img/signup-flow/qr-codes-on-hover.svg',
    CLICKED_STATE = '../../../assets/img/signup-flow/qr-codes.svg'
}

enum cardsSvgPaths {
    DEFAULT_STATE = '../../../assets/img/signup-flow/cards-light.svg',
    HOVER_STATE = '../../../assets/img/signup-flow/cards-on-hover.svg',
    CLICKED_STATE = '../../../assets/img/signup-flow/cards.svg'
}

enum queryParams {
    DIRECT_PURCHASE = 'direct-purchase'
}
@Component({
    selector: 'app-signup-page',
    templateUrl: './signup-page.component.html',
    styleUrls: ['./signup-page.component.scss']
})

export class SignupPageComponent implements OnInit, OnDestroy {

    backgroundImage: string = '../../../assets/img/signup-background.jpg'
    // default product is QR
    productType: PRODUCT_TYPES = PRODUCT_TYPES.QR;
    isProductTypeQr: boolean = true;
    qrCodesSvgPath: string = qrCodesSvgPaths.CLICKED_STATE;
    cardsSvgPath: string = cardsSvgPaths.DEFAULT_STATE;
    showProductSelector: boolean = true;
    nextUrl: string = '';
    product: string = '';
    userEmail: string = '';
    templateSource: string = '';
    layout: string = '';
    hasDbcData: boolean = false;
    skipProductSelection: boolean = false;
    LDClient = null;
    isLoading: boolean = true;

    // Subjects
    ngUnsubscribe: Subject<any> = new Subject();

    constructor(private readonly ssoAuthService: SsoAuthService,
        private readonly router: Router,
        private readonly amplitudeService: AmplitudeService
    ) {
        this.initializeAnonymousAmplitude();
        this.redirectToHomeIfAuthenticated();
        this.initializeLaunchDarkly();
        this.fetchUrl();
    }

    initializeAnonymousAmplitude() {
        const timestamp = new Date().getTime();
        const randomComponent = Math.floor(Math.random() * 100);
        const anonymousId = localStorage.getItem('anonymous_amplitude_id') || `${timestamp}${randomComponent}`;
        localStorage.setItem('anonymous_amplitude_id', anonymousId);
        this.amplitudeService.initializeAnonymousTracking(anonymousId);
    }

    redirectToHomeIfAuthenticated() {
        const timeout = setTimeout(() => {
            this.isLoading = false;
        }, 5000); // 5 seconds fallback

        this.ssoAuthService.isAuthenticatedUser$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
            clearTimeout(timeout); // Clear the timeout if response is received
            if (res) {
                // If already authenticated, redirect to overview page
                this.router.navigate([HOMEPAGE_ROUTE]);
            } else {
                this.isLoading = false;
            }
        }, error => {
            clearTimeout(timeout); // Clear the timeout in case of an error
            this.isLoading = false;
        })
    }

    ngOnInit(): void {
        this.initializeMonitoring();
    }

    initializeLaunchDarkly() {
        this.LDClient = LDClient.initialize(environment.launchDarklyClientID, {
            kind: 'user',
            anonymous: true,
        });
    }

    initializeMonitoring() {
        datadogRum.init({
            applicationId: environment.datadog.applicationId,
            clientToken: environment.datadog.clientToken,
            site: environment.datadog.site,
            service: environment.datadog.service,
            env: environment.envName,
            sessionSampleRate: environment.datadog.sessionSampleRate,
            sessionReplaySampleRate: environment.datadog.sessionReplaySampleRate,
            trackResources: environment.datadog.trackResources,
            trackUserInteractions: environment.datadog.trackUserInteractions,
            defaultPrivacyLevel: environment.datadog.defaultPrivacyLevel
        });

        datadogRum.startSessionReplayRecording();
    }

    logAmplitudeEvent(product: PRODUCT_TYPES){
        const selectedProduct = product === PRODUCT_TYPES.QR ? 'qr_codes' : 'cards';
        this.amplitudeService.logEvent(
            AMPLITUDE_EVENT_CATEGORIES.Onboarding, AMPLITUDE_EVENTS.CHOOSE_PRODUCT,
            {
                product_selected: selectedProduct
            }
        );
    }

    handleNextCta() {
        this.logAmplitudeEvent(this.productType);
        let redirectPath = this.decodeBase64(this.nextUrl);
        const plan = this.getPlan(this.productType);
        let productSwitcherPath = '';
        switch (this.productType) {
            case PRODUCT_TYPES.DBC:
                productSwitcherPath = DBC_ONBOARDING_URLS.ONBOARD_V2;
                this.routeToDBCOnboarding(productSwitcherPath);
                return;
            case PRODUCT_TYPES.QR:
                const orgID = this.getOrgId(redirectPath.split('?')[1]);
                productSwitcherPath = '/qr-codes/add?trial_first=true&qr_code_data=eyJxcl9jYW1wYWlnbl90eXBlIjoid2Vic2l0ZSIsImRhdGEiOnsidXJsIjoiIn0sImRlc2lnbiI6eyJkYXRhUGF0dGVybiI6InNxdWFyZSIsImZyYW1lU3R5bGUiOiJub25lIiwiYmFja2dyb3VuZENvbG9yIjoiI2ZmZmZmZiIsImV5ZUJhbGxTaGFwZSI6InNxdWFyZSIsImV5ZUZyYW1lU2hhcGUiOiJzcXVhcmUiLCJleWVGcmFtZUNvbG9yIjoiIzAwMDAwMCIsImV5ZUJhbGxDb2xvciI6IiMwMDAwMDAiLCJncmFkaWVudFR5cGUiOiJub25lIiwiY29sb3JMaWdodCI6IiNmZmZmZmYiLCJjb2xvckRhcmsiOiIjMDAwMDAwIiwibWFyZ2luIjowLCJmcmFtZVRleHQiOiIiLCJsb2dvQmFja2dyb3VuZCI6ZmFsc2V9fQ%3D%3D#campaign';
                if (orgID) {
                    productSwitcherPath = `/qr-codes/add?trial_first=true&qr_code_data=eyJxcl9jYW1wYWlnbl90eXBlIjoid2Vic2l0ZSIsImRhdGEiOnsidXJsIjoiIn0sImRlc2lnbiI6eyJkYXRhUGF0dGVybiI6InNxdWFyZSIsImZyYW1lU3R5bGUiOiJub25lIiwiYmFja2dyb3VuZENvbG9yIjoiI2ZmZmZmZiIsImV5ZUJhbGxTaGFwZSI6InNxdWFyZSIsImV5ZUZyYW1lU2hhcGUiOiJzcXVhcmUiLCJleWVGcmFtZUNvbG9yIjoiIzAwMDAwMCIsImV5ZUJhbGxDb2xvciI6IiMwMDAwMDAiLCJncmFkaWVudFR5cGUiOiJub25lIiwiY29sb3JMaWdodCI6IiNmZmZmZmYiLCJjb2xvckRhcmsiOiIjMDAwMDAwIiwibWFyZ2luIjowLCJmcmFtZVRleHQiOiIiLCJsb2dvQmFja2dyb3VuZCI6ZmFsc2V9fQ%3D%3D&orgID=${orgID}#campaign`;
                }
                break;
        }

        if (this.product !== this.productType) {
            redirectPath = productSwitcherPath;
        }
        this.ssoAuthService.login(redirectPath, {signup: true, plan});
    }

    fetchUrl() {
        const searchParams = new URLSearchParams(window.location.search);
        this.nextUrl = searchParams.get('next');
        this.product = searchParams.get('product');
        this.userEmail = searchParams.get('email');
        this.templateSource = searchParams.get('template_source');
        this.layout = searchParams.get('layout');
        let plan = this.getPlan(this.product);
        const redirectPath = this.nextUrl ? this.decodeBase64(this.nextUrl) : '/';
        this.hasDbcData = this.hasDBCData(redirectPath.split('?')[1]);
        this.skipProductSelection = searchParams.get('skip_product_selection') === 'true';

        const autoSelect = searchParams.get('autoselect');

        if (this.product === PRODUCT_TYPES.DBC) {
            this.isProductTypeQr = false;
            this.productType = PRODUCT_TYPES.DBC;
            this.cardsSvgPath = cardsSvgPaths.CLICKED_STATE;
            this.qrCodesSvgPath = qrCodesSvgPaths.DEFAULT_STATE;
        }
        if (!plan) {
            plan = this.getPlan(PRODUCT_TYPES.QR);
        }

        const options: any = {
            signup: true,
            plan: plan
        };
        if (autoSelect !== null && autoSelect === 'true') {
            options.source = queryParams.DIRECT_PURCHASE;
            this.hideProductSelectorAndLogin(redirectPath, options);
        } else if (this.product !== PRODUCT_TYPES.DBC && (this.userEmail || (this.product === PRODUCT_TYPES.QR && this.skipProductSelection))) {
            options.email = this.userEmail;
            this.hideProductSelectorAndLogin(redirectPath, options);
        } else if (this.product === PRODUCT_TYPES.LINKPAGE) {
            this.hideProductSelectorAndLogin(redirectPath, options);
        } else if (this.product === PRODUCT_TYPES.DBC) {
            this.routeToDBCOnboarding(redirectPath)
        }
    }

    routeToDBCOnboarding(redirectNextPath: string) {
        // Pre signup card creation flow route
        const searchParams = new URLSearchParams(redirectNextPath);
        const dbc_data = searchParams.get('dbc_data');
        const layout = searchParams.get('layout') || this.layout;
        this.logAmplitudeEvent(PRODUCT_TYPES.DBC);
        this.router.navigate([DBC_ONBOARDING_URLS.ONBOARD_V2], {queryParams: {
            template_source: this.templateSource,
            dbc_data,
            layout,
            email: this.userEmail}
        });
    }

    hideProductSelectorAndLogin(redirectPath, options) {
        if (this.product === PRODUCT_TYPES.DBC) {
            this.logAmplitudeEvent(PRODUCT_TYPES.DBC);
        } else{
            this.logAmplitudeEvent(PRODUCT_TYPES.QR);
        }
        this.showProductSelector = false;
        this.ssoAuthService.login(redirectPath, options);
    }

    hasDBCData(queryString): boolean {
        const searchParams = new URLSearchParams(queryString);
        return searchParams.get('dbc_data') !== null;
    }

    getOrgId(queryString): string {
        const searchParams = new URLSearchParams(queryString);
        return searchParams.get('orgID');
    }

    decodeBase64(encodedString) {
        try {
            const decodedString = atob(encodedString);
            return decodedString;
        } catch (error) {
            console.error('Error decoding base64 string:', error);
            return null;
        }
    }

    getPlan(productType) {
        if (productType === PRODUCT_TYPES.QR) {
            return 'SFT-STARTER-TRIAL';
        } else if (productType === PRODUCT_TYPES.DBC) {
            return 'SFT-DBC-FREE-YEAR';
        }
        // default plan is QR
        return 'SFT-STARTER-TRIAL';
    }

    handleQRCodesSelectorComponent() {
        this.productType = PRODUCT_TYPES.QR;
        this.isProductTypeQr = true;
        this.qrCodesSvgPath = qrCodesSvgPaths.CLICKED_STATE;
        this.cardsSvgPath = cardsSvgPaths.DEFAULT_STATE;
    }

    handleCardsSelectorComponent() {
        this.productType = PRODUCT_TYPES.DBC;
        this.isProductTypeQr = false;
        this.cardsSvgPath = cardsSvgPaths.CLICKED_STATE;
        this.qrCodesSvgPath = qrCodesSvgPaths.DEFAULT_STATE;
    }

    handleMouseOverQrCodes() {
        if (!this.isProductTypeQr) {
            this.qrCodesSvgPath = qrCodesSvgPaths.HOVER_STATE;
        }
    }

    handleMouseOutQrCodes() {
        if (!this.isProductTypeQr) {
            this.qrCodesSvgPath = qrCodesSvgPaths.DEFAULT_STATE;
        }
    }

    handleMouseOverCards() {
        if (this.isProductTypeQr) {
            this.cardsSvgPath = cardsSvgPaths.HOVER_STATE;
        }
    }

    handleMouseOutCards() {
        if (this.isProductTypeQr) {
            this.cardsSvgPath = cardsSvgPaths.DEFAULT_STATE;
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}

