import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PLAN_TYPES} from '../../../user-account/user.model';
import {BUTTON_STYLES, BUTTON_TYPES} from 'kaizen-design-system';
import {DBC_PLANS_BREIF_DATA} from '../dbc-plan-data';
import {AuthService} from '../../../global-services/auth.service';
import {DBC_PRICE_PAGE_TYPE} from '../dbc-pricing-page.component';


@Component({
    selector: 'app-dbc-plan-detail-card',
    templateUrl: './plan-detail-card.component.html',
    styleUrls: ['./plan-detail-card.component.scss']
})

export class PlanDetailCardComponent {
    @Input() plan: PLAN_TYPES;
    @Input() selectedPlan: PLAN_TYPES;
    @Input() title: string;
    @Input() pageType: DBC_PRICE_PAGE_TYPE = DBC_PRICE_PAGE_TYPE.UPGRADE;

    @Output() onBuyPlanClickChange: EventEmitter<PLAN_TYPES> = new EventEmitter<PLAN_TYPES>();
    @Output() addMoreUserSeats: EventEmitter<PLAN_TYPES> = new EventEmitter<PLAN_TYPES>();
    // Variables for user-seat-input component
    @Input() showUserSeatInputBox: boolean = false;
    @Input() currentUserSeats: number = 1;
    @Input() minUsersToShow: number = 1;
    @Input() currentTotalUserSeats: number = 1;
    @Output() userSeatsChange: EventEmitter<number> = new EventEmitter<number>();
    @Input() priceToShow: number;
    DBC_PLANS_BREIF_DATA = DBC_PLANS_BREIF_DATA;

    BUTTON_TYPES = BUTTON_TYPES;
    protected readonly BUTTON_STYLES = BUTTON_STYLES;
    protected readonly PLAN_TYPES = PLAN_TYPES;

    currentPlan: PLAN_TYPES;

    constructor(private authService: AuthService) {
        this.currentPlan = this.authService.getUser().dbcCustomerPlan;
    }

    changePlan(event) {
        this.onBuyPlanClickChange.emit(event);
    }

    onAddMoreUserSeats(event: PLAN_TYPES) {
        this.addMoreUserSeats.emit(event)
    }

}
