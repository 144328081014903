import { QR_REDIRECTION_TYPE } from '../gs1-utils/gs1-constants';

export class GS1Code {

    primary_identifier_code: string;
    primary_identifier_value: string;
    key_qualifiers: any[];
    data_attributes: any[];
    redirect_type: string;
    redirect_url: string;
    organization: number;
    slug: string;
    redirect_id: number;

    constructor(
        data?: Partial<GS1Code>
    ) {
        this.primary_identifier_code = data?.primary_identifier_code || '';
        this.primary_identifier_value = data?.primary_identifier_value || '';
        this.key_qualifiers = data?.key_qualifiers || [
            {
                id: '',
                value: '',
                isError: true
            }
        ];
        this.data_attributes = data?.data_attributes || [
            {
                id: '',
                value: '',
                isError: true
            }
        ];
        this.redirect_type = data?.redirect_type || QR_REDIRECTION_TYPE.WEBSITE;
        this.redirect_url = data?.redirect_url || '';
        this.organization = data?.organization ?? 0; // Use nullish coalescing for 0
        this.slug = data?.slug || '';
        this.redirect_id = data?.redirect_id || 0;
    }

    // Reset method to restore default values
    reset(data: any = {}): void {
        this.primary_identifier_code = data?.primary_identifier_code || '';
        this.primary_identifier_value = '';
        this.key_qualifiers = [{
            id: '',
            value: ''
        }];
        this.data_attributes = [{
            id: '',
            value: ''
        }];
        this.redirect_type = QR_REDIRECTION_TYPE.WEBSITE;
        this.redirect_url = '';
        this.organization = 0;
        this.slug = '';
        this.redirect_id = 0;

    }
}
