<div class="plan-card-container" [ngClass]="{
    'selected-dbc-plan': currentPlan === plan
  }"
  >
    <div class="plan-header">
        <div class="d-flex justify-content-between">
            <div class="label mb-4"
                 [ngClass]="{
                'label--dbc-freemium': plan === PLAN_TYPES.DBCFreemium,
                'label--dbc-nteam': plan === PLAN_TYPES.DBCNTeam,
                'label--dbc-business-plus': plan === PLAN_TYPES.DBCBusinessPlus
             }">
                {{DBC_PLANS_BREIF_DATA[plan].title | uppercase}}
            </div>
            <div *ngIf="plan === currentPlan && plan !== PLAN_TYPES.DBCFreemium" class="current-plan-text">
                <i class="fas fa-check-circle"></i> Current plan
            </div>
        </div>
        <div class="price mb-4 d-flex align-items-center">
            <div *ngIf="plan !== PLAN_TYPES.DBCBusinessPlus; else bPlusDescription" class="d-flex">
                <div>
                    <span class="price-amount">${{ priceToShow ?? DBC_PLANS_BREIF_DATA[plan].amountPerUserSeat }}</span>
                </div>
                <div class="d-flex align-items-center justify-content-center pl-2">
                    <span class="user-per-month">{{ plan === PLAN_TYPES.DBCNTeam ? ' /month, billed annually' : ' for only one user' }}</span>
                </div>
            </div>
            <ng-template #bPlusDescription>
                <span class="price-amount">Talk to sales</span>
            </ng-template>
        </div>
        <div *ngIf="showUserSeatInputBox" class="mb-4">
            <app-dbc-user-seat-input
                [currentUserSeats]="currentUserSeats"
                [minUsersToShow]="minUsersToShow"
                [currentTotalUserSeats]="currentTotalUserSeats"
                (userSeatsChange)="userSeatsChange.emit($event)"
            ></app-dbc-user-seat-input>
        </div>
        <div class="mb-4">
            <app-dbc-plan-button
                [plan]="plan"
                [selectedPlan] ="selectedPlan"
                [pageType]="pageType"
                (onBuyPlanClickChange) = "changePlan($event)"
                (addMoreUserSeats)="onAddMoreUserSeats($event)"
            ></app-dbc-plan-button>
        </div>
    </div>

    <div class="plan-includes mt-4">
        <app-dbc-plan-includes [plan]="plan"></app-dbc-plan-includes>
    </div>
</div>
