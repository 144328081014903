import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {PLAN_TYPES} from '../../../user-account/user.model';
import {BUTTON_STYLES, BUTTON_TYPES} from 'kaizen-design-system';
import {AuthService} from '../../../global-services/auth.service';
import {DBC_PRICE_PAGE_TYPE} from '../dbc-pricing-page.component';

enum BUTTON_TO_SHOW {
    CURRENT_PLAN,
    SCHEDULE_DEMO,
    BUY_PLAN
}
@Component({
    selector: 'app-dbc-plan-button',
    templateUrl: './plan-button.component.html',
    styleUrls: ['./plan-button.component.scss']
})
export class PlanButtonComponent implements OnInit, OnChanges {

    @Input() plan: PLAN_TYPES;
    @Input() selectedPlan: PLAN_TYPES;
    @Input() pageType: DBC_PRICE_PAGE_TYPE = DBC_PRICE_PAGE_TYPE.UPGRADE;
    @Output() onBuyPlanClickChange: EventEmitter<PLAN_TYPES> = new EventEmitter<PLAN_TYPES>();
    @Output() addMoreUserSeats: EventEmitter<PLAN_TYPES> = new EventEmitter<PLAN_TYPES>();

    BUTTON_TYPES = BUTTON_TYPES;
    BUTTON_STYLES = BUTTON_STYLES;
    BUTTON_TO_SHOW = BUTTON_TO_SHOW;
    buttonToShow: BUTTON_TO_SHOW;
    userCurrentPlan: PLAN_TYPES;

    constructor(private authService: AuthService) {
        this.userCurrentPlan = this.authService.getUser().dbcCustomerPlan;
    }
    ngOnInit() {
        this.buttonToShow = this.getButtonToShow();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.buttonToShow = this.getButtonToShow();
    }

    getButtonToShow() {
        if ((this.pageType === DBC_PRICE_PAGE_TYPE.UPGRADE) && this.userCurrentPlan === this.plan) {
            return BUTTON_TO_SHOW.CURRENT_PLAN;
        } else if (this.plan === PLAN_TYPES.DBCBusinessPlus || this.plan === PLAN_TYPES.DBCEnterprise) {
            return BUTTON_TO_SHOW.SCHEDULE_DEMO;
        } else {
            return BUTTON_TO_SHOW.BUY_PLAN;
        }
    }

    onTalkToSalesClick() {
        this.onBuyPlanClickChange.emit(this.plan);
    }

    onBuyPlanClick() {
        this.onBuyPlanClickChange.emit(this.plan);
    }

    onAddMoreUserSeatsClick() {
        this.addMoreUserSeats.emit(this.plan);
    }

    protected readonly PLAN_TYPES = PLAN_TYPES;
}
