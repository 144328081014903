<div class="bx--row dbc-pricing-page-margin">
    <div class="bx--col" [ngClass]="{'dbc-pricing-expired-page-padding' : pageType === DBC_PRICE_PAGE_TYPE.EXPIRED, 'dbc-pricing-upgrade-page-padding' : pageType === DBC_PRICE_PAGE_TYPE.UPGRADE }">
        <app-page-description
            [pageType]="pageType"
            [totalDBCInOrg]="totalDBCInOrg"
        ></app-page-description>

        <div class="bx--row mb-4 custom-display-none">
            <div class="bx--col d-flex justify-content-center mobile-screen-padding">
                <div class="pricing-table d-flex flex-md-row flex-column flex-wrap justify-content-center align-items-center horizontal-scroll">
                    <app-dbc-plan-detail-card *ngIf="plansToShow[PLAN_TYPES.DBCFreemium]"
                        [plan]="PLAN_TYPES.DBCFreemium"
                        [selectedPlan] = "selectedPlan"
                        [pageType]="pageType"
                        (onBuyPlanClickChange)="onBuyPlanClick($event)"
                    ></app-dbc-plan-detail-card>
                    <app-dbc-plan-detail-card *ngIf="plansToShow[PLAN_TYPES.DBCNTeam]"
                        [plan]="PLAN_TYPES.DBCNTeam"
                        [selectedPlan] = "selectedPlan"
                        [pageType]="pageType"
                        [showUserSeatInputBox]="(currentPlan === PLAN_TYPES.DBCFreemium) || (pageType === DBC_PRICE_PAGE_TYPE.EXPIRED)"
                        (onBuyPlanClickChange)="onBuyPlanClick($event)"
                        [currentUserSeats]="currentUserSeats"
                        [minUsersToShow]="minUsersToShow"
                        [currentTotalUserSeats]="currentTotalUserSeats"
                        (userSeatsChange)="userSeatsToBuyChange($event)"
                        [priceToShow]="priceToShow"
                        (addMoreUserSeats)="onAddMoreUserSeats($event)"
                    ></app-dbc-plan-detail-card>
                    <app-dbc-plan-detail-card
                        [plan]="PLAN_TYPES.DBCBusinessPlus"
                        [selectedPlan] = "selectedPlan"
                        [pageType]="pageType"
                        (onBuyPlanClickChange)="onBuyPlanClick($event)"
                        (addMoreUserSeats)="onAddMoreUserSeats($event)"
                    ></app-dbc-plan-detail-card>
                </div>
            </div>
        </div>
        <!-- mobile design view <=390px -->
        <div *ngIf="visiblePlansCount !== 1" class="bx--row custom-display-show pagination mr-3 mb-4">
            <div class="p-2" (click)="handleClickBack()">
                <i class="fas fa-chevron-left" [ngClass]="{'disabled-icon': currentPlanIndexForMobileView === 1, 'enabled-icon': currentPlanIndexForMobileView !== 1}"></i>
            </div>
            <div class="pagination-container">
                <span class="pagination-text">{{currentPlanIndexForMobileView}}/{{visiblePlansCount}}</span>
            </div>
            <div class="p-2" (click)="handleClickNext()">
                <i class="fas fa-chevron-right" [ngClass]="{'disabled-icon': currentPlanIndexForMobileView === visiblePlansCount, 'enabled-icon': currentPlanIndexForMobileView !== visiblePlansCount}"></i>
            </div>
        </div>
        <div class="bx--row mb-4 custom-display-show">
            <div class="bx--col d-flex justify-content-center mobile-screen-padding"  (touchstart)="onTouchStart($event)" (touchend)="onTouchEnd($event)">
                <div class="pricing-table d-flex flex-md-row flex-column flex-wrap justify-content-center align-items-center" *ngFor="let plan of visiblePlans; let i = index" >
                    <app-dbc-plan-detail-card
                        [plan]="plan"
                        [selectedPlan]="selectedPlan"
                        [pageType]="pageType"
                        (onBuyPlanClickChange)="onBuyPlanClick($event)"
                        [showUserSeatInputBox]="plan === PLAN_TYPES.DBCNTeam ? (currentPlan === PLAN_TYPES.DBCFreemium) || (pageType === DBC_PRICE_PAGE_TYPE.EXPIRED) : null"
                        [currentUserSeats]="plan === PLAN_TYPES.DBCNTeam ? currentUserSeats : null"
                        [minUsersToShow]="plan === PLAN_TYPES.DBCNTeam ? minUsersToShow : null"
                        [currentTotalUserSeats]="plan === PLAN_TYPES.DBCNTeam ? currentTotalUserSeats : null"
                        (userSeatsChange)="plan === PLAN_TYPES.DBCNTeam ? userSeatsToBuyChange($event) : null"
                        [priceToShow]="plan === PLAN_TYPES.DBCNTeam ? priceToShow : null"
                        *ngIf="i + 1 === currentPlanIndexForMobileView"
                        (addMoreUserSeats)="plan !== PLAN_TYPES.DBCFreemium ? onAddMoreUserSeats($event) : null">
                    </app-dbc-plan-detail-card>
                </div>
            </div>
        </div>

        <div class="bx--row">
            <div class="bx--col bottom-padding">
                <app-dbc-plan-comparison [pageType]="pageType"></app-dbc-plan-comparison>
            </div>
        </div>
    </div>
</div>
