import {
    AfterViewInit,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    Pipe,
    PipeTransform,
    ViewChild
} from '@angular/core';
import {OverlayService} from '../../global-services/overlay.service';
import {Animations} from '../../shared/beaconstac-animations';
import {ActivatedRoute, ParamMap, Params, Router} from '@angular/router';
import {DigitalBusinessCard, DigitalBusinessCardTemplateModel} from '../digital-business-card.model';
import {DBCCardTypes, Utils} from '../../shared/utils';
import {of as observableOf, Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {AuthService, DATADOG_ACTIONS} from '../../global-services/auth.service';
import {
    CardsOrgSettingService,
    DigitalBusinessCardsService,
    DigitalBusinessCardTemplateService,
    LEAD_COLLECT_TYPE
} from '../digital-business-cards.service';
import {MessageModalService} from '../../shared/message-modal/message-modal.service';
import {environment} from '../../../environments/environment';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {
    CARD_PROPERTY_TYPES,
    DigitalBusinessCardSetting
} from '../digital-business-card-setting/digital-business-card-setting.model';
import {AMPLITUDE_EVENT_CATEGORIES, AMPLITUDE_EVENTS, AmplitudeService} from '../../global-services/amplitude.service';
import {INTERCOM_EVENTS, IntercomService} from '../../global-services/intercom.service';
import {DBC_LAYOUTS, FREEMIUM_QR_TEMPLATES, TEMPLATE_NAMES_MAP} from '../templates-utils';
import {CurrentPageStat} from 'app/shared/current-page-stat.model';
import {UntypedFormGroup} from '@angular/forms';
import {CustomDomain} from 'app/user-account/custom-domain.model';
import {CustomDomainService} from 'app/user-account/custom-domain.service';
import {
    DBC_DESIGN_TYPE
} from '../digital-business-card-global-components/dbc-designs-grid-view/dbc-designs-grid-view.component';
import {
    CardsOrganizationRestrictedFields,
    DBC_ORG_PERMISSION_OPTIONS
} from '../../user-account/user-organization.model';
import {BUTTON_STYLES, BUTTON_TYPES} from 'kaizen-design-system';
import {TEMPLATE_SAVE_REDIRECT_TO} from '../digital-business-card-templates/digital-business-card-templates.component';
import {UpsellService} from 'app/global-services/upsell.service';
import {FEATURES} from 'app/user-account/organization-permission-model';
import {PLAN_TYPES} from 'app/user-account/user.model';
import {cloneDeep, isEqual} from 'lodash';
import {SalesforceService} from '../../integrations/salesforce/salesforce.service';
import {getUserConsentAmplitudeValue} from '../digital-business-card-utils';
import {DbcRbacService} from '../../global-services/dbc-rabc/dbc.rbac.service';
import {DbcFormUpsellTouchpointsPermission} from '../../global-services/dbc-rabc/dbc.rbac.model';
import {DbcLDFlagService} from '../../global-services/dbc-ld-flag/dbc-ld-flag.service';
import {findDifferencesInForm, getSpecificFieldDifferences} from '../../shared/utils';
import { reduceLogoSize } from 'app/dbc-onboarding/dbc-onboard.utils';

export enum DBC_STEPPER {
    SETUP = 1,
    DESIGN,
    LAUNCH,
    FINISH
}

enum CREATION_TYPE {
    CREATE_NEW = 'createDBC',
    UPDATE = 'updateDBC'
}

enum CARD_SCREENS {
    CREATION_FLOW,
    LAYOUT_SELECT
}

export enum CARD_USER_INPUT_OPERATIONS {
    CREATION_FLOW,
    LAYOUT_SELECT,
    SELECT_TEMPLATE,
    FETCH_MORE_TEMPLATES,
    SEARCH_TEMPLATE
}

@Pipe({
    name: 'disableFieldCheckPipe'
})
export class DisableFieldCheckPipe implements PipeTransform {
    transform(hasWriterAccess, cardSetting, cardOrgSetting, cardType, priortizeOrgSettings, property) {
        if (!hasWriterAccess) {
            return true;
        }

        if (cardType === DBCCardTypes.TEMPLATE_CARDS) {
            switch (property) {
                case CARD_PROPERTY_TYPES.AUTODOWNLOAD_V2:
                case CARD_PROPERTY_TYPES.LEAD_COLLECTION_V2:
                case CARD_PROPERTY_TYPES.LOCATION_ENABLED_V2:
                case CARD_PROPERTY_TYPES.IP_LOCATION_ENABLED:
                    return cardOrgSetting[property] === DBC_ORG_PERMISSION_OPTIONS.YES || cardOrgSetting[property] === DBC_ORG_PERMISSION_OPTIONS.NO;
            }

            return false;
        } else {
            // For other types BULK, MYCARDS, TEAMCARDS
            if (cardOrgSetting[property] === DBC_ORG_PERMISSION_OPTIONS.YES || cardOrgSetting[property] === DBC_ORG_PERMISSION_OPTIONS.NO || (cardOrgSetting[property] === true)) {
                return true;
            } else {
                switch (property) {
                    case CARD_PROPERTY_TYPES.AUTODOWNLOAD_V2:
                        return cardSetting[CARD_PROPERTY_TYPES.AUTODOWNLOAD];
                    case CARD_PROPERTY_TYPES.LEAD_COLLECTION_V2:
                        return cardSetting[CARD_PROPERTY_TYPES.LEAD_COLLECTION];
                    case CARD_PROPERTY_TYPES.LOCATION_ENABLED_V2:
                        return cardSetting[CARD_PROPERTY_TYPES.LOCATION_ENABLED];
                    case CARD_PROPERTY_TYPES.IP_LOCATION_ENABLED:
                        return cardSetting[CARD_PROPERTY_TYPES.IP_LOCATION_ENABLED];
                }
                return cardSetting[property] || false;
            }
        }
    }
}


@Pipe({
    name: 'inputIconPipe'
})
export class InputIconPipe implements PipeTransform {
    transform(cardSettingValue: any,
        cardType: DBCCardTypes, priortizeOrgSettings: boolean,
        property: CARD_PROPERTY_TYPES,
        cardSettings: DigitalBusinessCardSetting,
        orgCardSettings: CardsOrganizationRestrictedFields = new CardsOrganizationRestrictedFields()): string {

        let iconToshow = '';

        switch (property) {
            case CARD_PROPERTY_TYPES.AUTODOWNLOAD_V2:
            case CARD_PROPERTY_TYPES.LEAD_COLLECTION_V2:
            case CARD_PROPERTY_TYPES.LOCATION_ENABLED_V2:
            case CARD_PROPERTY_TYPES.EMAIL_WALLET_PASS:
            case CARD_PROPERTY_TYPES.IP_LOCATION_ENABLED:
                if ( orgCardSettings[property] === DBC_ORG_PERMISSION_OPTIONS.YES || orgCardSettings[property] === DBC_ORG_PERMISSION_OPTIONS.NO) {
                    iconToshow = 'fas fa-lock text-secondary';
                } else {
                    // Check for card setting which conflicts with organization
                    switch (property) {
                        case CARD_PROPERTY_TYPES.AUTODOWNLOAD_V2:
                            iconToshow = cardSettings[CARD_PROPERTY_TYPES.AUTODOWNLOAD] ? 'fas fa-lock text-secondary pl-2' : '';
                            break;
                        case CARD_PROPERTY_TYPES.LEAD_COLLECTION_V2:
                            iconToshow = cardSettings[CARD_PROPERTY_TYPES.LEAD_COLLECTION] ? 'fas fa-lock text-secondary pl-2' : '';
                            break;
                        case CARD_PROPERTY_TYPES.LOCATION_ENABLED_V2:
                            iconToshow = cardSettings[CARD_PROPERTY_TYPES.LOCATION_ENABLED] ? 'fas fa-lock text-secondary pl-2' : '';
                            break;
                        case CARD_PROPERTY_TYPES.IP_LOCATION_ENABLED:
                            iconToshow = cardSettings[CARD_PROPERTY_TYPES.IP_LOCATION_ENABLED] ? 'fas fa-lock text-secondary pl-2' : '';
                            break;
                    }
                }
                break;
            default:
                // We will enforce org level settings if user has set it up in org settings page.
                if (orgCardSettings[property] && cardType !== DBCCardTypes.TEMPLATE_CARDS) {
                    iconToshow = 'fas fa-lock text-secondary pl-2';
                }
                iconToshow = cardSettings[property] ? 'fas fa-lock text-secondary pl-2' : iconToshow;
                break;
        }
        if (cardType === DBCCardTypes.TEMPLATE_CARDS) {
            return iconToshow === '' ? 'fas fa-lock-open unlock-color pl-2' : iconToshow;
        }
        return iconToshow;

    }
}

@Component({
    selector: 'app-digital-business-card-detail',
    templateUrl: './digital-business-card-detail.component.html',
    styleUrls: ['./digital-business-card-detail.component.scss', '../../../scss/product.scss',
        '../../markdown-cards/markdown-card-detail.scss', '../../link-page/link-page-detail/link-page-detail.component.scss'],
    animations: [Animations.collapse]
})

export class DigitalBusinessCardDetailComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('digitalBusinessCardPreview', { static: false }) digitalBusinessCardPreview: ElementRef;
    @ViewChild('mobilePreview', { static: false }) mobilePreview: ElementRef;
    @ViewChild('mobilePreviewModal', { static: false }) mobilePreviewModal: ElementRef;
    @ViewChild('createDuplicateTempalteWarningModal', { static: false }) createDuplicateTempalteWarningModal: ModalDirective;

    LINK_PAGE_BASE_ASSET_URL: string = '../../../assets/img/linkpage/';
    selectedStep: number = 1;
    product: DigitalBusinessCard | DigitalBusinessCardTemplateModel = null;
    oldProductData: DigitalBusinessCard | DigitalBusinessCardTemplateModel = null;

    cardType: DBCCardTypes;

    /*
    * Note cardSetting variable behaves differently for templates and cards
    * For Templates it will be used to lock and unlock editable fields for cards.
    * For cards (my-cards and org-cards) it behaves as which field should be locked and disabled.
     */
    cardSettings: DigitalBusinessCardSetting = new DigitalBusinessCardSetting();
    /*
     * Note cardOrgRestrictedFields variable can override cardSetting as which field should be locked and disabled.
     * As Org level restrictions are given more preference in case of conflicting fields like autodownload_v2
     */
    cardOrgRestrictedFields: CardsOrganizationRestrictedFields = new CardsOrganizationRestrictedFields();

    ngUnsubscribe: Subject<any> = new Subject();

    // iFrame load status
    isFrameLoadingSubject = new Subject<boolean>();
    isFrameLoading: boolean = false;
    contentChangeSubject = new Subject<boolean>();
    hasWriteAccess: Boolean;
    isNewDigitalBusinessCard: boolean = true
    domains = [{ name: environment.dbcDomain, domain: environment.dbcDomain, value: 1 }];

    validations = {
        first_name: true,
        email_v2: true,
        website_v2: true,
        template_name: true
    }

    validationsMultiLang: {[key: string]: {first_name_ml: boolean,
        email_v2_ml: boolean,
        website_v2_ml: boolean}} = {};

    customSlugValidations: any = {
        autoToggled: true,
        isValidDomain: true,
    };

    dbcDataToPopulate: object;
    form: UntypedFormGroup = new UntypedFormGroup({});
    isMultiLanguageContent: any;
    currentLanguage: string = 'en';
    multiLangList: any = ['en'];
    currentScreen: CARD_SCREENS = CARD_SCREENS.CREATION_FLOW
    selectedTemplate: DigitalBusinessCardTemplateModel = null;
    postSaveRedirectTo: string;
    priortizeOrgSettings: boolean = false;
    hasAccessForCreateCardButton: boolean = this.authService.getUser().hasWriteAccess(this.authService.getCurrentOrgId());
    $resize: Subject<any> = new Subject<boolean>();
    isMobileScreen = false;
    hasDBCAdvancedCustomization: boolean = false;
    isSalesforceConnected: boolean = false;
    isPreviewOpen: boolean = false; // This bool is only used for mobile screens
    onChangeDesign: boolean = false;
    showStickyButtons: boolean;
    firstFlow: boolean = false;

    dbcFormUpsellTouhPointsPermission: DbcFormUpsellTouchpointsPermission = {
        multi_language: true,
        advanced_customization: true,
        custom_slug: true,
        qr_template: true,
        layout_design: true,
        card_template: true,
        custom_fonts: true
    }

    stepOneClonedProduct: DigitalBusinessCard
    stepTwoClonedProduct: DigitalBusinessCard
    stepThreeClonedProduct: DigitalBusinessCard
    setUpStepDifferences: string[] = [];
    designStepDifferences: string[] = [];
    launchStepDifferences: string[] = [];
    LaunchStepFieldLogMap = {
        'autodownload_v2': 'advanced_download_on_scan',
        'location_enabled': 'advanced_gps_location',
        'ip_location_enabled': 'advanced_track_ip_location',
        'template': 'qr_code_template',
        '__auto_generated_slug__': 'url_domain'
    };

    designStepFieldLogMap = {
        'customizations.typography.company_details.google_font_style': 'typography_font',
        'customizations.background_color': 'color_primary_color',
        'customizations.user_info_color': 'color_profile_text_color',
        'customizations.secondary_color': 'color_secondary_text_color',
    };

    protected readonly CARD_SCREENS = CARD_SCREENS;
    protected readonly DBC_DESIGN_TYPE = DBC_DESIGN_TYPE;
    protected readonly BUTTON_STYLES = BUTTON_STYLES;
    protected readonly BUTTON_TYPES = BUTTON_TYPES;
    protected readonly DBC_STEPPER = DBC_STEPPER;
    hasBrandingRemovalAccess: boolean = false;

    constructor(private overlayService: OverlayService, private route: ActivatedRoute, private router: Router,
        private authService: AuthService, private digitalBusinessCardService: DigitalBusinessCardsService
        , private amplitudeService: AmplitudeService,
        private digitalBusinessCardTemplateService: DigitalBusinessCardTemplateService, private intercom: IntercomService,
        private messageModal: MessageModalService, private customDomainService: CustomDomainService,
        private cardsOrgSettingService: CardsOrgSettingService,
        private upsellService: UpsellService,
        private salesforceService: SalesforceService,
        private readonly dbcRbacService: DbcRbacService,
        private readonly dbcLDFlagService: DbcLDFlagService,
    ) {
        this.hasWriteAccess = authService.getUser().hasWriteAccess(this.authService.getCurrentOrgId())
        this.goToNext = this.goToNext.bind(this);
        this.hasBrandingRemovalAccess =  this.authService.getUser().organization.dbc_feature_permissions.branding_footer;

    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.$resize.next(true);
        this.setDeviceType();
    }

    private isDataChanged(oldData: any, newData: any): boolean {
        return !isEqual(oldData, newData);
    }

    private checkDataChangesToTrack() {
        // checks if below properties have been changed
        return {
            button_color_changed: this.isDataChanged(this.oldProductData.customizations.button_color, this.product.customizations.button_color),
            icon_color_changed: this.isDataChanged(this.oldProductData.customizations.icon_color, this.product.customizations.icon_color),
            primary_color_changed: this.isDataChanged(this.oldProductData.customizations.background_color, this.product.customizations.background_color),
            card_background_changed: this.isDataChanged(this.oldProductData.customizations.background, this.product.customizations.background),
            font_changed: this.isDataChanged(this.oldProductData.customizations.typography.font_family, this.product.customizations.typography.font_family),
            personal_info_font_changed: this.isDataChanged(this.oldProductData.customizations.typography.personal_info, this.product.customizations.typography.personal_info),
            company_details_font_changed: this.isDataChanged(this.oldProductData.customizations.typography.company_details, this.product.customizations.typography.company_details),
            bio_font_changed: this.isDataChanged(this.oldProductData.customizations.typography.bio, this.product.customizations.typography.bio),
            contact_details_font_changed: this.isDataChanged(this.oldProductData.customizations.typography.contact_details, this.product.customizations.typography.contact_details),
            button_font_changed: this.isDataChanged(this.oldProductData.customizations.typography.button, this.product.customizations.typography.button),
        }
    }

    ngOnInit(): void {
        if (!this.hasBrandingRemovalAccess) {
            // making this function accessible from window object to show upsell modal inside (iframe dbc preview)
            (window as any).showRemoveUniqodeBrandingModal = this.showRemoveUniqodeBrandingModal.bind(this);
        }
        this.hasDBCAdvancedCustomization = this.authService.getUser().hasDbcPermissionFor(FEATURES.advanced_customization_options);
        if (!this.hasAccessForCreateCardButton) {
            this.router.navigate(['/digital-business-cards', 'my-cards'], { relativeTo: this.route, queryParams: {
                organization: this.authService.getCurrentOrgId()
            }})
        }
        this.overlayService.isLoading(false);
        this.route.data.subscribe(res => {
            if (this.router.url.indexOf('my-cards') > -1) {
                this.cardType = DBCCardTypes.MY_CARDS
            } else if (this.router.url.indexOf('team-cards') > -1) {
                this.cardType = DBCCardTypes.TEAM_CARDS
            } else {
                this.cardType = DBCCardTypes.TEMPLATE_CARDS
            }
            if (this.cardType === DBCCardTypes.TEAM_CARDS && (!this.authService.getUser().isSuperAdmin() && this.authService.getUser().isEditor(this.authService.getCurrentOrgId()))) {
                this.router.navigate(['/overview'], { queryParams: { orgID: this.authService.getCurrentOrgId() } });
            }
        });
        this.cardsOrgSettingService.getDetail(this.authService.getCurrentOrgId()).subscribe(settings => {
            this.cardOrgRestrictedFields = new CardsOrganizationRestrictedFields(settings);
        });

        this.route.params.subscribe((params: Params) => {
            this.handleRouteParams(params);
        })
        this.route.queryParamMap.subscribe((map: ParamMap) => {
            const layoutNumber = Number(map.get('layout'));
            if (!this.hasDBCAdvancedCustomization && Utils.PREMIUM_LAYOUTS_LIST.includes(layoutNumber)) {
                this.router.navigate(['/digital-business-cards/my-cards' + '/design-library'], { relativeTo: this.route, queryParams: {
                    organization: this.authService.getCurrentOrgId()
                }})
                return;
            }
            if (this.isNewDigitalBusinessCard) {
                let dbcTemplate = JSON.parse(JSON.stringify(this.digitalBusinessCardTemplateService.getDBCTemplate()));
                if (map.has('dbc_data')) {
                    try {
                        const dbcDataReceivedFromParamParsed = JSON.parse(atob(map.get('dbc_data')));
                        this.product = dbcDataReceivedFromParamParsed ? new DigitalBusinessCard(dbcDataReceivedFromParamParsed) : new DigitalBusinessCard();
                        this.oldProductData = cloneDeep(this.product);
                        this.loadPreview(false, true);
                    } catch (error) {
                        this.router.navigate (['/digital-business-cards/my-cards' + '/design-library'], { relativeTo: this.route, queryParams: {
                            organization: this.authService.getCurrentOrgId()
                        }})
                    }
                    return;
                }
                const layout = map.get('layout');
                if (!layout) {
                    this.router.navigate(['/digital-business-cards/' + this.getCurrentCardRoute() + '/design-library'], { relativeTo: this.route, queryParamsHandling: 'merge' })
                    return;
                }
                this.selectedTemplate = this.digitalBusinessCardTemplateService.getDBCTemplate();
                // this.priortizeOrgSettings = this.selectedTemplate ? false : this.priortizeOrgSettings = this.authService.getCurrentOrganization().dbc_feature_permissions.deprecated_restricted_fields;
                if (dbcTemplate) {
                    // Copy out restricted fields from template to cardSetting
                    this.cardSettings = new DigitalBusinessCardSetting(dbcTemplate['restrictedfields']);
                }
                if (!dbcTemplate && layout) {
                    dbcTemplate = JSON.parse(JSON.stringify(DBC_LAYOUTS.filter(res => res.name === layout)[0]));
                }
                if (this.getCurrentCardRoute() === 'templates') {
                    this.product = dbcTemplate ? new DigitalBusinessCardTemplateModel(dbcTemplate) : new DigitalBusinessCardTemplateModel();
                } else {
                    delete dbcTemplate['card_owner']
                    this.product = dbcTemplate ? new DigitalBusinessCard(dbcTemplate) : new DigitalBusinessCard();
                }

                // Add default QR as uniqode's qr code
                this.product.template = JSON.parse(JSON.stringify(FREEMIUM_QR_TEMPLATES[0]));
                this.handleQueryParams(map, dbcTemplate);
            }
        });
        this.contentChangeSubject.pipe(debounceTime(500)).subscribe(isMobileScreen => {
            if (isMobileScreen) {
                this.writeOnIframe(this.mobilePreview)
            } else {
                this.writeOnIframe(this.digitalBusinessCardPreview)
            }
        });
        this.isFrameLoadingSubject.pipe(debounceTime(100)).subscribe(event => {
            this.isFrameLoading = event;
        });
        const customDomainId = this.authService.getCurrentOrganization().domains;
        if (customDomainId.length && this.authService.getUser().isSuperAdmin()) {
            this.customDomainService
                .getData(customDomainId[0])
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((res) => {
                    const { is_dbc_configured, domain, id } = new CustomDomain(res);
                    if (is_dbc_configured) {
                        const name = environment.production ? `smartcards.${domain}` : `q.smartcards.${domain}`;
                        this.domains = [{ name, value: id, domain: environment.dbcDomain }];
                    }
                });
        }
        this.priortizeOrgSettings = this.authService.getUser().organization.dbc_feature_permissions.deprecated_restricted_fields
        this.setDeviceType();
        this.$resize.pipe(debounceTime(100), takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            if (res) {
                this.setDeviceType();
            }
        })

        // Check if connected to salesforce
        if (this.authService.getUser().isOnDBCEnterprisePlan()) {
            this.salesforceService.getAuthenticatedStatus().pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
                this.isSalesforceConnected = !!response.connected;
            })
        }
    }

    hideMobilePreview() {
        this.isPreviewOpen = false;
        this.mobilePreviewModal.nativeElement.classList.remove('flex-display');
    }

    setDeviceType() {
        // 672px is for sm screen carbon design system
        this.isMobileScreen = (window.innerWidth <= 672);

        // Temporary fix for dbc stepper
        document.querySelectorAll('.section-container-main')[0].classList.add('padding-top-0px')
        try {
            if (this.isMobileScreen) {
                document.querySelectorAll('.container-fluid-xs')[0].classList.add('margin-top-0')
                document.querySelectorAll('.app-footer')[0].classList.add('margin-bottom-100px')
            } else {
                document.querySelectorAll('.container-fluid-xs')[0].classList.remove('margin-top-0')
                document.querySelectorAll('.app-footer')[0].classList.remove('margin-bottom-100px')
            }
        } catch (e) {}

        // setting default value of branding_footer for enterprise plan
        if (this.authService.getUser().isOnDBCEnterprisePlan()) {
            this.product.branding_footer = true;
        }
    }

    ngAfterViewInit() {
        document.querySelectorAll('.container-fluid')[0].classList.remove('hide-breadcrumb-container');
        document.querySelectorAll('.container-fluid')[0].classList.add('hide-breadcrumb-container-linkpage');
        this.oldProductData = JSON.parse(JSON.stringify(this.product));
        if (this.isNewDigitalBusinessCard) {
            this.loadPreview(false, true)
        }
        document.querySelectorAll('.container-fluid-xs')[0].classList.remove('margin-top-0')
        document.querySelectorAll('.app-footer')[0].classList.remove('margin-bottom-100px')
        this.setDeviceType();
    }
    getCurrentCardRoute() {
        switch (this.cardType) {
            case DBCCardTypes.MY_CARDS:
                return 'my-cards';
            case DBCCardTypes.TEAM_CARDS:
                return 'team-cards';
            case DBCCardTypes.TEMPLATE_CARDS:
                return 'templates';
        }
    }

    updatePreviewConfigurations() {
        /**
            Applies additional checks and updates the product object for preview mode,
            such as configuring visibility of UI elements based on feature flags and the current step.
         **/
        if (!this.product) {
            return;
        }

        this.product['__footer__branding__text__'] = !this.authService.getUser().organization.dbc_feature_permissions.branding_footer;
        this.product['__show_new_sticky_btn__'] = true;
        if (this.product.lead_collection && this.selectedStep === DBC_STEPPER.LAUNCH) {
            this.product['__show_lead_form_in_preview__'] = this.product.lead_attribute.connection === LEAD_COLLECT_TYPE.COLLECT_FIRST
        } else {
            this.product['__show_lead_form_in_preview__'] = false;
        }
    }


    showRemoveUniqodeBrandingModal() {
        const upsellFeature = {feature: 'uniqode_branding_upsell' , source: 'remove_branding_upgrade_cta_clicked'};
        if (!this.hasBrandingRemovalAccess) {
            this.amplitudeService.logEvent(AMPLITUDE_EVENT_CATEGORIES.Engagement, AMPLITUDE_EVENTS.REMOVE_BRANDING_MODAL_VIEWED , {});
            if (!this.isMobileScreen) {
                this.upsellService.showV2(upsellFeature)
            } else {
                this.upsellService.showUpsellModalOnMobile(upsellFeature);
            }
        }
    }
    loadPreview(isMobileScreen = false, duringInitialize = false) {
        this.updatePreviewConfigurations()
        if (!duringInitialize) {
            this.form.markAsDirty();
            this.authService.hasUnsavedChangesSource.next(true);
        }
        this.onIFrameLoadingStateChange(true);
        this.contentChangeSubject.next(isMobileScreen)
    }

    onIFrameLoadingStateChange(value: boolean) {
        this.isFrameLoadingSubject.next(value);
    }


    writeOnIframe(element) {
        if (!element) {
            setTimeout(() => {
                this.loadPreview(element);
            }, 100);
            return;
        }

        const iframe = element;
        const iframedoc = iframe.nativeElement.contentWindow || iframe.nativeElement.contentDocument.document ||
            iframe.nativeElement.contentDocument;

        iframedoc.document.open();
        if (this.isMultiLanguageContent) {
            this.product.fetchDataFromLanguageDataModel(this.currentLanguage);
            iframedoc.document.write(DigitalBusinessCard.getVcardPreviewHTML(this.product, false, true, this.multiLangList, this.currentLanguage));
        } else {
            iframedoc.document.write(DigitalBusinessCard.getVcardPreviewHTML(this.product, false, false));
        }
        iframedoc.document.close();
        this.overlayService.isLoading(false);

    }

    setUpsellTouchPointsVisibility() {

        this.dbcRbacService.dbcFormUpsellTouchpointsPermission$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
            this.dbcFormUpsellTouhPointsPermission = res;
        })
    }

    private handleQueryParams(map: ParamMap, dbcTemplateData) {
        const layout = map.get('layout');
        const template = map.get('template');
        const card_owner = map.get('card_owner')

        this.firstFlow =  map.get('TrialSignUp')?.toLowerCase() === 'true'
        if (this.firstFlow) {
            this.setUpsellTouchPointsVisibility();
        }

        this.postSaveRedirectTo = map.get('post_save_redirect_to')

        if (card_owner && (Number(card_owner) !== -1)) {
            this.product.card_owner['id'] = card_owner;
        }
        if (layout?.trim().length > 0 && ['1', '2', '3', '4', '5', '6', '7', '8', '9', '11'].includes(layout)) {

            if (map.has('qr_code_data')) {

                const dbcDataRecieved = atob(map.get('qr_code_data'));
                const dbcDataRecievedParsed = JSON.parse(dbcDataRecieved);

                if (dbcDataRecievedParsed.qr_campaign_type === 'vcard_plus') {
                    this.dbcDataToPopulate = dbcDataRecievedParsed.data;
                    this.populateDBCData(dbcTemplateData);
                }

            }

            if (!template) {
                this.setDBCLayout(layout);
            }
            this.oldProductData = JSON.parse(JSON.stringify(this.product));
            this.loadPreview(false, true)
        } else {
            const cardRoute = this.getCurrentCardRoute()
            this.router.navigate(['/digital-business-cards/' + cardRoute + '/design-library'], { relativeTo: this.route, queryParamsHandling: 'merge' })
            return
        }

    }

    private setDBCLayout(layout: string) {
        const layoutObject = DBC_LAYOUTS.filter(res => res.name === layout)[0]
        this.product.layout = layout
        this.product.customizations = JSON.parse(JSON.stringify(layoutObject['customizations']));
        this.product.contact_info_ordering = JSON.parse(JSON.stringify(layoutObject['contact_info_ordering']));
        if (this.dbcDataToPopulate) {

            for (const [key, value] of Object.entries(this.dbcDataToPopulate['customizations'])) {
                if (value) {
                    this.product.customizations[key] = value;
                }
            }

        }

    }

    private populateDBCData(defaultDbcTemplateData: object) {

        for (const key in this.dbcDataToPopulate) {
            defaultDbcTemplateData[key] = this.dbcDataToPopulate[key];
        }

    }

    onSave() {
        this.product.logo_size = reduceLogoSize(this.product.logo_size, this.product.layout);
        if (this.isMultiLanguageContent) {
            this.product.fetchDataFromLanguageDataModel();
            delete this.product.language_data[this.product.default_language];
        } else {
            this.product.default_language = null;
            this.product.language_data = {};
        }
        this.clearDataForSave();
        const pageStat = new CurrentPageStat();
        pageStat.pageNumber = 1;
        pageStat.pageSize = 10;
        pageStat.type = this.getCurrentCardRoute();
        this.authService.setCurrentPage(pageStat);

        this.form.markAsUntouched()
        this.form.markAsPristine()
        this.authService.hasUnsavedChangesSource.next(false);

        if (this.cardType === DBCCardTypes.TEMPLATE_CARDS) {
            if (this.isNewDigitalBusinessCard) {
                this.messageModal.show('Saving..', 'warning', 0)
                this.createNewTemplate();
            } else {
                this.updateTemplateWarnCheck();
            }
        } else {
            this.messageModal.show('Saving..', 'warning', 0)
            if (this.isNewDigitalBusinessCard) {
                this.createNewDBC()
            } else {
                this.updateDBC()
            }
        }
    }

    private clearDataForSave() {
        const typography = this.product.customizations.typography;
        if (typography.font_type === 'google') {
            for (const key in typography.personal_info) {
                if (!key.startsWith('google')) {
                    delete typography.personal_info[key];
                }
            }
            for (const key in typography.company_details) {
                if (!key.startsWith('google')) {
                    delete typography.company_details[key];
                }
            }
            for (const key in typography.contact_details) {
                if (!key.startsWith('google')) {
                    delete typography.contact_details[key];
                }
            }
            for (const key in typography.button) {
                if (!key.startsWith('google')) {
                    delete typography.button[key];
                }
            }
            for (const key in typography.bio) {
                if (!key.startsWith('google')) {
                    delete typography.bio[key];
                }
            }
        } else {
            for (const key in typography.personal_info) {
                if (key.startsWith('google')) {
                    delete typography.personal_info[key];
                }
            }
            for (const key in typography.company_details) {
                if (key.startsWith('google')) {
                    delete typography.company_details[key];
                }
            }
            for (const key in typography.contact_details) {
                if (key.startsWith('google')) {
                    delete typography.contact_details[key];
                }
            }
            for (const key in typography.button) {
                if (key.startsWith('google')) {
                    delete typography.button[key];
                }
            }
            for (const key in typography.bio) {
                if (key.startsWith('google')) {
                    delete typography.bio[key];
                }
            }
        }
        const fields = ['phone_v2', 'email_v2', 'website_v2', 'custom_fields']
        if (this.isMultiLanguageContent) {
            for (const language of Object.keys(this.product.language_data)) {
                for (const field of fields) {
                    const contactDetails = [];
                    this.product.language_data[language][field].forEach(field_object => {
                        if (field_object['value'] && field_object['value'].trim() !== '') {
                            contactDetails.push(field_object);
                        }
                    });
                    this.product.language_data[language][field] = contactDetails;
                }
            }
        } else {
            for (const field of fields) {
                const contactDetails = [];
                this.product[field].forEach(field_object => {
                    if (field_object['value'] && field_object['value'].trim() !== '') {
                        contactDetails.push(field_object);
                    }
                });
                this.product[field] = contactDetails;
            }
        }

        // remove __valid_url__ key from this.dbcBulk.user_agreement_urls Array list
        this.product.lead_user_agreement_attribute.user_agreement_urls = this.product.lead_user_agreement_attribute.user_agreement_urls.filter(res => {
            delete res.__valid_url__;
            return (res.url && res.label) ? res : null;
        });
    }

    createNewTemplate() {
        if (this.product instanceof DigitalBusinessCardTemplateModel) {
            this.product.restrictedfields = new DigitalBusinessCardSetting(this.cardSettings);
            this.product.organization = this.product.restrictedfields.organization = this.authService.getCurrentOrgId();
        }
        this.product.clearModelForPost();
        this.digitalBusinessCardTemplateService.post(this.product).pipe().subscribe(res => {
            this.messageModal.dismiss()
            const propsToTrack = { ...this.getFinishStepAmplitudeProps('create'), ...this.checkDataChangesToTrack()};
            this.logAmplitudeEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, AMPLITUDE_EVENTS.LAUNCH_TEMPLATE_CARD, propsToTrack);
            this.product = new DigitalBusinessCardTemplateModel(res);
            this.authService.addDatadogAction(DATADOG_ACTIONS.CREATE_DBC_TEMPLATE);
            this.intercom.trackEvent(INTERCOM_EVENTS.CREATE_DBC_TEMPLATE);
            this.afterSaveOperations('create')
        }, error => {
            this.authService.addDatadogAction(DATADOG_ACTIONS.CREATE_DBC_TEMPLATE_FAILED);
            this.amplitudeService.logEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, AMPLITUDE_EVENTS.LAUNCH_TEMPLATE_CARD, {
                new: true,
                success: false
            });
            this.intercom.trackEvent(INTERCOM_EVENTS.CREATE_DBC_TEMPLATE_FAILED);
            const errorMessage = Utils.getErrorMessage(error.error);
            if ((error.status < 500) && errorMessage) {
                this.messageModal.show(`${errorMessage}`, 'danger');
            } else {
                this.messageModal.show('Error creating. Please try again', 'danger');
            }
        })
    }

    createNewDBC() {
        this.product.organization = this.authService.getCurrentOrgId()
        if (this.product.slug) {
            this.product.__auto_generated_slug__ = false
            this.product.url = `https://${this.domains[0].domain}/` + this.product.slug
        }
        this.setUpEnvPostStepThree()

        this.digitalBusinessCardService.post(this.product).pipe().subscribe(res => {
            this.messageModal.dismiss()
            this.authService.addDatadogAction(DATADOG_ACTIONS.CREATE_DBC);
            this.intercom.trackEvent(INTERCOM_EVENTS.CREATE_DBC);
            const propsToTrack = { ...this.getFinishStepAmplitudeProps('create'), ...this.checkDataChangesToTrack()};
            this.logAmplitudeEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, AMPLITUDE_EVENTS.LAUNCH_CARD, propsToTrack);
            this.triggerSaveEvents(CREATION_TYPE.CREATE_NEW)
            this.product = new DigitalBusinessCard(res);
            this.afterSaveOperations('create')
            this.digitalBusinessCardTemplateService.setDBCTemplate(null);
        }, error => {
            this.authService.addDatadogAction(DATADOG_ACTIONS.CREATE_DBC_FAILED);
            this.amplitudeService.logEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, AMPLITUDE_EVENTS.LAUNCH_CARD, {
                new: true,
                success: false
            });
            this.intercom.trackEvent(INTERCOM_EVENTS.CREATE_DBC_FAILED);
            const errorMessage = Utils.getErrorMessage(error.error);
            if ((error.status < 500) && errorMessage) {
                this.messageModal.show(`${errorMessage}`, 'danger');
            } else {
                this.messageModal.show('Error creating. Please try again', 'danger');
            }

        })
    }

    goToNext(step: number) {
        switch (step) {
            case DBC_STEPPER.SETUP:
                this.selectedStep = DBC_STEPPER.SETUP;
                this.loadPreview();
                break;
            case DBC_STEPPER.DESIGN:
                if (this.isDataValid()) {
                    this.selectedStep = DBC_STEPPER.DESIGN;
                    this.setUpEnvPostStepOne()
                    const amplitudeEvent = this.cardType === DBCCardTypes.TEMPLATE_CARDS ? AMPLITUDE_EVENTS.SETUP_TEMPLATE_CARD : AMPLITUDE_EVENTS.SETUP_CARD;
                    this.amplitudeService.logEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, amplitudeEvent, {
                        ...((this.isMobileScreen ) ? {stepper: 'new-dbc-stepper-aug19'} : {stepper: ''}),
                        activation_experiment_1: this.dbcLDFlagService.isDbcActivationExperiment1.getValue(),
                        first_flow: this.dbcLDFlagService.isUserInFirstFlow.getValue()
                    });
                    this.amplitudeService.logEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, amplitudeEvent);
                    this.loadPreview();
                }
                break;
            case DBC_STEPPER.LAUNCH:
                if (this.selectedStep === DBC_STEPPER.SETUP && !this.isDataValid()) {
                    return;
                } else {
                    this.selectedStep = DBC_STEPPER.LAUNCH;
                    this.setUpEnvPostStepTwo()
                    const amplitudeEvent = this.cardType === DBCCardTypes.TEMPLATE_CARDS ? AMPLITUDE_EVENTS.CUSTOMIZE_TEMPLATE_CARD : AMPLITUDE_EVENTS.CUSTOMIZE_CARD;
                    this.amplitudeService.logEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, amplitudeEvent, {
                        ...(( this.isMobileScreen ) ? {stepper: 'new-dbc-stepper-aug19'} : {stepper: ''}),
                        activation_experiment_1: this.dbcLDFlagService.isDbcActivationExperiment1.getValue(),
                        first_flow: this.dbcLDFlagService.isUserInFirstFlow.getValue()
                    });
                    this.amplitudeService.logEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, amplitudeEvent);
                    this.loadPreview();
                }
                break;
            case DBC_STEPPER.FINISH:
                if ((this.cardType === DBCCardTypes.TEMPLATE_CARDS) && !this.product['template_name']) {
                    this.validations.template_name = false;
                    this.scrollDownToLink('template_name_input');
                } else if (this.customSlugValidations.autoToggled || (!this.customSlugValidations.autoToggled && this.customSlugValidations.isValidDomain)) {
                    this.onSave()
                } else {
                    if (!this.customSlugValidations.isValidDomain) {
                        this.messageModal.show('Custom domain is invalid', 'warning');
                    } else {
                        this.messageModal.show('Custom domain is required', 'warning');
                    }

                    this.scrollDownToLink('custom-slug');
                }
        }

        this.onDBCStepperTabChange()
    }

    pressBack() {
        if (this.selectedStep > 1) {
            this.selectedStep--
        }
    }

    isDataValid() {
        if (this.isMultiLanguageContent) {
            for (const language of Object.keys(this.product.language_data)) {
                const isFirstNameEmpty = !this.product.language_data[language].first_name || !this.product.language_data[language].first_name.trim();
                const isFirstNameLengthExcessive = this.product.language_data[language].first_name.length > 255;
                if (isFirstNameEmpty || isFirstNameLengthExcessive) {
                    this.validationsMultiLang[language].first_name_ml = false;
                }
                for (const key in this.validationsMultiLang[language]) {
                    if (this.validationsMultiLang[language][key] === false) {
                        this.currentLanguage = language;
                        this.scrollDownToLink(key);
                        this.loadPreview(false, false);
                        return false
                    }
                }
            }
            return true;
        } else {
            for (const key in this.validations) {
                if (this.validations[key] === false) {
                    this.scrollDownToLink(key);
                    return false
                }
            }
            return true;
        }
    }

    scrollDownToLink(id) {
        const yOffset = -150;
        if (document.getElementById(id)) {
            const y = document.getElementById(id).getBoundingClientRect().top +
                window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    }

    updateTemplateWarnCheck() {
        if (this.product.meta['dbc_count'] > 0 || this.product.meta['is_ad_integration_template']) {
            this.createDuplicateTempalteWarningModal.show();
        } else {
            this.updateTemplate();
        }
    }

    updateTemplate() {
        this.messageModal.show('Saving..', 'warning', 0)
        if (this.product instanceof DigitalBusinessCardTemplateModel) {
            this.product.restrictedfields = new DigitalBusinessCardSetting(this.cardSettings);
            this.product.organization = this.product.restrictedfields.organization = this.authService.getCurrentOrgId();
        }
        this.product.clearModelForPut()
        this.digitalBusinessCardTemplateService.put(this.product, this.product.id,
            '?organization=' + this.authService.getCurrentOrgId()).pipe().subscribe(res => {
            this.messageModal.dismiss()
            this.product = new DigitalBusinessCardTemplateModel(res)
            const propsToTrack = { ...this.getFinishStepAmplitudeProps('update'), ...this.checkDataChangesToTrack()};
            this.logAmplitudeEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, AMPLITUDE_EVENTS.LAUNCH_TEMPLATE_CARD, propsToTrack);
            this.afterSaveOperations('update')
        }, error => {
            const errorMessage = Utils.getErrorMessage(error.error);
            if ((error.status < 500) && errorMessage) {
                this.messageModal.show(`${errorMessage}`, 'danger');
            } else {
                this.messageModal.show('Error updating. Please try again', 'danger');
            }
        })
    }

    getLeadAmplitudeValue () {
        let lead_collect_amplitude_val = '';
        if ( this.product.lead_collection && this.product.lead_attribute.connection === LEAD_COLLECT_TYPE.COLLECT_FIRST) {
            lead_collect_amplitude_val = 'collect_first';
        } else if (this.product.lead_collection && this.product.lead_attribute.connection === LEAD_COLLECT_TYPE.SHARE_FIRST) {
            lead_collect_amplitude_val = 'share_first';
        } else {
            lead_collect_amplitude_val = 'no';
        }

        return lead_collect_amplitude_val;
    }

    getFinishStepAmplitudeProps(operation: string) {
        const props = {
            new: true,
            success: true,
            ...(this.product.layout && TEMPLATE_NAMES_MAP[this.product.layout] ? {layout: TEMPLATE_NAMES_MAP[this.product?.layout]} : {layout: null}),
            '2-way_contact_sharing_enabled': this.getLeadAmplitudeValue(),
            gps_tracking_enabled: this.product.location_enabled,
            ip_tracking_enabled: this.product.ip_location_enabled,
            download_on_scan_enabled: this.product.autodownload_v2,
            dbc_id: this.product.id,
            organization_id: this.product.organization,
            'user_consent_enabled': getUserConsentAmplitudeValue(this.product),
            activation_experiment_1: this.dbcLDFlagService.isDbcActivationExperiment1.getValue(),
            first_flow: this.dbcLDFlagService.isUserInFirstFlow.getValue()
        }
        switch (operation) {
            case 'create':
                props['stepper'] = this.isMobileScreen  ? 'new-dbc-stepper-aug19' : '';
                props['new'] = true;
                break;
            case 'update':
                props['new'] = false;
                break;
            default:
                return {};
        }

        return props;
    }

    logAmplitudeEvent(category: AMPLITUDE_EVENT_CATEGORIES, event: AMPLITUDE_EVENTS, props: {}) {
        this.amplitudeService.logEvent(category, event, props);
    }

    updateDBC() {
        this.digitalBusinessCardService.put(this.product, this.product.id,
            '?organization=' + this.authService.getCurrentOrgId()).pipe().subscribe(res => {
            this.messageModal.dismiss()
            this.product = new DigitalBusinessCard(res)
            const propsToTrack = { ...this.getFinishStepAmplitudeProps('update'), ...this.checkDataChangesToTrack()};
            this.logAmplitudeEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, AMPLITUDE_EVENTS.LAUNCH_CARD, propsToTrack);
            this.afterSaveOperations('update')
            this.triggerSaveEvents(CREATION_TYPE.UPDATE)
            this.digitalBusinessCardTemplateService.setDBCTemplate(null);
        }, error => {
            const errorMessage = Utils.getErrorMessage(error.error);
            if ((error.status < 500) && errorMessage) {
                this.messageModal.show(`${errorMessage}`, 'danger');
            } else {
                this.messageModal.show('Error updating. Please try again', 'danger');
            }
        })
    }

    private handleRouteParams(params: Params) {
        if (params.id) {
            this.isNewDigitalBusinessCard = false
            this.overlayService.isLoading(true);
            if (this.cardType === DBCCardTypes.TEMPLATE_CARDS) {
                this.getDigitalBusinessTemplateWithId(params.id);
            } else {
                this.getDigitalBusinessCardWithId(params.id);
            }
        } else {
            this.overlayService.isLoading(false);
            this.isNewDigitalBusinessCard = true
            this.oldProductData = JSON.parse(JSON.stringify(this.product));
            this.loadPreview(false, true)
        }
    }

    private getDigitalBusinessTemplateWithId(id: any) {
        this.digitalBusinessCardTemplateService.getDetail(id).pipe().subscribe(res => {
            this.setupDigitalBusinessCardData(res)
        }, error => {
            this.messageModal.show('Error fetching digital business card', 'danger')
            const cardRoute = this.getCurrentCardRoute();
            this.router.navigate(['/digital-business-cards/' + cardRoute], { relativeTo: this.route })

        })
    }

    private getDigitalBusinessCardWithId(id: any) {
        this.digitalBusinessCardService.getDetail(id).pipe().subscribe(res => {
            this.setupDigitalBusinessCardData(res)
        }, error => {
            this.messageModal.show('Error fetching digital business card', 'danger')
            const cardRoute = this.getCurrentCardRoute();
            this.router.navigate(['/digital-business-cards/' + cardRoute], { relativeTo: this.route })

        })
    }

    private setupDigitalBusinessCardData(res) {
        if (this.cardType === DBCCardTypes.TEMPLATE_CARDS) {
            this.product = new DigitalBusinessCardTemplateModel(res)
            this.cardSettings = new DigitalBusinessCardSetting(this.product['restrictedfields']);
        } else {
            this.product = new DigitalBusinessCard(res)
            this.cardSettings = new DigitalBusinessCardSetting({});
        }
        this.isMultiLanguageContent = this.product.default_language ? true : false;
        this.currentLanguage = this.product.default_language ? this.product.default_language : 'en';
        this.overlayService.isLoading(false);
        this.oldProductData = JSON.parse(JSON.stringify(this.product));
        this.loadPreview(false, true)
        this.priortizeOrgSettings = this.authService.getUser().organization.dbc_feature_permissions.deprecated_restricted_fields
    }

    selectLayout(layout, isNew) {
        const layoutNumber = Number(layout.name);
        if (!this.hasDBCAdvancedCustomization && Utils.PREMIUM_LAYOUTS_LIST.includes(layoutNumber))  {
            const upsellFeature = { feature: 'advanced_customizations_new_card_designs', source: 'advanced-customizations-cta' };
            const user = this.authService.getUser();
            const isOnTrial = user.isOnSoloTrial() || user.isOnTeamTrial();

            if ( isOnTrial ) {
                this.upsellService.isTrialSwitchModal.next(PLAN_TYPES.Business);
            } else {
                this.upsellService.eventSource.next('premium_layouts_upsell');
            }
            if (!this.isMobileScreen) {
                this.upsellService.showV2(upsellFeature);
            } else {
                this.upsellService.showUpsellModalOnMobile(upsellFeature);
            }
            return;
        }
        this.product.layout = layout.name;
        if (isNew) {
            this.product.customizations = layout['customizations'];
        }
        this.currentScreen = CARD_SCREENS.CREATION_FLOW;
        this.loadPreview(false, false);

        if (this.isMobileScreen) {
            setTimeout(() => {
                this.showPreview();
            }, 100)
        }
    }

    showPreview() {
        this.loadPreview(true)
        this.mobilePreviewModal.nativeElement.classList.add('flex-display');
        this.isPreviewOpen = true;
    }

    private afterSaveOperations(operation) {
        const cardRoute = this.getCurrentCardRoute();
        if (cardRoute !== 'templates') {
            this.digitalBusinessCardService.setDBCCreated(this.product, operation)
            this.router.navigate(['/digital-business-cards/' + cardRoute])
        } else if (this.postSaveRedirectTo && (this.product instanceof DigitalBusinessCardTemplateModel) && operation === 'create') {
            this.digitalBusinessCardTemplateService.setDBCTemplate(this.product);
            this.redirectToPage()
        } else {
            this.router.navigate(['/digital-business-cards/' + cardRoute])
        }
    }

    redirectToPage() {
        const cardRoute = this.getCurrentCardRoute();
        switch (this.postSaveRedirectTo) {
            case TEMPLATE_SAVE_REDIRECT_TO.SINGLE_BULK:
                this.router.navigate(['/digital-business-cards/bulk-upload'],
                    { queryParams: {layout: this.product.layout, template: this.product.id}});
                break;
            case TEMPLATE_SAVE_REDIRECT_TO.MULTI_ORG_BULK:
                const masterOrg = this.authService.getUser().mainOrganization;
                this.router.navigate(['/digital-business-cards/bulk-upload/v2'],
                    { queryParams: {orgID: masterOrg}});
                break;
            default:
                this.router.navigate(['/digital-business-cards/' + cardRoute])
        }
    }

    hasUnsavedChanges() {
        return observableOf(this.form.dirty);
    }



    handleUserInputChange(event) {
        switch (event.type) {
            case CARD_USER_INPUT_OPERATIONS.LAYOUT_SELECT:
                this.currentScreen = CARD_SCREENS.LAYOUT_SELECT;
                break;
            case CARD_USER_INPUT_OPERATIONS.SEARCH_TEMPLATE:
                break;
            case CARD_USER_INPUT_OPERATIONS.CREATION_FLOW:
                this.currentScreen = CARD_SCREENS.CREATION_FLOW;
                break;
            case CARD_USER_INPUT_OPERATIONS.FETCH_MORE_TEMPLATES:
                break;
            case CARD_USER_INPUT_OPERATIONS.SELECT_TEMPLATE:
                this.applySelectedTemplate(event.template);
                break;
        }
    }

    applySelectedTemplate(template) {
        if (!template) {
            this.product.card_template = null;
            this.digitalBusinessCardTemplateService.setDBCTemplate(null);
            // this.priortizeOrgSettings = this.authService.getCurrentOrganization().dbc_feature_permissions.deprecated_restricted_fields
            this.cardSettings = new DigitalBusinessCardSetting();
        } else {
            // Copy template fields into product and also take out the new restrictedfields into card settings
            const id = this.product.id;
            this.product.applyCardTemplate(JSON.parse(JSON.stringify(template)))
            this.product.id = id;
            this.product.card_template = template.id;
            this.cardSettings = new DigitalBusinessCardSetting(template.restrictedfields);
            // this.priortizeOrgSettings = false;
        }
        this.priortizeOrgSettings = this.authService.getUser().organization.dbc_feature_permissions.deprecated_restricted_fields
        this.loadPreview(false, false);
    }

    duplicateTemplate() {
        const duplicateTempalteData = new DigitalBusinessCardTemplateModel(this.product);
        delete duplicateTempalteData.id
        duplicateTempalteData.template_name += 'copy'

        this.digitalBusinessCardTemplateService.post(duplicateTempalteData).pipe().subscribe(res => {
            this.messageModal.show('Duplicated successfully', 'success', 3000);
            this.router.navigate(['/digital-business-cards/templates'], { relativeTo: this.route, queryParams: {
                organization: this.authService.getCurrentOrgId()
            }
            });
            this.overlayService.isLoading(true);
        }, error => {
            this.messageModal.show('Error duplicating template', 'danger', 3000);
        })
    }

    getPrimaryCtaName(): string {
        if ((this.selectedStep === DBC_STEPPER.DESIGN || this.selectedStep === DBC_STEPPER.SETUP) && !this.isPreviewOpen) {
            return 'Next';
        } else if ((this.selectedStep === DBC_STEPPER.LAUNCH) && !this.isPreviewOpen) {
            return 'Finish'
        } else if (this.isPreviewOpen) {
            return 'Close Preview'
        }
    }

    getSecondaryCtaName(): string {
        if (this.isPreviewOpen) {
            return 'Change Design'
        } else {
            return 'Back'
        }
    }

    getPrimaryCtaAction() {
        if ((this.selectedStep === DBC_STEPPER.DESIGN || this.selectedStep === DBC_STEPPER.SETUP || this.selectedStep === DBC_STEPPER.LAUNCH) && !this.isPreviewOpen) {
            return this.goToNext(this.selectedStep + 1)
        } else if (this.isPreviewOpen) {
            this.isPreviewOpen = false;
            this.mobilePreviewModal.nativeElement.classList.remove('flex-display');
        }
    }

    getSecondaryCtaAction() {
        if (!this.isPreviewOpen && this.selectedStep !== DBC_STEPPER.SETUP) {
            this.pressBack()
        } else if (!this.isPreviewOpen && this.selectedStep === DBC_STEPPER.SETUP) {
            window.history.back()
        } else {
            const layoutSelectEvent = { type: CARD_USER_INPUT_OPERATIONS.LAYOUT_SELECT };
            this.onChangeDesign = true;
            return this.handleUserInputChange(layoutSelectEvent);
        }
    }


    ngOnDestroy() {
        this.resetDifferences()
        this.digitalBusinessCardTemplateService.setDBCTemplate(null);
        document.querySelectorAll('.container-fluid')[0].classList.remove('hide-breadcrumb-container');
        document.querySelectorAll('.container-fluid')[0].classList.remove('hide-breadcrumb-container-linkpage');
        try {
            document.querySelectorAll('.section-container-main')[0].classList.remove('padding-top-0px');
            document.querySelectorAll('.container-fluid-xs')[0].classList.remove('margin-top-0')
            document.querySelectorAll('.app-footer')[0].classList.remove('margin-bottom-100px')
        } catch (e) {}
    }

    onNoLayoutChanged() {
        const layoutChangeEvent = { type: CARD_USER_INPUT_OPERATIONS.CREATION_FLOW };
        this.handleUserInputChange(layoutChangeEvent);
        setTimeout(() => {
            this.showPreview();
        }, 100)
    }

    handleStickyButtons(): boolean {
        if (this.isMobileScreen) {
            this.showStickyButtons = true;
        } else if (!this.isMobileScreen) {
            this.showStickyButtons = false;
        }
        return this.showStickyButtons;
    }

    onDBCStepperTabChange(): void {
        window.scrollTo(0, 0);
    }

    trackCardCreationEvent() {
        const cardCreatedEventProperties = {
            organization_id: this.authService.getCurrentOrgId(),
            two_way_contact_sharing_setting: this.product.lead_collection,
            share_type: this.getLeadSettingType(),
            list_of_options_enabled_for_card: this.getLeadAttributeEnabledOptions(),
            download_on_scan_setting: this.product.autodownload_v2,
            gps_location_setting: this.product.location_enabled,
            track_ip_location_setting: this.product.ip_location_enabled,
            qr_code_template_id: this.product.template.id,
            url_domain_auto_generated_setting: this.getUrlDomainAutoGeneratedSetting()
        }

        this.logAmplitudeEvent(AMPLITUDE_EVENT_CATEGORIES.Engagement, AMPLITUDE_EVENTS.CARD_CREATED, cardCreatedEventProperties)
    }

    getUrlDomainAutoGeneratedSetting() {
        return Boolean(this.product.slug)
    }

    getLeadAttributeEnabledOptions() {
        return Object.keys(this.product.lead_attribute).filter(
            (key) => typeof this.product.lead_attribute[key] === 'boolean' && this.product.lead_attribute[key]
        );
    }

    triggerSaveEvents(dbcCreationType) {
        this.logSetUpCompletionEvent()
        this.logDesignStepCompletionEvent()
        if (dbcCreationType === CREATION_TYPE.CREATE_NEW) {
            this.trackCardCreationEvent()
        } else if (dbcCreationType === CREATION_TYPE.UPDATE) {
            this.setUpEnvPostStepThree()
        }
        this.logLaunchStepCompletionEvent()
    }

    logSetUpCompletionEvent() {
        const modifiedFields = this.getModifiedFields(DBC_STEPPER.SETUP)
        this.logModifiedFields(AMPLITUDE_EVENTS.CARD_DETAILS_CUSTOMIZED, modifiedFields)

    }

    logDesignStepCompletionEvent() {
        const modifiedFields = this.getModifiedFields(DBC_STEPPER.DESIGN)
        this.logModifiedFields(AMPLITUDE_EVENTS.CARD_DESIGN_CUSTOMIZED, modifiedFields)
    }

    logLaunchStepCompletionEvent() {
        const modifiedFields = this.getModifiedFields(DBC_STEPPER.LAUNCH)
        this.logModifiedFields(AMPLITUDE_EVENTS.CARD_SET_UP_CUSTOMIZED, modifiedFields)
    }

    getLeadSettingType() {
        if (this.product.lead_attribute.connection === LEAD_COLLECT_TYPE.COLLECT_FIRST && this.product.lead_collection) {
            return 'collect_type'
        } else if (this.product.lead_attribute.connection === LEAD_COLLECT_TYPE.SHARE_FIRST && this.product.lead_collection) {
            return 'share_type'
        }

        if (!this.product.lead_collection) {
            return 'no'
        }
    }

    logModifiedFields(eventName, modifiedFields) {
        const eventPayload: any = {
            organization_id: this.authService.getCurrentOrgId(),
            card_id: this.product.id,
            customize_type: modifiedFields,
        };

        if (eventName === AMPLITUDE_EVENTS.CARD_SET_UP_CUSTOMIZED) {
            eventPayload.lead_generation_two_way_contact_sharing_setting = this.getLeadSettingType();
        }

        this.amplitudeService.logEvent(
            AMPLITUDE_EVENT_CATEGORIES.Engagement,
            eventName,
            eventPayload
        );
    }

    setUpEnvPostStepOne() {
        const customizedFields = findDifferencesInForm(this.product, this.stepOneClonedProduct);
        this.updateDifferences(DBC_STEPPER.SETUP, customizedFields);
    }

    setUpEnvPostStepTwo() {
        const customizedFields = getSpecificFieldDifferences(this.product, this.stepTwoClonedProduct, this.designStepFieldLogMap);
        this.updateDifferences(DBC_STEPPER.DESIGN, customizedFields);
    }

    setUpEnvPostStepThree() {
        this.stepThreeClonedProduct.custom_fields = this.product.custom_fields
        const customizedFields = getSpecificFieldDifferences(this.product, this.stepThreeClonedProduct, this.LaunchStepFieldLogMap);
        this.updateDifferences(DBC_STEPPER.LAUNCH, customizedFields);
    }

    assignClonedProduct(event, step) {
        if (step === DBC_STEPPER.SETUP) {
            this.stepOneClonedProduct = event
        } else if (step === DBC_STEPPER.DESIGN) {
            this.stepTwoClonedProduct = event
        } else if (step === DBC_STEPPER.LAUNCH) {
            this.stepThreeClonedProduct = event
        }
    }

    getModifiedFields(step: number) {
        switch (step) {
            case DBC_STEPPER.SETUP:
                return this.setUpStepDifferences
            case DBC_STEPPER.DESIGN:
                return this.designStepDifferences
            case DBC_STEPPER.LAUNCH:
                return this.launchStepDifferences
            default:
                break;
        }
    }

    updateDifferences(step: DBC_STEPPER, newDifferences: any[]) {
        const modifiedFields = this.getModifiedFields(step);

        if (modifiedFields) {
            const uniqueDifferences = newDifferences.filter(diff => !modifiedFields.includes(diff));
            if (step === DBC_STEPPER.SETUP) {
                this.setUpStepDifferences = [...modifiedFields, ...uniqueDifferences];
            } else if (step === DBC_STEPPER.DESIGN) {
                this.designStepDifferences = [...modifiedFields, ...uniqueDifferences];
            } else if (step === DBC_STEPPER.LAUNCH) {
                this.launchStepDifferences = [...modifiedFields, ...uniqueDifferences];
            }
        } else {
            console.error('Invalid step:', step);
        }
    }


    resetDifferences() {
        this.setUpStepDifferences = [];
        this.designStepDifferences = [];
        this.launchStepDifferences = [];
    }
}
