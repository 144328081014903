import { Filter, FILTER_OPERATOR_TYPE } from 'app/global-components/filter-products/filter.model';
import { environment } from 'environments/environment';

export enum QR_REDIRECTION_TYPE {

    WEBSITE = 'url',
    LINKPAGE = 'linkpage',
    FILE = 'file',
}

export enum GS1_STEPS {
    PRODUCT_DETAILS = 'product_details',
    CUSTOMIZE = 'customize',
}

export enum BARCODE_TYPES {
    ITF14 = 'ITF14',
    EAN13 = 'EAN13',
    UPC_A = 'UPC',
    CODE128 = 'CODE128',
}

export const GS1_DOMAINS = environment.gs1Domains;

export const GS1_CONTENT_TYPE = 28;

export const GS1_DOCUMENTATION_LINK = 'https://docs.uniqode.com/en/articles/10750862-introduction-to-gs1-qr-codes';

export const GS1_FILTER_OPTIONS: Array<Filter> = [
    new Filter({
        name: 'GS1 QR Code Name',
        icon: 'fas fa-qrcode',
        value: 'name',
        input: '',
        show: true,
        operators: [
            { name: 'contains', value: '__icontains_all' },
            { name: 'does not contain', value: '__icontains_nin' },
            { name: 'is', value: '__in' },
            { name: 'is not', value: '__nin' },
        ],
        type: FILTER_OPERATOR_TYPE.String,
    }),
    new Filter({
        name: 'Identifier (AI)',
        icon: 'fas fa-laptop-code',
        value: 'campaign__gs1_code',
        input: '',
        show: true,
        operators: [
            { name: 'is', value: '__primary_identifier_code__in' },
            { name: 'is not', value: '__primary_identifier_code__nin' },
        ],
        placeholder: 'Select Application Identifier',
        dropdownOptions: [
            {
                'text': 'SSCC',
                'value': '00'
            },
            {
                'text': 'GTIN',
                'value': '01'
            },
            {
                'text': 'GDTI',
                'value': '253'
            },
            {
                'text': 'GCN',
                'value': '255'
            },
            {
                'text': 'GINC',
                'value': '401'
            },
            {
                'text': 'GSIN',
                'value': '402'
            },
            {
                'text': 'SHIP TO LOC',
                'value': '410'
            },
            {
                'text': 'BILL TO',
                'value': '411'
            },
            {
                'text': 'PURCHASE FROM',
                'value': '412'
            },
            {
                'text': 'SHIP FOR LOC',
                'value': '413'
            },
            {
                'text': 'LOC No.',
                'value': '414'
            },
            {
                'text': 'PAY TO',
                'value': '415'
            },
            {
                'text': 'PROD/SERV LOC',
                'value': '416'
            },
            {
                'text': 'PARTY',
                'value': '417'
            },
            {
                'text': 'GRAI',
                'value': '8003'
            },
            {
                'text': 'GIAI',
                'value': '8004'
            },
            {
                'text': 'ITIP',
                'value': '8006'
            },
            {
                'text': 'CPID',
                'value': '8010'
            },
            {
                'text': 'GMN',
                'value': '8013'
            },
            {
                'text': 'GSRN - PROVIDER',
                'value': '8017'
            },
            {
                'text': 'GSRN - RECIPIENT',
                'value': '8018'
            }
        ],
        type: FILTER_OPERATOR_TYPE.Dropdown,
    }),
    new Filter({
        name: 'AI Value',
        icon: 'fas fa-hashtag',
        value: 'campaign__gs1_code',
        input: '',
        show: true,
        operators: [
            { name: 'contains', value: '__primary_identifier_value__icontains_all' },
            { name: 'does not contain', value: '__primary_identifier_value__icontains_nin' },
            { name: 'is', value: '__primary_identifier_value__in' },
            { name: 'is not', value: '__primary_identifier_value__nin' },
        ],
        type: FILTER_OPERATOR_TYPE.String,
    }),
    new Filter({
        name: 'GS1 Digital Link',
        icon: 'fas fa-link',
        value: 'url',
        input: '',
        show: true,
        operators: [
            { name: 'contains', value: '__icontains_all' },
            { name: 'does not contain', value: '__icontains_nin' },
            { name: 'is', value: '__in' },
            { name: 'is not', value: '__nin' },
        ],
        type: FILTER_OPERATOR_TYPE.String,
    }),
    new Filter({
        name: 'Scan Count',
        placeholder: 'Enter a number: eg., 8, 32...',
        icon: 'fas fa-camera',
        value: 'scans',
        input: '',
        show: true,
        operators: [
            { name: 'is', value: '' },
            { name: 'is less than', value: '__lt' },
            { name: 'is greater than', value: '__gt' },
            { name: 'is not', value: '__neq' },
        ],
        type: FILTER_OPERATOR_TYPE.Number,
    }),
    new Filter({
        name: 'Date Created',
        icon: 'fas fa-calendar-alt',
        value: 'created',
        input: '',
        useOnce: true,
        show: true,
        radioOptions: [
            {
                name: 'Last 24 hours',
                state: false,
                disabled: false,
                value: '1',
            },
            {
                name: 'Last week',
                state: false,
                disabled: false,
                value: '7',
            },
            {
                name: 'Last month',
                state: false,
                disabled: false,
                value: '30',
            },
            {
                name: 'Last 3 months',
                state: false,
                disabled: false,
                value: '90',
            },
            {
                name: 'Custom',
                state: false,
                disabled: false,
            },
        ],
        type: FILTER_OPERATOR_TYPE.Date,
    }),
    new Filter({
        name: 'Last Updated',
        icon: 'fas fa-calendar-alt',
        value: 'updated',
        input: '',
        useOnce: true,
        show: true,
        radioOptions: [
            {
                name: 'Last 24 hours',
                state: false,
                disabled: false,
                value: '1',
            },
            {
                name: 'Last week',
                state: false,
                disabled: false,
                value: '7',
            },
            {
                name: 'Last month',
                state: false,
                disabled: false,
                value: '30',
            },
            {
                name: 'Last 3 months',
                state: false,
                disabled: false,
                value: '90',
            },
            {
                name: 'Custom',
                state: false,
                disabled: false,
            },
        ],
        type: FILTER_OPERATOR_TYPE.Date,
    })
];
