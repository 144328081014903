import {DBC_MAX_USER_SEATS, DBC_MINM_USER_SEATS, PLAN_TYPES} from '../../user-account/user.model';

export const DBC_PLANS_BREIF_DATA = {
    [PLAN_TYPES.DBCFreemium]: {
        title: 'Free',
        amountPerUserSeat: 0,
        maxUserSeats: DBC_MAX_USER_SEATS.DBCFreemium,
        maxUserSeatsText: `${DBC_MAX_USER_SEATS.DBCFreemium} user`,
        minUserSeatsAllowed: DBC_MINM_USER_SEATS.DBCFreemium,
        titleText: 'Includes',
        includes: [
            'Only 1 User',
            '1 Card',
            'Lead Generation (Up to 5 leads)',
            'Share via Apple/Google Wallet',
            '14 days Card Analytics',
            '2 Card Designs',
            'Basic Card Customization',
            'Unlimited Sharing',
        ]
    },
    [PLAN_TYPES.DBCNTeam]: {
        title: 'Team',
        amountPerUserSeat: 6,
        maxUserSeats: DBC_MAX_USER_SEATS.DBCNTeam,
        maxUserSeatsText: 'Unlimited users',
        minUserSeatsAllowed: DBC_MINM_USER_SEATS.DBCNTeam,
        titleText: 'Everything in Free plan, plus',
        includes: [
            'Minimum 1 User',
            '3 Cards per User',
            'Unlimited Lead Generation',
            'Advanced Card Customizations',
            'Premium Card Designs',
            'Unlimited Card Analytics',
            'Branded QR Codes',
            'Bulk Card Creation',
            'Template Management',
            'Zapier Integration',
            'Google Analytics Integration',
        ]
    },
    [PLAN_TYPES.DBCBusinessPlus]: {
        title: 'Business+',
        amountPerUserSeat: null,
        maxUserSeats: DBC_MAX_USER_SEATS.DBCBusinessPlus,
        maxUserSeatsText: `${DBC_MINM_USER_SEATS.DBCBusinessPlus}+ users`,
        minUserSeatsAllowed: DBC_MINM_USER_SEATS.DBCBusinessPlus,
        titleText: 'Everything in Team plan, plus',
        includes: [
            'Minimum 50 Users',
            'Unlimited Cards per User',
            'White Labeling',
            'Custom Fonts and Style',
            'Unlimited Card Analytics',
            'Microsoft Entra ID Integration',
            'Salesforce Integration',
            '1 Custom Domain'
        ]
    }
};

export const DBC_FEATURE_DETAILS_DATA_NEW = [
    {
        'categoryName': 'Digital business card essentials',
        'svgImage': '../../../../assets/img/dbc-pricing-page/Card-essentials-icon.svg',
        'categoryData': [
            {
                'type': 'Number of users',
                'mobileType': 'Number of users',
                'description': ' ',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': '1'
                    },
                    {
                        'planType': 'TEAM',
                        'value': 'Min. 1'
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'Min. 50'
                    }
                ]
            }, {
                'type': 'Card personalization',
                'mobileType': 'Personalize your cards',
                'description': 'Add a personal touch to your cards with unique colors & templates that represent you perfectly.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': 'Limited'
                    },
                    {
                        'planType': 'TEAM',
                        'value': 'true'
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'Branded Qr Codes',
                'mobileType': 'Get branded QR Code',
                'description': 'Get QR codes that connects to your digital business card, personalized with your brand’s colors, logo, and shapes.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': ''
                    },
                    {
                        'planType': 'TEAM',
                        'value': 'true'
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'Seamless wallet integration',
                'mobileType': 'Share card via Apple/Google Wallet',
                'description': 'Save your digital business card to Apple or Google Wallet and share it effortlessly—no extra app required.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': 'true'
                    },
                    {
                        'planType': 'TEAM',
                        'value': 'true'
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'Two-way contact sharing',
                'mobileType': 'Two-way contact sharing',
                'description': 'Share your card with prospects and get their contact in return. No app required.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': 'true'
                    },
                    {
                        'planType': 'TEAM',
                        'value': 'true'
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'Contact manager',
                'mobileType': 'Contact manager',
                'description': 'Manage all your collected contacts in one place for sales & marketing campaigns.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': 'true'
                    },
                    {
                        'planType': 'TEAM',
                        'value': 'true'
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'Multi-lingual digital business cards',
                'mobileType': 'Multi-lingual cards',
                'description': 'Connect globally by creating your card in multiple languages, letting prospects view your details in their preferred language.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': ''
                    },
                    {
                        'planType': 'TEAM',
                        'value': 'true'
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'Custom Fonts & Style',
                'mobileType': 'Custom Fonts & Style',
                'description': 'Upload your custom fonts and use them for creating and editing your cards; match your brand\'s unique style, and add a personal touch to your projects.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': ''
                    },
                    {
                        'planType': 'TEAM',
                        'value': ''
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'Advanced Customizations',
                'mobileType': 'Advanced Customizations',
                'description': 'Upload custom backgrounds and detailed branding to fully tailor your card to your brand’s identity.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': ''
                    },
                    {
                        'planType': 'TEAM',
                        'value': 'true'
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }
        ]
    }
    ,
    {
        'categoryName': 'Reporting & analytics',
        'svgImage': '../../../../assets/img/dbc-pricing-page/Report.svg',
        'categoryData': [
            {
                'type': 'GPS tracking',
                'mobileType': 'GPS tracking',
                'description': 'Get the exact GPS coordinates to know where people are scanning your digital business card from.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': 'true'
                    },
                    {
                        'planType': 'TEAM',
                        'value': 'true'
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'Card analytics',
                'mobileType': 'Card analytics',
                'description': 'Get a bird’s eye view of all your card saves and views— filtered by time, location and device.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': '14 days'
                    },
                    {
                        'planType': 'TEAM',
                        'value': 'Unlimited'
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'Unlimited'
                    }
                ]
            }
        ]
    }
    ,
    {
        'categoryName': 'Data & security',
        'svgImage': '../../../../assets/img/dbc-pricing-page/Shield.svg',
        'categoryData': [
            {
                'type': 'GDPR compliant',
                'mobileType': 'GDPR compliant',
                'description': 'Protect your user data as per GDPR compliance.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': 'true'
                    },
                    {
                        'planType': 'TEAM',
                        'value': 'true'
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'SOC® 2 Type 2 compliant',
                'mobileType': 'SOC® 2 Type 2 compliant',
                'description': 'Ensure top security and privacy standards with SOC® 2 Type 2 compliance.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': 'true'
                    },
                    {
                        'planType': 'TEAM',
                        'value': 'true'
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'Anomalous scan detection',
                'mobileType': 'Anomalous scan detection',
                'description': 'Identify abnormal scan behavior and filter them out. Maintain data sanity and accurate campaign analytics.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': 'true'
                    },
                    {
                        'planType': 'TEAM',
                        'value': 'true'
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'Malware & phishing URL detection',
                'mobileType': 'Malware & phishing URL detection',
                'description': 'Protect your users from malicious phishing attacks with our URL-based phishing detection.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': 'true'
                    },
                    {
                        'planType': 'TEAM',
                        'value': 'true'
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'SSO integration via SAML',
                'mobileType': 'SSO integration via SAML',
                'description': 'Ensure complete control and security with a singular company-level sign-in SAML account.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': ''
                    },
                    {
                        'planType': 'TEAM',
                        'value': ''
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': '2-factor authentication (2FA)',
                'mobileType': '2-factor authentication (2FA)',
                'description': 'Safeguard your data against phishing attacks and unauthorized access with 2FA.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': ''
                    },
                    {
                        'planType': 'TEAM',
                        'value': ''
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'Infosec questionnaire filling',
                'mobileType': 'Infosec questionnaire filling',
                'description': 'Get your Infosec questionnaire filled out by us and be assured of a top-tier secure partnership.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': ''
                    },
                    {
                        'planType': 'TEAM',
                        'value': ''
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'Trust portal access',
                'mobileType': 'Trust portal access',
                'description': 'View all information related to Uniqode’s security and privacy practices in one place.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': ''
                    },
                    {
                        'planType': 'TEAM',
                        'value': ''
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }
        ]
    }
    ,
    {
        'categoryName': 'Branding',
        'svgImage': '../../../../assets/img/dbc-pricing-page/Brand.svg',
        'categoryData': [
            {
                'type': 'Short URL',
                'mobileType': 'Short URL',
                'description': 'Modify and shorten your URL slug as per your digital business card needs.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': ''
                    },
                    {
                        'planType': 'TEAM',
                        'value': 'true'
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'Custom domain',
                'mobileType': 'Custom domain',
                'description': 'Use your brand’s URL to create a custom domain for your digital business card.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': ''
                    },
                    {
                        'planType': 'TEAM',
                        'value': ''
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }
        ]
    },
    {
        'categoryName': 'Collaboration & control',
        'svgImage': '../../../../assets/img/dbc-pricing-page/Group.svg',
        'categoryData': [
            {
                'type': 'Role-based access control',
                'mobileType': 'Role-based access control',
                'description': 'Configure custom role-based access to different users.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': ''
                    },
                    {
                        'planType': 'TEAM',
                        'value': 'true'
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'Ownership transfer',
                'mobileType': 'Ownership transfer',
                'description': 'Transfer account ownership and admin access to current employees at the time of team member exits.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': ''
                    },
                    {
                        'planType': 'TEAM',
                        'value': 'true'
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'Enforce card templates',
                'mobileType': 'Enforce card templates',
                'description': 'Lock digital business card templates at an organization-level to ensure uniformity.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': ''
                    },
                    {
                        'planType': 'TEAM',
                        'value': 'true'
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'Enforce non-editable card fields',
                'mobileType': 'Enforce non-editable card fields',
                'description': 'Make certain input fields on a digital business card uneditable by the recipient to ensure greater uniformity.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': ''
                    },
                    {
                        'planType': 'TEAM',
                        'value': 'true'
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'Separate organizations or workspaces',
                'mobileType': 'Separate organizations or workspaces',
                'description': 'Create separate organizations or workspaces with configured access if you have multiple teams using digital business cards.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': ''
                    },
                    {
                        'planType': 'TEAM',
                        'value': ''
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'Custom'
                    }
                ]
            }, {
                'type': 'Entra ID Group to Organization mapping',
                'mobileType': 'Entra ID Group to Organization mapping',
                'description': 'Map Microsoft Entra ID groups to organizations or workspaces in your Uniqode dashboard.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': ''
                    },
                    {
                        'planType': 'TEAM',
                        'value': ''
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'Just-in-time user provisioning',
                'mobileType': 'Just-in-time user provisioning',
                'description': 'Create a user account automatically the first time a user logs in with a SAML identity provider.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': ''
                    },
                    {
                        'planType': 'TEAM',
                        'value': ''
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'Cards transfer across organizations',
                'mobileType': 'Cards transfer across organizations',
                'description': 'Move your digital business cards between different organizations or workspaces without any hassle.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': ''
                    },
                    {
                        'planType': 'TEAM',
                        'value': ''
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'Dedicated asset libraries',
                'mobileType': 'Dedicated asset libraries',
                'description': 'Keep your assets organized by creating unique branding kits for each organization, ready for seamless company-wide sharing.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': ''
                    },
                    {
                        'planType': 'TEAM',
                        'value': ''
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'Analytics export',
                'mobileType': 'Analytics export',
                'description': 'Export analytics for one or multiple digital business cards as CSV files.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': ''
                    },
                    {
                        'planType': 'TEAM',
                        'value': ''
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }
        ]
    },
    {
        'categoryName': 'Scalability',
        'svgImage': '../../../../assets/img/dbc-pricing-page/Upscale.svg',
        'categoryData': [
            {
                'type': 'Bulk Upload',
                'mobileType': 'Bulk Upload',
                'description': 'Upload multiple cards or contacts at once using CSV files.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': ''
                    },
                    {
                        'planType': 'TEAM',
                        'value': 'true'
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'Uniqode API',
                'mobileType': 'Uniqode API',
                'description': 'Use Uniqode API to create, track, and manage all your digital business cards at scale.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': ''
                    },
                    {
                        'planType': 'TEAM',
                        'value': 'true'
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }
        ]
    },
    {
        'categoryName': 'Integrations',
        'svgImage': '../../../../assets/img/dbc-pricing-page/Puzzle.svg',
        'categoryData': [
            {
                'type': 'Microsoft Entra ID',
                'mobileType': 'Microsoft Entra ID',
                'description': 'Manage employee permissions and access to digital business card resources with a seamless Uniqode and Microsoft Entra ID integration.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': ''
                    },
                    {
                        'planType': 'TEAM',
                        'value': ''
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'Salesforce',
                'mobileType': 'Salesforce',
                'description': 'Automate follow-ups and outreach campaigns by transferring contacts collected with digital business cards into Salesforce.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': ''
                    },
                    {
                        'planType': 'TEAM',
                        'value': ''
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'Zapier',
                'mobileType': 'Zapier',
                'description': 'Move information between Uniqode cards and your web apps using Zapier.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': ''
                    },
                    {
                        'planType': 'TEAM',
                        'value': 'true'
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'Google Analytics',
                'mobileType': 'Google Analytics',
                'description': 'Connect Uniqode cards as a property on Google Analytics to get unique and advanced user insights.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': ''
                    },
                    {
                        'planType': 'TEAM',
                        'value': 'true'
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'Meta Pixel',
                'mobileType': 'Meta Pixel',
                'description': 'Integrate with Meta Pixel to re-target users who viewed your card.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': ''
                    },
                    {
                        'planType': 'TEAM',
                        'value': 'true'
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'Google Ads',
                'mobileType': 'Google Ads',
                'description': 'Integrate with Google Ads to re-target users who viewed your card.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': ''
                    },
                    {
                        'planType': 'TEAM',
                        'value': 'true'
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }
        ]
    },
    {
        'categoryName': 'Customer support',
        'svgImage': '../../../../assets/img/dbc-pricing-page/Support.svg',
        'categoryData': [
            {
                'type': 'Knowledge base',
                'mobileType': 'Knowledge base',
                'description': 'Find articles, videos, and guides to help you with everything Uniqode.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': 'true'
                    },
                    {
                        'planType': 'TEAM',
                        'value': 'true'
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'Live chat',
                'mobileType': 'Live chat',
                'description': 'Unmatched customer support for all users via chat. Available anytime, anywhere.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': 'true'
                    },
                    {
                        'planType': 'TEAM',
                        'value': 'true'
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'Email support',
                'mobileType': 'Email support',
                'description': 'Reach out to our support team anytime—we’ll respond with a solution within hours.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': 'true'
                    },
                    {
                        'planType': 'TEAM',
                        'value': 'true'
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'Phone support',
                'mobileType': 'Phone support',
                'description': 'Call our digital business card experts and quickly resolve any issue.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': 'true'
                    },
                    {
                        'planType': 'TEAM',
                        'value': 'true'
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'Dedicated customer success manager',
                'mobileType': 'Dedicated customer success manager',
                'description': 'Get personalized customer onboarding, training hours, and hands-on support.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': ''
                    },
                    {
                        'planType': 'TEAM',
                        'value': ''
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'Onboarding & setup',
                'mobileType': 'Onboarding & setup',
                'description': 'Get hands-on support during onboarding and setup of the Uniqode dashboard.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': ''
                    },
                    {
                        'planType': 'TEAM',
                        'value': ''
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'Redlining',
                'mobileType': 'Redlining',
                'description': 'Create negotiable, custom contracts for your subscription. ',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': ''
                    },
                    {
                        'planType': 'TEAM',
                        'value': ''
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }, {
                'type': 'SLA',
                'mobileType': 'SLA',
                'description': 'Create tailored and unique agreements that reflect your requirements and expectations.',
                'plans': [
                    {
                        'planType': 'FREE',
                        'value': ''
                    },
                    {
                        'planType': 'TEAM',
                        'value': ''
                    },
                    {
                        'planType': 'BUSINESS+',
                        'value': 'true'
                    }
                ]
            }
        ]
    }
]
