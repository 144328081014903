import { Component } from '@angular/core';
import {DbcIndexedDbService} from '../../global-services/indexed-db.service';
import {ONBOARDING_STORE_NAME} from '../../dbc-onboard-v2/dbc-onboard-v2.utils';

@Component({
    selector: 'app-file-share-mktg',
    templateUrl: './file-share-mktg.component.html',
    styleUrls: ['./file-share-mktg.component.scss']
})
export class FileShareMKTGComponent {
    fileUrl: string | ArrayBuffer = '';

    constructor(private readonly dbcIndexDbService: DbcIndexedDbService) {
        try {
            const loader = document.getElementById('overlay-loader')
            if (loader) {
                loader.style.display = 'none';
            }
        } catch (e) {
            console.error('Error hiding loader', e);
        }
        window.addEventListener('message', (event) => {
            if (event.data && event.data?.data) {
                this.readFile(event.data?.data);
            }
        });
    }

    readFile(file: File) {
        try {
            const reader = new FileReader();
            reader.onload = (e) => {
                this.fileUrl = e.target.result;
                this.dbcIndexDbService.setKeyValue(ONBOARDING_STORE_NAME, 'user_image_url', this.fileUrl).subscribe();
            };
            reader.readAsDataURL(file);
        } catch (e) {
            console.error('Error reading file', e);
        }
    }
}
