import {Component, Input} from '@angular/core';
import {DBC_PRICE_RATES, PLAN_TYPES} from '../../../user-account/user.model';
import {DBC_FEATURE_DETAILS_DATA} from '../../../user-account/dbc-feature-details-data';
import {DBC_FEATURE_DETAILS_DATA_NEW} from '../dbc-plan-data';
import {BUTTON_TYPES} from 'kaizen-design-system';
import {DBC_PRICE_PAGE_TYPE} from '../dbc-pricing-page.component';
@Component({
    selector: 'app-dbc-plan-comparison',
    templateUrl: './plan-comparison.component.html',
    styleUrls: ['./plan-comparison.component.scss', '../plan-detail-card/plan-detail-card.component.scss']
})
export class PlanComparisonComponent {

    @Input() pageType: DBC_PRICE_PAGE_TYPE = DBC_PRICE_PAGE_TYPE.UPGRADE;

    protected readonly DBC_PRICE_RATES = DBC_PRICE_RATES;
    protected readonly DBC_FEATURE_DETAILS_DATA = DBC_FEATURE_DETAILS_DATA;
    protected readonly PLAN_TYPES = PLAN_TYPES;

    planTypeIndex: number = 0;

    protected readonly DBC_FEATURE_DETAILS_DATA_NEW = DBC_FEATURE_DETAILS_DATA_NEW;
    protected readonly BUTTON_TYPES = BUTTON_TYPES;

    categoryToggleStates: { [key: string]: boolean } = {'Digital business card essentials': true};
    switchMobilePlans(planType: PLAN_TYPES) {
        switch (planType) {
            case PLAN_TYPES.DBCFreemium:
                this.planTypeIndex = 0;
                break;

            case PLAN_TYPES.DBCNTeam:
                this.planTypeIndex = 1;
                break;

            case PLAN_TYPES.DBCBusinessPlus:
                this.planTypeIndex = 2;
                break;
        }
    }

    protected readonly DBC_PRICE_PAGE_TYPE = DBC_PRICE_PAGE_TYPE;
}
