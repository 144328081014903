<ul class="collapsed-side-nav">
    <div class="listItems">
        <!-- Collapse/Expand toggle -->
        <span class="collapse-icon" (click)="toggleCollapsedState()" [style.top.px]="
                currentDashboard === DASHBOARD_MENU.QRCODES &&
                qrCreateCtaVariation === QR_CREATE_CTA_VARIATIONS.CREATE_NEW_PRIMARY
                    ? 25
                    : 'auto'
            ">
            <span class="custom-tooltip">Expand menu</span>
            <i class="fas fa-sm fa-chevron-right" style="color: #5e6980; font-weight: 900"></i>
        </span>

        <!-- Create CTA -->
        <div *ngIf="
                currentDashboard === DASHBOARD_MENU.QRCODES &&
                qrCreateCtaVariation === QR_CREATE_CTA_VARIATIONS.CREATE_NEW_PRIMARY
            " dropdown placement="right" container="body" class="create-cta-box">
            <li class="create-cta" dropdownToggle aria-controls="dropdown-qr-cta" [tooltip]="'Create new'"
                placement="right">
                <i class="fas fa-plus"></i>
            </li>

            <ul id="dropdown-qr" *dropdownMenu class="dropdown-menu custom-drop-down-menu" role="menu"
                (mouseout)="selectDropdownIcon()">
                <li role="menuitem">
                    <a class="dropdown-item cta" [routerLink]="['/qr-codes/add']"
                        [queryParams]="{ orgID: currentOrganizationId }"
                        (mouseover)="selectDropdownIcon(campaignTypes.QR_CODE)"
                        (click)="logAmplitudeEventForCreateQRcta(QR_CREATE_CTA_VARIATIONS.CREATE_NEW_PRIMARY, 'QR')">
                        <img [src]="dropdownIcon.qrCode" class="pr-3" /><span>QR Code</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a class="dropdown-item cta" [routerLink]="['/linkpage/templates/']"
                        [queryParams]="{ orgID: currentOrganizationId }"
                        (mouseover)="selectDropdownIcon(campaignTypes.LINK_PAGE)" (click)="
                            logAmplitudeEventForCreateQRcta(QR_CREATE_CTA_VARIATIONS.CREATE_NEW_PRIMARY, 'Linkpages')
                        ">
                        <img [src]="dropdownIcon.linkpage" class="pr-3" /><span>Linkpage</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a class="dropdown-item cta" [routerLink]="['/forms/templates']"
                        [queryParams]="{ setFormName: true, orgID: currentOrganizationId }"
                        (mouseover)="selectDropdownIcon(campaignTypes.FORM)"
                        (click)="logAmplitudeEventForCreateQRcta(QR_CREATE_CTA_VARIATIONS.CREATE_NEW_PRIMARY, 'Forms')">
                        <img [src]="dropdownIcon.form" class="pr-3" /><span>Form</span>
                    </a>
                </li>
            </ul>
        </div>

        <!-- Overview -->
        <li class="collapsed-side-nav-item" [routerLink]="['/overview']"
            routerLinkActive="activeLink"
            [tooltip]="'Overview'" placement="right"
            (click)="logOverviewClicks()"
            >
            <i class="fa fa-chart-pie fa-md nav-link-icon"></i>
        </li>

        <!-- My Cards -->
        <li *ngIf="isDbcDashboard && dbcNavItemsPermissions.myCards" class="collapsed-side-nav-item"
            routerLinkActive="activeLink" [routerLink]="['/digital-business-cards/my-cards']"
            [queryParams]="{ orgID: currentOrganizationId }" [tooltip]="'My Cards'" placement="right">
            <i class="far fa-address-card fa-md nav-link-icon"></i>
        </li>

        <!-- Organization Cards -->
        <li *ngIf="isDbcDashboard && dbcNavItemsPermissions.orgCards" class="collapsed-side-nav-item"
            routerLinkActive="activeLink" [routerLink]="['/digital-business-cards/team-cards']"
            id="organization-cards-both" [queryParams]="{ orgID: currentOrganizationId }"
            [tooltip]="'Organization Cards'" placement="right">
            <i class="far fa-building fa-md nav-link-icon"></i>
        </li>

        <!-- DBC Analytics -->
        <li *ngIf="isDbcDashboard && dbcNavItemsPermissions.analytics" class="collapsed-side-nav-item"
            [routerLink]="['/analytics/digital-business-cards']" [queryParams]="{ orgID: currentOrganizationId }"
            routerLinkActive="activeLink" [tooltip]="'Analytics'" placement="right">
            <i class="fas fa-chart-bar fa-md nav-link-icon"></i>
        </li>

        <!-- Contact Manager -->
        <li *ngIf="isDbcDashboard" class="collapsed-side-nav-item" routerLinkActive="activeLink"
            [routerLink]="['/contact-manager']" [queryParams]="{ orgID: currentOrganizationId }"
            [tooltip]="'Contact Manager'" placement="right">
            <i class="far fa-address-book fa-md nav-link-icon"></i>
        </li>

        <!-- QR Codes Menu -->
        <div *ngIf="currentDashboard === DASHBOARD_MENU.QRCODES" dropdown placement="right" container="body">
            <li class="collapsed-side-nav-item dropdown-toggle" dropdownToggle aria-controls="dropdown-qr"
                [tooltip]="'QR Codes'" placement="right">
                <i class="fas fas-icon fa-qrcode"></i>
            </li>

            <ul id="dropdown-qr" *dropdownMenu class="dropdown-menu custom-drop-down-menu" role="menu">
                <li *ngIf="qrCreateCtaVariation === 'CREATE_QR_TEXT'" role="menuitem">
                    <a class="dropdown-item" [routerLink]="['/qr-codes/add']"
                        [queryParams]="{ orgID: currentOrganizationId }">
                        Create QR Code
                    </a>
                </li>
                <li role="menuitem">
                    <a class="dropdown-item" [routerLink]="['/qr-codes']"
                        [queryParams]="{ orgID: currentOrganizationId }">
                        {{ qrCreateCtaVariation === 'CREATE_QR_TEXT' ? 'My QR Codes' : 'QR Codes' }}
                    </a>
                </li>

                <li role="menuitem">
                    <a class="dropdown-item" [routerLink]="['/analytics/qr']"
                        [queryParams]="{ orgID: currentOrganizationId }">
                        Analytics
                    </a>
                </li>

                <li role="menuitem" *ngIf="hasTemplatePermission">
                    <a class="dropdown-item" [routerLink]="['/qr-templates']"
                        [queryParams]="{ orgID: currentOrganizationId }">
                        Templates
                        <i *ngIf="isOnTrialPlan" class="fas fa-crown" style="color: #fcbf49; font-size: 12px"></i>
                    </a>
                </li>
            </ul>
        </div>

        <!-- Template for GS1 Tooltip -->
        <ng-template #gs1Tooltip>
            <span>
                GS1 QR Codes
                <i class="fas fa-crown" style="color: #fcbf49; font-size: 12px"></i>
            </span>
        </ng-template>
        <!-- GS1 QR CODES -->
        <div *ngIf="currentDashboard === DASHBOARD_MENU.QRCODES && showGs1Section && accessList.gs1Code" dropdown placement="right" container="body">
            <li *ngIf="userHasGs1Permission" class="collapsed-side-nav-item dropdown-toggle" dropdownToggle aria-controls="dropdown-qr"
                [tooltip]="'GS1 QR Codes'" placement="right">
                <i class="fas fas-icon fa-barcode"></i>
            </li>
            <li *ngIf="!userHasGs1Permission" class="collapsed-side-nav-item" (click)="openUpgradePage('gs1')"
                [tooltip]="gs1Tooltip" placement="right">
                <i class="fas fas-icon fa-barcode"></i>
            </li>

            <ul id="dropdown-qr" *dropdownMenu class="dropdown-menu custom-drop-down-menu" role="menu">
                <li role="menuitem">
                    <a class="dropdown-item" [routerLink]="['/gs1']"
                        [queryParams]="{ orgID: currentOrganizationId }">
                        GS1 QR Codes
                    </a>
                </li>
                <li role="menuitem">
                    <a class="dropdown-item" [routerLink]="['/gs1/analytics']"
                        [queryParams]="{ orgID: currentOrganizationId }">
                        Analytics
                    </a>
                </li>

                <li role="menuitem" *ngIf="hasTemplatePermission">
                    <a class="dropdown-item" [routerLink]="['/gs1/templates']"
                        [queryParams]="{ orgID: currentOrganizationId }">
                        Templates
                        <i *ngIf="isOnTrialPlan" class="fas fa-crown" style="color: #fcbf49; font-size: 12px"></i>
                    </a>
                </li>
            </ul>
        </div>
        <!-- GS1 QR CODES -->

        <!-- Forms -->
        <li *ngIf="currentDashboard === DASHBOARD_MENU.QRCODES && showFormLinkOutsideCampaign" class="collapsed-side-nav-item"
            routerLinkActive="activeLink" [routerLink]="['/forms']"
            [queryParams]="{ orgID: currentOrganizationId }" [tooltip]="'Forms'" placement="right">
            <img alt="form-logo" src="../../assets/logo/form-sidebar-icon.svg" />
        </li>
        <!-- Forms -->

        <!-- Linkpages -->
        <div *ngIf="currentDashboard === DASHBOARD_MENU.QRCODES && accessList.linkpage" dropdown placement="right"
            container="body">
            <li class="collapsed-side-nav-item dropdown-toggle" dropdownToggle aria-controls="dropdown-linkpages"
                [tooltip]="'Linkpages'" placement="right">
                <i class="fas fas fa-list"></i>
            </li>

            <ul id="dropdown-linkpages" *dropdownMenu class="dropdown-menu custom-drop-down-menu" role="menu">
                <li role="menuitem">
                    <a class="dropdown-item" [routerLink]="['/linkpage']"
                        [queryParams]="{ orgID: currentOrganizationId }">
                        Linkpages
                    </a>
                </li>
                <li role="menuitem">
                    <a class="dropdown-item" [routerLink]="['/analytics/linkpage']"
                        [queryParams]="{ orgID: currentOrganizationId }">
                        Analytics
                    </a>
                </li>
                <li role="menuitem" *ngIf="isOwner || isAdmin">
                    <a class="dropdown-item" [routerLink]="['/linkpage-templates']"
                        [queryParams]="{ orgID: currentOrganizationId }">
                        Templates
                    </a>
                </li>
            </ul>
        </div>

        <!-- Campaigns -->
        <div *ngIf="showCampaignsSection"
            dropdown placement="right" container="body">
            <li class="collapsed-side-nav-item dropdown-toggle" dropdownToggle aria-controls="dropdown-campaigns"
                [tooltip]="'Campaigns'" placement="right">
                <i class="fas fas-icon fa-bullhorn"></i>
            </li>

            <ul id="dropdown-campaigns" *dropdownMenu class="dropdown-menu custom-drop-down-menu" role="menu">
                <li role="menuitem" *ngIf="accessList.markdownCard ||  PLAN_TYPES.Starter || PLAN_TYPES.Lite">
                    <a class="dropdown-item" [routerLink]="['/landing-pages']"
                        [queryParams]="{ orgID: currentOrganizationId }">
                        Landing Pages
                        <i *ngIf="isOnTrialPlan" class="fas fa-crown" style="color: #fcbf49; font-size: 12px"></i>
                    </a>
                </li>
                <li role="menuitem" *ngIf="accessList.form && !showFormLinkOutsideCampaign">
                    <a class="dropdown-item" [routerLink]="['/forms']" [queryParams]="{ orgID: currentOrganizationId }">
                        Forms
                    </a>
                </li>
            </ul>
        </div>

        <!-- Asset Library -->
        <li class="collapsed-side-nav-item"
            *ngIf="currentDashboard === DASHBOARD_MENU.QRCODES || (isDbcDashboard && dbcNavItemsPermissions.assetLib)"
            [routerLink]="['/asset-library']" [queryParams]="{ orgID: currentOrganizationId }"
            routerLinkActive="activeLink" [tooltip]="'Asset Library'" placement="right">
            <i class="far fa-folder-open"></i>
        </li>

        <!-- DBC Templates -->
        <div *ngIf="isDbcDashboard && dbcNavItemsPermissions.template" dropdown placement="right" container="body">
            <li class="collapsed-side-nav-item dropdown-toggle" dropdownToggle aria-controls="dropdown-qr"
                [tooltip]="'Template'" placement="right">
                <i class="far fa-images"></i>
            </li>

            <ul id="dropdown-qr" *dropdownMenu class="dropdown-menu custom-drop-down-menu" role="menu">
                <li *ngIf="isAdmin" role="menuitem">
                    <a *ngIf="hasCardsTemplateAccess" class="dropdown-item"
                        [routerLink]="['/digital-business-cards/templates']"
                        [queryParams]="{ orgID: currentOrganizationId }">Business Cards</a>
                    <a *ngIf="!hasCardsTemplateAccess" class="dropdown-item" (click)="showUpsellModel()">Business Cards
                        <img class="pr-2 mb-1" src="../../assets/logo/crown-solid.svg" /></a>
                </li>

                <li *ngIf="hasTemplatePermission" role="menuitem">
                    <a class="dropdown-item" [routerLink]="['/qr-templates']"
                        [queryParams]="{ orgID: currentOrganizationId }">
                        QR Codes
                    </a>
                </li>

                <li *ngIf="isAdmin" role="menuitem">
                    <a class="dropdown-item" [routerLink]="['/wallet-pass-template']"
                        [queryParams]="{ orgID: currentOrganizationId }">
                        Wallet Passes
                    </a>
                </li>
            </ul>
        </div>

        <!-- Settings -->
        <li *ngIf="isDbcDashboard && dbcNavItemsPermissions.settings" class="collapsed-side-nav-item"
            routerLinkActive="activeLink" [routerLink]="['/digital-business-cards/settings']"
            [queryParams]="{ orgID: currentOrganizationId }" [tooltip]="'Settings'" placement="right">
            <i class="far fa-cog fa-md nav-link-icon"></i>
        </li>

        <!-- Integrations -->
        <li class="collapsed-side-nav-item" *ngIf="navBarItems.integrations" [routerLink]="['/integrations']"
            [queryParams]="{ orgID: currentOrganizationId }" routerLinkActive="activeLink" [tooltip]="'Integrations'"
            placement="right">
            <i class="fas fa-network-wired"></i>
        </li>

        <!-- API -->
        <li class="collapsed-side-nav-item" *ngIf="
                (currentDashboard === DASHBOARD_MENU.QRCODES && navBarItems.api) ||
                (isDbcDashboard && dbcNavItemsPermissions.api)
            " [routerLink]="['/api']" [queryParams]="{ orgID: currentOrganizationId }" routerLinkActive="activeLink"
            [tooltip]="'API'" placement="right">
            <i class="fas fas-icon fa-code"></i>
        </li>

        <!-- Logs -->
        <li class="collapsed-side-nav-item" *ngIf="isAdminDashboard" [routerLink]="['/request-logs']"
            [queryParams]="{ orgID: currentOrganizationId }" routerLinkActive="activeLink" [tooltip]="'Logs'"
            placement="right">
            <i class="fas fas-icon fa-history"></i>
        </li>

        <!-- Help Menu -->
        <div dropdown placement="right" container="body">
            <li class="collapsed-side-nav-item dropdown-toggle" dropdownToggle aria-controls="dropdown-help" [tooltip]="'Help'" placement="right">
                <i class="far fa-question-circle"></i>
            </li>

            <ul id="dropdown-help" *dropdownMenu class="dropdown-menu custom-drop-down-menu" role="menu" [ngClass]="currentDashboard === DASHBOARD_MENU.CARDS ? 'custom-position-menuItem' : ''" >
                <li role="menuitem">
                    <a class="dropdown-item" href="javascript:void(0);" (click)="startNewIntercomConversation()">Live
                        support</a>
                </li>
                <li role="menuitem">
                    <a class="dropdown-item" href="javascript:void(0);"
                        (click)="redirectFromHelpSection(HelpMenuOptions.HELP_CENTER)">Help center</a>
                </li>
                <li role="menuitem">
                    <a class="dropdown-item" href="javascript:void(0);"
                        (click)="redirectFromHelpSection(HelpMenuOptions.BLOGS)">Blogs</a>
                </li>
                <li role="menuitem">
                    <a class="dropdown-item" href="javascript:void(0);"
                        (click)="redirectFromHelpSection(HelpMenuOptions.VIDEOS)">Videos</a>
                </li>
                <li role="menuitem">
                    <a class="dropdown-item" href="javascript:void(0);"
                        (click)="redirectFromHelpSection(HelpMenuOptions.WHATS_NEW)">What's new</a>
                </li>
                <li role="menuitem">
                    <a class="dropdown-item" href="javascript:void(0);" (click)="startNewIntercomConversation()">Request
                        a feature</a>
                </li>
            </ul>
        </div>
    </div>

    <!-- Upgrade icon if needed -->
    <div class="bottom-section">
        <li class="pwa-nudge" *ngIf="!isMobileDevice && isDbcDashboard" (click)="handleNudgePopup($event)"
            (keyup)="handleNudgePopup($event)" (keydown)="handleNudgePopup($event)">
            <span class="custom-tooltip">Go Mobile</span>
            <i class="fas fa-mobile-alt fa-icon fa-lg"></i>
        </li>

        <!-- DBC Upgrade button -->
        <li *ngIf="isDbcDashboard && showCardsUpgradeNowButton" (click)="openUpgradePage('sidenav-upgrade-cta')"
            class="trial-upgrade-icon">
            <span class="custom-tooltip">Upgrade Now</span>
            <i class="fas fa-crown"></i>
        </li>

        <!-- QR Upgrade button -->
        <li *ngIf="currentDashboard === DASHBOARD_MENU.QRCODES && showQrUpgradeNowButton"
            (click)="openUpgradePage('sidenav-upgrade-cta')" class="trial-upgrade-icon">
            <span class="custom-tooltip">Upgrade Now</span>
            <i class="fas fa-crown"></i>
        </li>
    </div>
</ul>

<mycards-popup [showModal]="showModal" (closeMycardsPopupEmitter)="closePopupEmitter()"></mycards-popup>
