import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DbcPricingPageComponent } from './dbc-pricing-page.component';
import { AmountContianerComponent } from './amount-contianer/amount-contianer.component';
import { UserSeatInputComponent } from './user-seat-input/user-seat-input.component';
import { PlanDetailCardComponent } from './plan-detail-card/plan-detail-card.component';
import { BeaconstacModule } from 'kaizen-design-system';
import { FormsModule } from '@angular/forms';
import { PlanIncludesComponent } from './plan-includes/plan-includes.component';
import { PlanButtonComponent } from './plan-button/plan-button.component';
import { PlanComparisonComponent } from './plan-comparison/plan-comparison.component';
import { PipesModule } from '../pipes/pipes.module';
import { PageDescriptionComponent } from './page-description/page-description.component';
import { DigitalBusinessCardsService } from '../../digital-business-card/digital-business-cards.service';
import { TooltipModule } from 'ngx-bootstrap/tooltip';


@NgModule({
    declarations: [
        DbcPricingPageComponent,
        AmountContianerComponent,
        UserSeatInputComponent,
        PlanDetailCardComponent,
        PlanIncludesComponent,
        PlanButtonComponent,
        PlanComparisonComponent,
        PageDescriptionComponent
    ],
    exports: [
        DbcPricingPageComponent,
        UserSeatInputComponent
    ],
    imports: [
        CommonModule,
        BeaconstacModule,
        FormsModule,
        PipesModule,
        TooltipModule.forRoot()
    ],
    providers: [
        DigitalBusinessCardsService
    ]
})
export class DbcPricingPageModule { }
