<div class="bx--row my-4 pb-4" *ngIf="pageType === DBC_PRICE_PAGE_TYPE.EXPIRED">
    <div class="bx--col px-4 d-flex flex-column justify-content-center align-items-center">
        <div class="heading mb-3">{{title}}</div>
        <div class="description" [innerHtml]="description | getSanitizedHtml">
        </div>
    </div>
</div>
<div class="bx--row my-4 pb-4 margin-bottom-mobile-size" *ngIf="pageType === DBC_PRICE_PAGE_TYPE.UPGRADE">
    <div class="px-4 bx--col d-flex flex-column justify-content-center align-items-center">
        <div class="heading mb-3 mobile-heading-font-size">{{title}}</div>
    </div>
</div>
