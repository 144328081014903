<div class="d-md-block d-none">
    <div class="bx--grid grid-container bg-white">
        <div class="bx--row desktop-plans-container-border" [ngClass]="pageType === DBC_PRICE_PAGE_TYPE.UPGRADE ? 'position-sticky-upgrade': 'position-sticky-expired'">
            <div class="bx--col desktop-first-column-plan-container"></div>
            <div class="bx--col desktop-common-column-plan-container">
                <div class="freemium-plan-label-container">
                    <span class="freemium-plan-label-heading"> FREE </span>
                </div>
                <div class="plan-label-subtext-container">
                    <span class="plan-label-subtext">${{DBC_PRICE_RATES.DBCFreemium}} for one user</span>
                </div>
            </div>
            <div class="bx--col desktop-common-column-plan-container">
                <div class="nteam-plan-label-container">
                    <span class="nteam-plan-label-heading"> TEAM </span>
                </div>
                <div class="plan-label-subtext-container">
                    <span class="plan-label-subtext">${{DBC_PRICE_RATES.DBCNTeam}}/user/month</span>
                </div>
            </div>
            <div class="bx--col desktop-common-column-plan-container">
                <div class="business-plus-plan-label-container">
                    <span class="business-plus-plan-label-heading"> BUSINESS+ </span>
                </div>
                <div class="plan-label-subtext-container">
                    <span class="plan-label-subtext">Talk to Sales</span>
                </div>
            </div>
        </div>
        <div *ngFor="let category of DBC_FEATURE_DETAILS_DATA_NEW">
            <div class="bx--row desktop-category-container-row" [ngClass]="{'border-bottom-class': category.categoryName === 'Customer support'}">
                <div
                    class="bx--col desktop-category-container-column"
                >
                    <div>
                        <span class="mr-2">
                            <img [src]="category?.svgImage" alt="Icon" />
                        </span>
                        <span class="desktop-category-name-text">{{
                            category?.categoryName
                        }}</span>
                    </div>
                    <div class="text-right cursor-pointer">
                        <span
                            *ngIf="!categoryToggleStates[category.categoryName]"
                            (click)="categoryToggleStates[category.categoryName] = true;"
                        >
                            <img src="../../../../assets/img/dbc-pricing-page/chevron-down.svg" alt="Icon" />
                        </span>
                        <span
                            *ngIf="categoryToggleStates[category.categoryName]"
                            (click)="categoryToggleStates[category.categoryName] = false;"
                        >
                            <img src="../../../../assets/img/dbc-pricing-page/chevron-up.svg" alt="Icon" />
                        </span>
                    </div>
                </div>
            </div>
            <div *ngIf="categoryToggleStates[category.categoryName]">
                <div class="bx--row desktop-category-data-container-border" *ngFor="let categoryData of category.categoryData">
                    <div class="bx--col desktop-first-column-category-data-container">
                        <span class="desktop-category-data-type">
                            {{ categoryData.type }}
                        </span>
                        <br />
                        <span class="desktop-category-data-description">
                            {{ categoryData.description }}
                        </span>
                    </div>
                    <ng-container *ngFor="let item of categoryData.plans">
                        <div
                            class="bx--col d-flex desktop-category-data-plan-comparison"
                        >
                            <div *ngIf="item?.value === 'true'; else notSupportedBlock">
                                <i class="tick-mark-color fas fa-check fa-lg"></i>
                            </div>
                            <ng-template #notSupportedBlock>
                                <div *ngIf="item?.value === ''; else showValueBlock">
                                    <img src="../../../../assets/img/dbc-pricing-page/X.svg" alt="Cross Icon" />
                                </div>
                                <ng-template #showValueBlock>
                                    <span class="category-data-has-value-block-color">
                                        {{ item?.value }}
                                    </span>
                                </ng-template>
                            </ng-template>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- for mobile screens pricing comparison -->

<div class="d-md-none">
    <div class="bx--grid grid-container bg-white dbc-plan-comparison-page-padding">
        <div class="bx--row" [ngClass]="pageType === DBC_PRICE_PAGE_TYPE.UPGRADE ? 'position-sticky-upgrade': 'position-sticky-expired'">
            <div class="bx--col mobile-column-common-plan-container">
                <div class="freemium-plan-label-container-mobile">
                    <span class="freemium-plan-label-heading-mobile"> FREE </span>
                </div>
                <div class="plan-label-subtext-container">
                    <span class="plan-label-subtext-mobile"><b>${{DBC_PRICE_RATES.DBCFreemium}}</b> <form action="" method="get"></form> one user</span>
                </div>
            </div>
            <div class="bx--col mobile-column-common-plan-container">
                <div class="nteam-plan-label-container-mobile">
                    <span class="nteam-plan-label-heading-mobile"> TEAM </span>
                </div>
                <div class="plan-label-subtext-container">
                    <span class="plan-label-subtext-mobile"><b>${{DBC_PRICE_RATES.DBCNTeam}}</b>/user/mo</span>
                </div>
            </div>
            <div class="bx--col mobile-column-common-plan-container mobile-column-business-plus-padding">
                <div class="business-plus-plan-label-container-mobile">
                    <span class="business-plus-plan-label-heading-mobile"> BUSINESS+ </span>
                </div>
                <div class="plan-label-subtext-container">
                    <span class="plan-label-subtext-mobile"><b>Talk to Sales</b></span>
                </div>
            </div>
        </div>
        <div *ngFor="let category of DBC_FEATURE_DETAILS_DATA_NEW">
            <div class="bx--row p-0">
                <div
                    class="bx--col mobile-category-container-column"
                >
                    <div class="d-flex">
                        <div>
                            <span class="mr-2">
                                <img [src]="category?.svgImage" alt="Icon" />
                            </span>
                        </div>
                        <div>
                            <span class="mobile-category-name-text">{{
                                category?.categoryName
                            }}</span>
                        </div>
                    </div>
                    <div class="text-right cursor-pointer">
                        <span
                            *ngIf="!categoryToggleStates[category.categoryName]"
                            (click)="categoryToggleStates[category.categoryName] = true;"
                        >
                            <img src="../../../../assets/img/dbc-pricing-page/chevron-down.svg" alt="Icon" />
                        </span>
                        <span
                            *ngIf="categoryToggleStates[category.categoryName]"
                            (click)="categoryToggleStates[category.categoryName] = false;"
                        >
                            <img src="../../../../assets/img/dbc-pricing-page/chevron-up.svg" alt="Icon" />
                        </span>
                    </div>
                </div>
            </div>
            <hr class="mobile-category-separator">
            <div *ngIf="categoryToggleStates[category.categoryName]" [ngClass]="{'customer-support-mobile-padding': category.categoryName === 'Customer support'}">
                <div class="bx--row" *ngFor="let categoryData of category.categoryData">
                    <div class="bx--col mobile-category-data-container">
                        <div class="bx--row">
                            <div class="bx--col mobile-category-data-type-container">
                                <span class="mobile-category-data-type-heading">
                                    {{ categoryData.mobileType }}
                                </span>
                            </div>
                            <ng-template #popTooltipForCategoryDescription>
                                <div class="custom-tooltip-class">
                                  {{ categoryData.description }}
                                </div>
                              </ng-template>
                            <div *ngIf="categoryData.mobileType !== 'Number of users'" class="bx---col mobile-tooltip-container-border">
                                <span
                                  class="tooltip-button fal fa-info-circle"
                                  [tooltip]="popTooltipForCategoryDescription"
                                  placement="auto"
                                  triggers="hover"
                                  container="body">
                                </span>
                            </div>
                        </div>
                        <div class="bx--row m-0">
                            <ng-container *ngFor="let item of categoryData.plans">
                                <div
                                    class="bx--col d-flex mobile-category-data-plan-comparison"
                                >
                                    <div *ngIf="item?.value === 'true'; else notSupportedBlock">
                                        <i class="tick-mark-color fas fa-check fa-lg"></i>
                                    </div>
                                    <ng-template #notSupportedBlock>
                                        <div *ngIf="item?.value === ''; else showValueBlock">
                                            <img src="../../../../assets/img/dbc-pricing-page/X.svg" alt="Cross Icon" />
                                        </div>
                                        <ng-template #showValueBlock>
                                            <span class="category-data-has-value-block-color">
                                                {{ item?.value }}
                                            </span>
                                        </ng-template>
                                    </ng-template>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
