<div class="counter-box d-flex flex-column justify-content-center align-items-center">
    <div class="counter-container d-flex justify-content-center align-items-center">
        <div class="counter-content d-flex">
            <div class="increment-decrement-button-planspage" id="decrement-button"
                 (mousedown)="onMouseDown(MOUSE_CLICK_ACTION.DECREMENT)"
                 (mouseup)="onMouseUp()"
                 (mouseleave)="onMouseUp()"
                 (touchstart)="onMouseDown(MOUSE_CLICK_ACTION.DECREMENT)"
                 (touchend)="onMouseUp()"
                 (touchcancel)="onMouseUp()">
                <beaconstac-button
                    [type]="BUTTON_TYPES.SECONDARY"
                    [disabled]="minUsersToShow === userSeatToBuyCount"
                    [style] = "BUTTON_STYLES.STROKE"
                    [icon]="'fas fa-minus'">
                </beaconstac-button>
            </div>
            <input class="counter" id="user-seats-counter" type="number"
                   name="counterInput" [min]="1" [(ngModel)]="userSeatToBuyCount"
                   (input)="setUserSeatChange($event?.target?.value)"
                   (blur)="onInputBlur()">
            <div class="increment-decrement-button-planspage" id="increment-button"
                 (mousedown)="onMouseDown(MOUSE_CLICK_ACTION.INCREMENT)"
                 (mouseup)="onMouseUp()"
                 (mouseleave)="onMouseUp()"
                 (touchstart)="onMouseDown(MOUSE_CLICK_ACTION.INCREMENT)"
                 (touchend)="onMouseUp()"
                 (touchcancel)="onMouseUp()">
                <beaconstac-button
                    [type]="BUTTON_TYPES.SECONDARY"
                    [disabled]="maxUsersToShow === userSeatToBuyCount"
                    [style] = "BUTTON_STYLES.STROKE"
                    [icon]="'fas fa-plus'">
                </beaconstac-button>
            </div>
        </div>
        <div class="users-text pl-2">
            users
        </div>
    </div>
</div>
