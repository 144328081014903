import {BaseModel} from '../../global-services/base.model';
import {environment} from '../../../environments/environment';
import { socialMediaNamesArray } from 'app/shared/social-media';

export const CategoryIcons = [
    `${environment.imagesEndpoint}restaurantmenu/breakfast.png`,
    `${environment.imagesEndpoint}restaurantmenu/lunch.png`,
    `${environment.imagesEndpoint}restaurantmenu/dinner.png`,
    `${environment.imagesEndpoint}restaurantmenu/barbecue.png`,
    `${environment.imagesEndpoint}restaurantmenu/brunch.png`,
    `${environment.imagesEndpoint}restaurantmenu/hot_dog.png`,
    `${environment.imagesEndpoint}restaurantmenu/pizza.png`,
    `${environment.imagesEndpoint}restaurantmenu/salad.png`,
    `${environment.imagesEndpoint}restaurantmenu/sandwich.png`,
    `${environment.imagesEndpoint}restaurantmenu/tacos.png`,
    `${environment.imagesEndpoint}restaurantmenu/takeaway.png`,
    `${environment.imagesEndpoint}restaurantmenu/balloon.png`,
    `${environment.imagesEndpoint}restaurantmenu/juice.png`,
    `${environment.imagesEndpoint}restaurantmenu/beer.png`,
    `${environment.imagesEndpoint}restaurantmenu/beer_bottle.png`,
    `${environment.imagesEndpoint}restaurantmenu/wine_club.png`,
    `${environment.imagesEndpoint}restaurantmenu/wine_glass.png`,
    `${environment.imagesEndpoint}restaurantmenu/party.png`,
    `${environment.imagesEndpoint}restaurantmenu/party_glitter.png`,
    `${environment.imagesEndpoint}restaurantmenu/coffee.png`,
    `${environment.imagesEndpoint}restaurantmenu/coffee_mug.png  `,
    `${environment.imagesEndpoint}restaurantmenu/coffee_cup.png`,
    `${environment.imagesEndpoint}restaurantmenu/discount.png`,
    `${environment.imagesEndpoint}restaurantmenu/icecream.png`,
    `${environment.imagesEndpoint}restaurantmenu/icecream_cone.png`,
    `${environment.imagesEndpoint}restaurantmenu/icecream_stick.png`,
    `${environment.imagesEndpoint}restaurantmenu/merchandise.png`,
    `${environment.imagesEndpoint}restaurantmenu/sushi-1.png`,
    `${environment.imagesEndpoint}restaurantmenu/sushi-2.png`,
];


export const BackgroundImages = [
    `${environment.imagesEndpoint}/restaurantmenu/background_1.png`,
    `${environment.imagesEndpoint}/restaurantmenu/background_2.png`,
    `${environment.imagesEndpoint}/restaurantmenu/background_3.png`,
    `${environment.imagesEndpoint}/restaurantmenu/background_4.png`,
    `${environment.imagesEndpoint}/restaurantmenu/background_5.png`,
];

export const CategoryWeekDay = [
    {
        id: 0,
        day: 'S'
    },
    {
        id: 1,
        day: 'M'
    },
    {
        id: 2,
        day: 'T'
    },
    {
        id: 3,
        day: 'W'
    },
    {
        id: 4,
        day: 'T'
    },
    {
        id: 5,
        day: 'F'
    },
    {
        id: 6,
        day: 'S'
    },
]

const CategoryParameters = {
    all_week: true,
    start_date: '',
    end_date: '',
    week_array: [0, 0, 0, 0, 0, 0, 0],
    has_custom_icon: false,
    is_category_scheduled: false
}

export class RestaurantMenu extends BaseModel {
    heading: string = '';
    subheading: string = '';
    custom_text: string = '';
    background_image: string = BackgroundImages[0];
    logo_image: string = '';
    font: string = 'Poppins';
    time_based_menu: boolean = false;
    social_links: {
        facebook: string,
        instagram: string,
        twitter: string,
        linkedin: string,
        tiktok: string,
        snapchat: string,
        whatsapp: string,
        telegram: string,
        vimeo: string,
        youtube: string,
        wistia: string,
        twitch: string,
        discord: string,
        pinterest: string,
        yelp: string,
        paypal: string,
        venmo: string,
        cashapp: string,
        calendly: string,
        shopify: string,
        github: string,
        dribbble: string,
        behance: string,
        custom_url: string
    } =
            {
                facebook: '',
                instagram: '',
                twitter: '',
                linkedin: '',
                tiktok: '',
                snapchat: '',
                whatsapp: '',
                telegram: '',
                vimeo: '',
                youtube: '',
                wistia: '',
                twitch: '',
                discord: '',
                pinterest: '',
                yelp: '',
                paypal: '',
                venmo: '',
                cashapp: '',
                calendly: '',
                shopify: '',
                github: '',
                dribbble: '',
                behance: '',
                custom_url: '',
            };
    categories: Array<Category> = [
        new Category({
            title: 'Breakfast',
            start_time: '07:00 am',
            end_time: '11:00 am',
            has_availability_hours: true,
            icon_url: CategoryIcons[0],
            all_week: true,
            ...CategoryParameters
        }),
        new Category({
            title: 'Lunch',
            start_time: '12:00 pm',
            end_time: '03:00 pm',
            has_availability_hours: true,
            icon_url: CategoryIcons[1],
            ...CategoryParameters
        }),
        new Category({
            title: 'Dinner',
            start_time: '06:00 pm',
            end_time: '11:00 pm',
            has_availability_hours: true,
            icon_url: CategoryIcons[2],
            ...CategoryParameters
        })
    ];

    constructor(json?: any) {
        super();
        if (!json) {
            return;
        }
        this.heading = json['heading'];
        this.subheading = json['subheading'];
        this.custom_text = json['custom_text'] || '';
        this.background_image = json['background_image'];
        this.logo_image = json['logo_image'];
        this.font = json['font'];
        this.time_based_menu = json['time_based_menu'];
        this.social_links = json['social_links'] ? json['social_links'] : this.social_links;
        if (json['categories']) {
            this.categories = json['categories'].map(c => new Category(c));
        }
    }

    // tslint:disable-next-line:member-ordering
    static getRestaurantMenuPreviewHTML(restaurantMenu: RestaurantMenu, allDayText, isMobile: boolean) {
        let hasSocialLinks = false;

        let html = `<!DOCTYPE html>
<html dir="ltr" mozdisallowselectionprint>
<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=0.5, maximum-scale=1">
    <meta name="google" content="notranslate">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <title>${restaurantMenu.heading}</title>`;

        if (restaurantMenu.logo_image) {
            html += `<link rel="icon" type="image/x-icon" href="${restaurantMenu.logo_image}" id="fav_icon"/>
          <link rel="shortcut icon" type="image/x-icon" href="${restaurantMenu.logo_image}"/>`
        } else {
            html += `<link rel="icon" type="image/x-icon" href="https://d3nvy39jvu7woe.cloudfront.net/static/images/favicon.ico"
                id="fav_icon"/>
          <link rel="shortcut icon" type="image/x-icon"
                href="https://d3nvy39jvu7woe.cloudfront.net/static/images/favicon.ico"/>`
        }
        if (restaurantMenu.font) {
            html += `<link href="https://fonts.googleapis.com/css?family=${restaurantMenu.font}:wght@100;400;600&display=swap"
                rel="stylesheet">`;
        }
        html += `
</head>

<body class="no-scrollbar" tabindex="1" class="loadingInProgress" style="margin: 0; background-color: rgb(64, 64, 64); height: 100vh">
<style>`;

        if (restaurantMenu.font) {
            html += `body {
                font-family: '${restaurantMenu.font}', sans-serif;
            }`;
        }
        html += `
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }
    .no-scrollbar {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
    .branding-container {
        background: url(${environment.imagesEndpoint}restaurantmenu/texture.png), linear-gradient(rgba(82, 82, 82, 0.99), rgba(69, 69, 69, 0.95)) ! important;
        color: #d9d9d9;
    }

    .d-none {
        display: none !important;
    }

    .pdfViewer {
        margin-bottom: 20px;
        min-height: calc(100vh - 94px);
    }

    @media screen and (-webkit-min-device-pixel-ratio: 1.1), screen and (min-resolution: 1.1dppx) {
        .toolbarButton.nextFile::before, .secondaryToolbarButton.nextFile::before {
            content: url(/static/pdfviewer/web/images/findbarButton-next@2x.png);
        }

        .toolbarButton.previousFile::before, .secondaryToolbarButton.previousFile::before {
            content: url(/static/pdfviewer/web/images/findbarButton-previous@2x.png);
        }
    }

    .top-message-text {
        position: absolute;
        z-index: 11111;
        background: #fff;
        width: calc(100% - 20px);
        text-align: center;
        padding: 12px 10px;
        box-shadow: 0 1px 1px 1px #c5c5c5;
    }
    .custom-text {
      bottom: 0;
      position: absolute;
      background: url(/static/pdfviewer/web/images/texture.png), linear-gradient(rgba(82, 82, 82, 0.99), rgba(69, 69, 69, 0.95)) ! important;
      color: #d9d9d9;
      font-family: inherit;
      box-shadow: -1px -1px 5px 1px rgb(0 0 0 / 20%);
      display: block;
      font-size: 10px;
      left: 0;
      right: 0;
      padding: 10px 0 10px 20px;
      }
    .menu-list-container {
        min-height: calc(100% - 40px);
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        max-width: 500px;
        padding: 20px;
    }

    .dashboard-zoom {
        zoom: ${isMobile ? '0.75' : '0.60'} ;
    }

    .menu-list-container::after {
        content: "";
        background: url("${restaurantMenu.background_image}");
        opacity: 1;
        filter: brightness(0.7);
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;
        background-size: cover;
    }

    .menu-list-container .item {
        padding: 10px 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        border-bottom: 1px dashed #AAAAAA;
    }

    .items-container .item:first-child {
        padding-top: 0;
    }

    .items-container .item:last-child {
        padding-bottom: 0;
        border-bottom: none;

    }

    .menu-list-container .item .icon {
        width: 60px;
        margin-right: 10px;
    }

    .menu-list-container .item .title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
        font-size: 18px;
        margin-bottom: 5px;
        color: #0B2031;
    }

    .menu-list-container .item .timing, .menu-list-container .item .alcohol {
        font-size: 14px;
        max-width: 100%;
        color: #95949E;
        font-weight: 400;
    }

    .menu-list-container .item .timing img, .menu-list-container .item .alcohol img {
        width: 15px;
        vertical-align: middle;
    }

    #back-button {
        position: absolute;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        width: 16px;
        height: 16px;
        z-index: 1111;
        border-radius: 50%;
        top: 20px;
        left: 20px;
        box-shadow: 0 0 2px 1px #e9e9e9;
        cursor: pointer;
    }

    .items-container {
        padding: 20px;
        background: #fff;
        max-height: 60vh;
        overflow: scroll;
        bottom: 20px;
        left: auto;
        right: auto;
        width: calc(100% - 40px);
        box-shadow: 0 0 2px 0 #e4e4e4;
        box-sizing: content-box;
        border-radius: 8px;
        margin-top: 30px;
    }
    .items-container::-webkit-scrollbar {
        display: none;
    }
    .restaurant-logo-container {
        align-self: flex-start;
        margin-bottom: 10px;
        border-radius: 50%;
        overflow: hidden;
        box-shadow: #ccc 0 0 2px 1px;
    }

    .restaurant-logo-container .logo {
        height: 60px;
        vertical-align: middle;
    }

    .restaurant-detail {
        align-self: flex-start;
        color: #ffffff;
    }

    .restaurant-detail .name {
        font-size: 42px;
        font-weight: 600;
    }

    .word-break {
        overflow: hidden;
        word-break: break-word;
    }

    .restaurant-detail .headline {
        font-size: 18px;
        max-width: 100%;
    }

    a {
        color: inherit !important;
        text-decoration: none;
    }

    .social-heading{
      font-size: 24px;
      max-width: 100%;
      color: #ffffff;
      font-weight: 600;
      padding-top: 20px;
      padding-bottom: 15px;
    }

    .button_icon_container {
      display: grid;
      grid-template-columns: auto auto auto auto;
      padding: 0px 15px;
      justify-content: center;
    }

    .grid-item{
      padding: 5px
    }
</style>`;
        html += `
<div id="menu-list" class="menu-list-container dashboard-zoom">
    <div class="restaurant-logo-container">`;

        html += `<img class="logo" src="${restaurantMenu.logo_image || environment.imagesEndpoint + 'restaurantmenu/dummy_logo.svg'}">`;

        html += `
    </div>
    <div class="restaurant-detail">
        <div class="name word-break">${restaurantMenu.heading || 'Best in town'}</div>`;
        html += `<div class="headline">${restaurantMenu.subheading || 'Enjoy the best food in town'}</div>`;
        html += `
    </div>
    <div class="items-container">`;
        for (const item of restaurantMenu.categories) {
            html += `<a class="item" href="#${item.title}">
              <img class="icon" src="${item.icon_url}">
              <div>
                  <div class="title">${item.title}</div>`;
            if (!item.all_day) {
                html += `<div class="timing">
                            <img src="${environment.imagesEndpoint}restaurantmenu/time.png">
                            ${item.start_time} - ${item.end_time}</div>`;
            } else {
                html += `<div class="timing"><img src="${environment.imagesEndpoint}restaurantmenu/time.png"> ${allDayText}</div>`;
            }
            if (item.serves_alcohol) {
                html += `<div class="alcohol">
        <img src="${environment.imagesEndpoint}restaurantmenu/wine.png">
          Serves alcohol
        </div>`;
            }
            html +=
        `</div>
        </a>`
        }
        html += '</div>';

        let socialMediaIcons = ''
        socialMediaNamesArray.forEach(item => {
            const imgLink = item;
            if ( restaurantMenu.social_links[item] ) {
                hasSocialLinks = true;
                socialMediaIcons +=
        `<a class="grid-item">
            <img src="${ environment.imagesEndpoint }/vcard_plus/social_link_icons/${imgLink}_icon.png" class="social-logo">
        </a>`
            }
        })

        if ( hasSocialLinks){
            html +=
      `<div class="social-heading">Connect with us on</div>
        <div class="button_icon_container" style="margin-bottom: 4rem;">
          ${socialMediaIcons}
        </div>
      </div>`
        }

        if (restaurantMenu.custom_text !== '') {
            html += `<div class="custom-text">
        <a>${restaurantMenu.custom_text}</a>
      </div>`;
        }
        html += `</div>

</body>
</html>
`;

        return html;
    }
}

export class Category {
    id: string = '';
    title: string = '';
    files: Array<{ url: string, name: string }> = [];
    // merged_url: string = '';
    start_time: string;
    end_time: string;
    all_day: boolean = false;
    serves_alcohol: boolean = false;
    icon_url: string = CategoryIcons[0];
    all_week: boolean = false;
    start_date: string = '';
    end_date: string = '';
    week_array: Array<number> = [0, 0, 0, 0, 0, 0, 0];
    has_custom_icon: boolean = false
    is_category_scheduled: boolean = false

    constructor(json?: any) {
        if (!json) {
            return;
        }
        this.id = json['id'] || this.generateUniqueID(6);
        this.title = json['title'];
        this.files = json['files'] || [];
        // this.merged_url = json['merged_url'];
        this.start_time = json['start_time'];
        this.end_time = json['end_time'];
        this.all_day = json['all_day'] || false;
        this.serves_alcohol = json['serves_alcohol'] || false;
        this.icon_url = json['icon_url'] || '';
        this.all_week = json['all_week'] || false;
        this.start_date = json['start_date'] || '';
        this.end_date = json['end_date'] || '';
        this.week_array = json['week_array'] || [0, 0, 0, 0, 0, 0, 0];
        this.has_custom_icon = json['has_custom_icon'] || false;
        this.is_category_scheduled = json['is_category_scheduled'] || false
    }

    generateUniqueID(length: number): string {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_-';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }


}
