export enum FEATURE_FLAGS {
    QR_MULTI_LINK_QR_CODES = 'qr-multi-link-qr-codes',
    QR_CREATE_QR_CODES_CTA = 'qr-create-cta',
    QR_ANALYTICS_BANNER =  'qr-analytics-banner',
    QR_PRICING_SHOW_STARTER_PLAN = 'qr-pricing-show-starter-plan',
    QR_TRIAL_FIRST_FINISH_PLACEMENT = 'qr-trial-first-finish-placement',
    QR_WEBSITE_NAME_CHANGE = 'qr-website-name-change',
    QR_LIST_ALL_ORG_OBJECTS = 'all-organization-objects',
    QR_COLLAPSIBLE_SIDE_NAV = 'qr-collapsible-side-nav',
    GET_MORE_CODES_CTA = 'get-more-codes-cta',
    QR_ANALYTICS_EXPORT_OPTIMIZED_LAMBDA = 'qr-analytics-export-optimized-lambda',
    QR_REDIRECT_CAMPAIGN_TYPE_SELECTION = 'qr-redirect-campaign-type-selection',
    QR_MASK_STARTER_PLAN = 'qr-mask-starter-plan',

    // DBC flags
    VIRTUAL_BACKGROUND = 'virtual-background',
    DBC_EMAIL_SIGNATURE = 'email-signature',
    DBC_ACTIVATION_EXPERIMENT_1 = 'dbc-activation-experiment-1',
    NEW_DBC_ONBOARDING = 'new-dbc-onboarding',
    DBC_LEAD_FOLLOW_UP_EMAIL = 'dbc-lead-follow-up-email',
    MULTI_LANG_LOGO_RESIZE = 'multi-lang-logo-resize',

    // Forms flags
    FORMS_MIGRATE_TO_KAIZEN = 'forms-migrate-to-kaizen',
    FORMS_RESPONSE_TO_KAIZEN = 'forms-response-to-kaizen',
    FORMS_TEMPLATE_TO_KAIZEN = 'forms-template-to-kaizen',
    FORMS_EMPTY_STATE = 'forms-empty-state',
    FORMS_EMPTY_STATE_VERSION = 'forms-empty-state-version',
    FORMS_LINK_IN_SIDEBAR = 'forms-link-in-sidebar',
    FORMS_SHARING_EXPERIENCE = 'forms-sharing-experience',

    // AI Analytics Summary flags
    QR_AI_ANALYTICS = 'qr-ai-analytics-cta',
    QR_AI_ANALYTICS_OPT_OUT_SECTION = 'qr-ai-analytics-opt-out-flow',

    // SCIM integrations flags
    OKTA_SCIM_INTEGRATION = 'okta-scim-itegration',
    SCIM_COUNTRY_MAPPING = 'dbc-scim-country-mapping',

    // GS1 Code
    GS1_CODE = 'gs1-support'
}
