import {Injectable} from '@angular/core';
import {NgxIndexedDBService} from 'ngx-indexed-db';
import {Observable, from, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class DbcIndexedDbService {
    constructor(private readonly ngxIndexedDBService: NgxIndexedDBService) {}

    /**
     * Checks if the IndexedDB database exists.
     * @returns Observable<boolean>
     */
    private checkIfDbExists(): Observable<boolean> {
        if (!indexedDB.databases) {
            return of(false);
        }

        return from(indexedDB.databases()).pipe(
            switchMap((dbList) => of(dbList.some((db) => db.name === 'onboarding-dbc')))
        );
    }


    /**
     * Retrieves a value from the specified IndexedDB store by key.
     * @param storeName - The name of the store.
     * @param key - The key to retrieve the value for.
     * @returns Observable<any>
     */
    getKeyValue(storeName: string, key: string): Observable<any> {
        return this.checkIfDbExists().pipe(
            switchMap((dbExists) =>
                dbExists
                    ? this.ngxIndexedDBService.getByKey<{ value: any }>(storeName, key).pipe(
                        map((result) => result?.value ?? null)
                    )
                    : of(null)
            )
        );
    }


    /**
     * Stores a key-value pair in the specified IndexedDB store.
     * @param storeName - The name of the store.
     * @param key - The key to store.
     * @param value - The value associated with the key.
     * @returns Observable<any>
     */
    setKeyValue(storeName: string, key: string, value: any): Observable<any> {
        return this.getKeyValue(storeName, key).pipe(
            switchMap(existingValue => {
                if (existingValue !== undefined && existingValue !== null) {
                    return this.ngxIndexedDBService.update(storeName, { key, value });
                } else {
                    return this.ngxIndexedDBService.add(storeName, { key, value });
                }
            })
        );
    }


    /**
     * Deletes a key-value pair from the specified IndexedDB store.
     * @param storeName - The name of the store.
     * @param key - The key to delete.
     * @returns Observable<any>
     */
    deleteKey(storeName: string, key: string): Observable<any> {
        return this.ngxIndexedDBService.delete(storeName, key);
    }


    /**
     * Retrieves all records from the specified object store.
     * @param storeName - The name of the object store.
     */
    getAllEntries(storeName: string) {
        return this.checkIfDbExists().pipe(
            switchMap((dbExists) =>
                dbExists ? this.ngxIndexedDBService.getAll(storeName) : of([])
            )
        );
    }


    /**
     * Clears all key-value pairs in the specified IndexedDB store.
     * @param storeName The name of the store to clear.
     * @returns Observable<any>
     */
    clearStore(storeName: string): Observable<any> {
        return this.checkIfDbExists().pipe(
            switchMap((dbExists) =>
                dbExists ? this.ngxIndexedDBService.clear(storeName) : of(null)
            )
        );
    }
}
