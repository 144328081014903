<beaconstac-button *ngIf="buttonToShow === BUTTON_TO_SHOW.CURRENT_PLAN"
                    [label]="plan===PLAN_TYPES.DBCFreemium ? 'CURRENT PLAN' : 'ADD MORE USER SEATS'"
                    [type]="plan===PLAN_TYPES.DBCFreemium ? BUTTON_TYPES.SECONDARY : BUTTON_TYPES.PRIMARY"
                    [style]="plan===PLAN_TYPES.DBCFreemium ? BUTTON_STYLES.FILLED  : BUTTON_STYLES.PRIMARY"
                    [width]="'100%'"
                   (onClick)="plan!==PLAN_TYPES.DBCFreemium ? onAddMoreUserSeatsClick() : null"
></beaconstac-button>

<beaconstac-button *ngIf="buttonToShow === BUTTON_TO_SHOW.SCHEDULE_DEMO"
                   [label]="'SCHEDULE DEMO'"
                   [type]="BUTTON_TYPES.PRIMARY"
                   [style]="BUTTON_STYLES.STROKE"
                   [width]="'100%'"
                   (onClick)="onTalkToSalesClick()"
></beaconstac-button>

<beaconstac-button *ngIf="buttonToShow === BUTTON_TO_SHOW.BUY_PLAN"
                   [label]="'BUY NOW'"
                   [type]="BUTTON_TYPES.PRIMARY"
                   [style]="BUTTON_STYLES.PRIMARY"
                   [width]="'100%'"
                   (onClick)="onBuyPlanClick()"
></beaconstac-button>
