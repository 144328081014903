import { Injectable } from '@angular/core';

declare let gtag: Function;

export interface GTMDatalayerVars {
    event: GTM_ENAME;

    account_id: number;

    trial_start: boolean;
    trial_upgrade: boolean;
    direct_purchase: boolean;

    product_line: string;
    plan_name: string;
    plan_acv: number;

    customer_id: string;
    transactor_hashed_email: string;

    schedule_demo: boolean;
}

export enum GTM_ENAME {
    PostSignup = 'PostSignup',
    PlanPurchase = 'PlanPurchase',
}

@Injectable({
    providedIn: 'root',
})
export class GoogleAnalyticsService {
    constructor() {}

    public eventEmitter(
        eventName: string,
        eventCategory: string = null,
        eventAction: string = null,
        eventLabel: string = null,
        eventValue: number = null,
    ) {
        gtag('event', eventName, {
            eventCategory: eventCategory,
            eventLabel: eventLabel,
            eventAction: eventAction,
            eventValue: eventValue,
        });
    }
}
