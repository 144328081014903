import {Component, Input} from '@angular/core';
import {DBC_PLANS_FEATURES_DATA, PLAN_TYPES} from '../../../user-account/user.model';
import {DBC_PLANS_BREIF_DATA} from '../dbc-plan-data';

@Component({
    selector: 'app-dbc-plan-includes',
    templateUrl: './plan-includes.component.html',
    styleUrls: ['./plan-includes.component.scss']
})
export class PlanIncludesComponent {
    @Input() plan: PLAN_TYPES;
    DBC_PLANS_BREIF_DATA = DBC_PLANS_BREIF_DATA;

    protected readonly DBC_PLANS_FEATURES_DATA = DBC_PLANS_FEATURES_DATA;
}
