import {
    CustomizationAttributes, getLayoutCustomizations
} from '../digital-business-card/templates-utils';

export enum DBC_ONBOARD_STEPS {
    COMPANY_INFO,
    CONTACT_INFO,
    PROFILE_PIC_INFO,
    DESIGN_INFO,
    SHARE_SCREEN
}

export enum FORM_INPUT_FIELDS {
    COMPANY_NAME = 'Company Name',
    JOB_TITLE = 'Job Title',
    PHONE_NUMBER = 'Phone Number',
}

export const ONBOARDING_LAYOUTS_LIST: Array<string> = ['1', '4', '5', '6'];

type LayoutId = typeof ONBOARDING_LAYOUTS_LIST[number];

/**
 * Customizations for each layout in the onboarding process
 * @type {Record<LayoutId, CustomizationAttributes>}
 * @see CustomizationAttributes
 * **/
export const ONBOARDING_LAYOUTS_CUSTOMIZATIONS: Record<LayoutId, CustomizationAttributes> = {
    '1': {
        ...getLayoutCustomizations('1')
    },
    '3': {
        ...getLayoutCustomizations('3'),
    },
    '4': {
        ...getLayoutCustomizations('4'),
    },
    '5': {
        ...getLayoutCustomizations('5'),
    },
    '6': {
        ...getLayoutCustomizations('6'),
    },
};

export enum ONBOARD_NAVIGATION_SOURCES {
    CONTINUE_CTA = 'continue_cta',
    BACK_CTA = 'back_cta',
    SKIP_CTA = 'skip_cta'
}

export enum MOBILE_BOTTOM_CTA {
    CONTINUE = 'Continue',
    SHARE_CARD = 'Share Card',
    BACK = 'Back',
    CONTINUE_EDITING = 'Continue Editing'
}

export enum DBC_ONBOARDING_URLS {
    TRIAL_SIGNUP = '/digital-business-cards/onboard?TrialSignUp=true',
    ONBOARD_V2 = '/digital-business-cards/onboarding',
}

export function reduceLogoSize(logo_size: number, layout: string): number {
    if (layout === '1') {
        return logo_size - 25;
    }
    return logo_size;
}

export function enlargeLogoSize(logo_size: number, layout: string): number {
    if (layout === '1') {
        return logo_size + 25;
    }
    return logo_size;
}
