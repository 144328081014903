import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {FEATURE_FLAGS} from '../shared/feature-flags';
import {AuthService} from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class FullLayoutComponentService {
    private isSideNavCollapsedSubject = new BehaviorSubject<boolean>(false);
    private showFormLinkOutsideCampaignSubject = new BehaviorSubject<boolean>(false);
    private checkGs1CampaignAccessSubject = new Subject<boolean>();
    isSideNavCollapsed$: Observable<boolean> = this.isSideNavCollapsedSubject.asObservable();
    showFormLinkOutsideCampaign: Observable<boolean> = this.showFormLinkOutsideCampaignSubject.asObservable();
    checkGs1CampaignAccess: Observable<boolean> = this.checkGs1CampaignAccessSubject.asObservable();

    constructor(private readonly authService: AuthService,
    ) {
    }

    setSideNavCollapsedState(state: boolean): void {
        this.isSideNavCollapsedSubject.next(state);
    }

    getSideNavCollapsedState(): boolean {
        return this.isSideNavCollapsedSubject.value;
    }

    handleFormsLinkInSidebarLaunchDarkly() {
        this.authService.getFeatureFlag(FEATURE_FLAGS.FORMS_LINK_IN_SIDEBAR, true).then(flag => {
            this.showFormLinkOutsideCampaignSubject.next(flag);
        })
    }

    updateGs1CampaignAccess(value: boolean) {
        this.checkGs1CampaignAccessSubject.next(value);
    }
}
