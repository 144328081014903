import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {BUTTON_STYLES, BUTTON_TYPES} from 'kaizen-design-system';
import {interval, Subject, Subscription} from 'rxjs';
import {debounceTime, takeUntil, throttleTime} from 'rxjs/operators';

export enum MOUSE_CLICK_ACTION {
    INCREMENT = 'increment',
    DECREMENT = 'decrement'
}
@Component({
    selector: 'app-dbc-user-seat-input',
    templateUrl: './user-seat-input.component.html',
    styleUrls: ['./user-seat-input.component.scss']
})
export class UserSeatInputComponent implements OnInit, OnDestroy, OnChanges {

    @Input() currentUserSeats: number = 1;
    @Input() minUsersToShow: number = 1;
    @Input() maxUsersToShow: number = 1600;
    @Input() currentTotalUserSeats: number = 1;
    @Output() userSeatsChange: EventEmitter<number> = new EventEmitter<number>();

    protected readonly BUTTON_TYPES = BUTTON_TYPES;
    protected readonly BUTTON_STYLES = BUTTON_STYLES;
    protected readonly MOUSE_CLICK_ACTION = MOUSE_CLICK_ACTION;

    private mouseAction: string | null = null;
    private interval$!: Subscription;
    private userSeatCountChange: Subject<number> = new Subject<number>();
    private ngUnsubscribe: Subject<any> = new Subject<any>();

    userSeatToBuyCount: number = 1; // initial value of counter

    ngOnInit() {
        // On any change make sure the validity of the user seat count
        this.userSeatCountChange.pipe(takeUntil(this.ngUnsubscribe), debounceTime(400)).subscribe((res) => {
            // To avoid appending 1 before the number user types after clearing the input field
            if (String(res) === ''){
                // set the seat count to min for the parent component, to counter the case when the input box is left blank
                this.userSeatsChange.emit(this.minUsersToShow);
                return;
            }
            this.userSeatToBuyCount = Number(res);
            if (this.userSeatToBuyCount < this.minUsersToShow) {
                this.userSeatToBuyCount = this.minUsersToShow;
            } else if (this.userSeatToBuyCount > this.maxUsersToShow){
                this.userSeatToBuyCount = this.maxUsersToShow;
            }
            // Parent component can listen to the changes made in user seat
            this.userSeatsChange.emit(this.userSeatToBuyCount);
        });
        // Intially making the user seats equal to the user seat passed into component
        this.userSeatToBuyCount = this.currentTotalUserSeats;
    }

    ngOnChanges(changes: SimpleChanges) {
    }

    // When mouse button is held down
    onMouseDown(mouseAction: MOUSE_CLICK_ACTION) {
        this.mouseAction = mouseAction;

        // stop any existing interval before starting a new one
        if (this.interval$) {
            this.interval$.unsubscribe();
        }

        // Create an interval observable that emits events
        const interval$ = interval(50); // emits every 200 ms

        // Start performing the mouseAction with throttling
        this.interval$ = interval$
            .pipe(throttleTime(50)) // throttling to control the speed
            .subscribe(() => {
                if (this.mouseAction === MOUSE_CLICK_ACTION.INCREMENT) {
                    if (this.userSeatToBuyCount < this.maxUsersToShow) {
                        this.userSeatToBuyCount = Number(this.userSeatToBuyCount) + 1;
                        this.setUserSeatChange(this.userSeatToBuyCount);
                    }
                } else if (this.mouseAction === MOUSE_CLICK_ACTION.DECREMENT) {
                    this.userSeatToBuyCount = Number(this.userSeatToBuyCount) > Number(this.currentUserSeats) ? (Number(this.userSeatToBuyCount) - 1) : this.currentUserSeats
                    this.setUserSeatChange(this.userSeatToBuyCount);
                }
            });
    }

    // When the mouse button is released
    onMouseUp() {
        if (this.interval$) {
            this.interval$.unsubscribe(); // Stop the interval observable
        }
    }

    setUserSeatChange(event) {
        this.userSeatCountChange.next(event);
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onInputBlur(){
        this.userSeatCountChange.next(this.userSeatToBuyCount);
    }
}
