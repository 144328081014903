import {BehaviorSubject, Observable, Subscription} from 'rxjs';

import {distinctUntilChanged} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {User} from '../../user-account/user.model';

@Injectable()
export class ProductOwnerAccessService {

    private visibilitySource = new BehaviorSubject<boolean>(false);
    visibility$: Observable<boolean> = this.visibilitySource.pipe(distinctUntilChanged());

    private messageSource = new BehaviorSubject<string>('');
    message$: Observable<string> = this.messageSource.asObservable();

    private dismissSubscription: Subscription = new Subscription();

    constructor() {
    }

    checkRestriction(user: User, product: any, productType: string) {
        if (product.maintainer === user.id) {
            return;
        }
        let message = '';
        if (product['meta'] && product['meta'].hasOwnProperty('creator_id') && user.id !== product['meta']['creator_id']) {
            message = `This ${productType} was created by ${product['meta']['creator_email']} - are you sure you want to make changes?`;
            this.messageSource.next(message);
            this.visibilitySource.next(true);
        } else if (product['meta'] && product['meta'].hasOwnProperty('updater_id') && user.id !== product['meta']['updater_id']) {
            message = `This ${productType} was last updated by ${product['meta']['updater_email']} - are you sure you want to make changes?`;
            this.messageSource.next(message);
            this.visibilitySource.next(true);
        }

        if (!this.dismissSubscription.closed) {
            this.dismissSubscription.unsubscribe();
        }
    }

    dismiss() {
        this.visibilitySource.next(false);
        this.dismissSubscription.unsubscribe();
    }

}
