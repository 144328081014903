<div class="mb-3 feature-include">
    {{DBC_PLANS_BREIF_DATA[plan].titleText ? DBC_PLANS_BREIF_DATA[plan].titleText: 'Includes'}}
</div>
<div class="pl-1 bold-first-child">
    <ng-container *ngFor="let includeData of DBC_PLANS_BREIF_DATA[plan].includes;">
        <div class="mb-1 d-flex feature-include-data">
            <i class="fas fa-check check-icon-blue pr-2"></i>
            <span>
              {{includeData}}
            </span>
        </div>
    </ng-container>
</div>
