export function isMobileDevice() {
    return /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
}

export enum DBC_ONBOARD_V2_SCREENS {
    ONBOARDING_FORM,
    IMAGE_CAPTURE,
}

export enum INDEXDB_DBC_KEYS {
    USER_IMAGE_URL = 'user_image_url',
    LOGO_URL = 'logo_url',
    DBC = 'dbc',
    COUNTRY_NAME_CODE = 'country_name_code',
}

export enum DBC_FORM_FIELD {
    FIRST_NAME = 'first_name',
    DESIGNATION = 'designation',
    COMPANY = 'company',
    PHONE_NUMBER = 'phone_v2',
    EMAIL = 'email_v2',
    WEBSITE = 'website_v2',
    BACKGROUND_COLOR = 'background_color'
}

export function validateEmail(email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,}$/;
    return emailRegex.test(email);
}

export function getRandom5DigitNumber(): string {
    const randomValues = new Uint32Array(1);
    window.crypto.getRandomValues(randomValues);
    return (randomValues[0] % 90000 + 10000).toString();
}
export const ONBOARDING_STORE_NAME = 'CARD'
