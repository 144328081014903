import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PLAN_TYPES} from '../../user-account/user.model';
import {Animations} from '../../shared/beaconstac-animations';
import {AuthService} from '../../global-services/auth.service';
import {DigitalBusinessCardsService} from '../../digital-business-card/digital-business-cards.service';
import {PRODUCT_STATE} from '../../shared/utils';
import {DBC_PLANS_BREIF_DATA} from './dbc-plan-data';

export enum DBC_PRICE_PAGE_TYPE {
    UPGRADE,
    EXPIRED,
}
@Component({
    selector: 'app-dbc-pricing-page',
    templateUrl: './dbc-pricing-page.component.html',
    styleUrls: ['./dbc-pricing-page.component.scss'],
    animations: [Animations.collapse]
})
export class DbcPricingPageComponent implements OnInit {

    @Input() currentUserSeats: number = 1;
    @Input() priceToShow: number = DBC_PLANS_BREIF_DATA.NT.amountPerUserSeat;
    @Input() selectedPlan: PLAN_TYPES;
    @Input() minUsersToShow: number = 1;
    @Input() currentTotalUserSeats: number = 1;
    @Input() pageType: DBC_PRICE_PAGE_TYPE = DBC_PRICE_PAGE_TYPE.UPGRADE;

    @Output() onUserSeatsToBuyChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() onBuyPlanClickChange: EventEmitter<PLAN_TYPES> = new EventEmitter<PLAN_TYPES>();
    @Output() addMoreUserSeats: EventEmitter<PLAN_TYPES> = new EventEmitter<PLAN_TYPES>();

    title: string = 'Pricing & Plans';
    userSeatsToBuy: number = 1;
    plansToShow = {
        [PLAN_TYPES.DBCFreemium]: true,
        [PLAN_TYPES.DBCNTeam]: true,
        [PLAN_TYPES.DBCBusinessPlus]: true
    }
    visiblePlansCount: number = 0;
    currentPlanIndexForMobileView: number = 1;
    showUsersSelectorBox: boolean = true;
    totalDBCInOrg: number = 0;
    currentPlan: PLAN_TYPES;
    DBC_PRICE_PAGE_TYPE = DBC_PRICE_PAGE_TYPE;

    mobileTouchStartX: number = 0;
    mobileTouchEndX: number = 0;
    visiblePlans = [];

    constructor(private authService: AuthService,
        private readonly digitalBusinessCardService: DigitalBusinessCardsService) {
        this.digitalBusinessCardService.getList(1, 1, {}, true, null, null, `&state=${PRODUCT_STATE.ACTIVE}`, false).subscribe((response) => {
            this.totalDBCInOrg = response.totalCount;
        })
        this.currentPlan = this.authService.getUser().dbcCustomerPlan;
    }

    ngOnInit() {
        this.plansToShow = {
            [PLAN_TYPES.DBCFreemium]: !this.authService.getUser().planIsGreatorThan(PLAN_TYPES.DBCFreemium) && (!this.authService.getUser().isOnOldDbcPlan),
            [PLAN_TYPES.DBCNTeam]: !this.authService.getUser().planIsGreatorThan(PLAN_TYPES.DBCNTeam),
            [PLAN_TYPES.DBCBusinessPlus]: true
        }

        if (this.pageType === DBC_PRICE_PAGE_TYPE.UPGRADE) {
            this.showUsersSelectorBox = this.plansToShow[PLAN_TYPES.DBCFreemium];
        } else if (this.pageType === DBC_PRICE_PAGE_TYPE.EXPIRED) {
            /** Not showing free plan in Expiry page
             * As user can't downgrade to free plan from expired page
             * Also we do not support downgrade as of now
             */
            this.plansToShow[PLAN_TYPES.DBCFreemium] = false;
        }
        this.configurePlanPagination();
    }

    configurePlanPagination() {
        this.visiblePlans = []; // Array to store visible plans in order

        if (this.plansToShow[PLAN_TYPES.DBCFreemium] === true) {
            this.visiblePlans = [
                PLAN_TYPES.DBCFreemium,
                PLAN_TYPES.DBCNTeam,
                PLAN_TYPES.DBCBusinessPlus
            ];
        } else if (this.plansToShow[PLAN_TYPES.DBCNTeam] === true) {
            this.visiblePlans = [
                PLAN_TYPES.DBCNTeam,
                PLAN_TYPES.DBCBusinessPlus
            ];
        } else {
            this.visiblePlans = [PLAN_TYPES.DBCBusinessPlus];
        }

        this.visiblePlansCount = this.visiblePlans.length;
    }

    handleClickBack() {
        if (this.currentPlanIndexForMobileView > 1) {
            this.currentPlanIndexForMobileView--;
        }
    }

    handleClickNext() {
        if (this.currentPlanIndexForMobileView < this.visiblePlansCount) {
            this.currentPlanIndexForMobileView++;
        }
    }
    // handling user swipe gesture
    onTouchStart(event: TouchEvent): void {
        this.mobileTouchStartX = event.changedTouches[0].screenX;
    }

    onTouchEnd(event: TouchEvent): void {
        this.mobileTouchEndX = event.changedTouches[0].screenX;
        this.handleSwipeGesture();
    }

    handleSwipeGesture(): void {
        const swipeThreshold = 30;
        const swipeDistance = this.mobileTouchStartX - this.mobileTouchEndX;

        if (Math.abs(swipeDistance) > swipeThreshold) {
            if (swipeDistance > 0) {
                this.handleClickNext();
            } else {
                this.handleClickBack();
            }
        }
    }
    userSeatsToBuyChange(event) {
        this.userSeatsToBuy = event;
        this.onUserSeatsToBuyChange.emit(this.userSeatsToBuy);
    }

    onBuyPlanClick(event = PLAN_TYPES.DBCNTeam) {
        this.onBuyPlanClickChange.emit(event);
    }

    onAddMoreUserSeats(event: PLAN_TYPES) {
        this.addMoreUserSeats.emit(event);
    }

    protected readonly PLAN_TYPES = PLAN_TYPES;
}
