import {Injectable} from '@angular/core';
import {BaseModel} from '../global-services/base.model';
import {BaseBackendService} from '../global-services/base-backend.service';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../global-services/auth.service';
import {MessageModalService} from '../shared/message-modal/message-modal.service';
import {catchError, map, switchMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';


export class FaviconModel extends BaseModel {
    url: string;

    constructor(json: any) {
        super();
        this.url = json['url'];
    }
}


@Injectable()
export class RecommendationService extends BaseBackendService<FaviconModel> {
    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, FaviconModel, 'fetch-logo', messageModal);
    }

    fetchFavicon(url: string, size?: number) {
        let apiUrl = `${this.apiURL}?website_url=${url}`;
        if (size) {
            apiUrl += `&size=${size}`;
        }
        return this.http
            .get(apiUrl, this.buildHeaders({})).pipe(
                map(response => {
                    return response;
                }),
                catchError(error => {
                    return of(null);
                }));
    }

    fetchFaviconWithMinSize(url: string, preferredSize: number, minSize: number): Observable<string | null> {
        return this.fetchFavicon(url, preferredSize).pipe(
            switchMap((response: any) => {
                if (response && response.url) {
                    return this.getFaviconSize(response.url).pipe(
                        switchMap((actualSize) =>
                            actualSize >= minSize
                                ? of(response.url)
                                : this.fetchMinSizeFavicon(url, minSize)
                        )
                    );
                } else {
                    return of(null);
                }
            }),
            catchError(() => {
                return of(null);
            })
        );
    }

    private fetchMinSizeFavicon(url: string, minSize: number): Observable<string | null> {
        return this.fetchFavicon(url, minSize).pipe(
            switchMap((response: any) =>
                response?.url
                    ? this.getFaviconSize(response.url).pipe(
                        switchMap((actualSize) =>
                            actualSize >= minSize
                                ? of(response.url)
                                : of(null)
                        )
                    )
                    : of(null)
            ),
            catchError(() => of(null))
        );
    }

    getFaviconSize(faviconUrl: string): Observable<number> {
        const img = new Image();
        img.src = faviconUrl;

        return new Observable<number>(observer => {
            img.onload = () => {
                observer.next(img.width);
                observer.complete();
            };
            img.onerror = () => {
                observer.error(0);
                observer.complete();
            };
        }
        );
    }
}
